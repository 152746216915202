import React, { Fragment, useContext, useEffect } from 'react';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import commentIcon from '../../assets/svg/comment.svg';
import mailIcon from '../../assets/svg/mail.svg';
import plusCircleSolid from '../../assets/svg/plus-circle-solid.svg';
import {
  ProjectContext,
  ProjectContextProvider
} from '../../contexts/projectContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import {
  GET_PROJECT_getProject,
  GET_PROJECT_getProject_collaborations
} from '../../graphql/types/GET_PROJECT';
import { IProjectShowPageProps } from '../../pages/project/show';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import CommentForm from '../Comment/CommentForm';
import CommentList from '../Comment/CommentList';
import { PrettyContent } from '../Content';
import ImageSlider from '../ImageSlider';
import InterestedProject from './Action/Interested';
import LikeProject from './Action/LikeProject';
import ProjectCollabAccordion from './ProjectCollabAccordion';
import Video from './Video';

interface IProjectContentProps {
  user: GET_CURRENT_USER_me;
}

const ProjectContent = (props) => {
  const project = useContext(ProjectContext);
  const history = useHistory();

  useEffect(() => {
    if (!project.isPublic) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCollaborations = (project.collaborations && project.collaborations.length > 0) ? true : false
  const lookingForCollaborations: JSX.Element[] = [];
  const foundCollaborations: JSX.Element[] = [];

  if (hasCollaborations) {
    project.collaborations?.forEach(
      (collaboration: GET_PROJECT_getProject_collaborations, idx: number) => {
        if (!collaboration.isFound) {
          lookingForCollaborations.push(
            <ProjectCollabAccordion key={idx} collaboration={collaboration} />
          );
        } else {
          foundCollaborations.push(
            <ProjectCollabAccordion key={idx} collaboration={collaboration} />
          );
        }
      }
    );
  }

  return (
    <Fragment>
      <div className="content page-project-content is-bordered-bottom p-b-0 m-b-0">
        <header className="project-header">
          <div className="project-header-top is-bordered-bottom is-block">
            <h1 className="is-size-4 is-relative p-r-3">{project.title}</h1>
          </div>
          <div
            className={`project-header-bottom ${!project.medias.length &&
              'is-bordered-bottom'}`}
          >
            <div className="level is-small">
              <div className="level-left">
                {project.type && (
                  <p className="level-item is-capitalized">
                    {project.type.toLocaleLowerCase()}
                  </p>
                )}
                {project.locations && project.locations[0] && (
                  <p className="level-item is-capitalized">
                    {project.locations[0].name}
                  </p>
                )}
                <p className="level-item is-capitalized">
                  Funding:{' '}
                  {project.fundingStatus &&
                    project.fundingStatus.toLocaleLowerCase()}
                </p>
              </div>
              <div className="level-right">
                <p className="level-item">
                  Posted: &nbsp;
                  <Moment format="YYYY/MM/DD">{project.createdAt}</Moment>
                </p>
              </div>
            </div>
          </div>
        </header>
        <ImageSlider medias={project.medias} />
        <div className="page-project-details p-t-1 p-b-2">
          <h3 className="is-size-5">Project Summary</h3>
          <PrettyContent
            content={project.description}
            isLineBreakEnabled={true}
          />
        </div>
        <Video project={project} />
      </div>
      <div className="content page-project-collaborators m-b-0 is-bordered-bottom">
        <h3 className="is-size-5">Collaborators</h3>
        {!hasCollaborations ? (
          <p className="p-b-1">
            This project is currently <strong>not</strong> looking for
            collaborations.
          </p>
        ) : (
          <>
            {lookingForCollaborations.length > 0 && (
              <div className="collaborators-looking m-b-2">
                <p className="m-b-1">
                  This project is looking for collaborators!
                </p>
                {lookingForCollaborations}
              </div>
            )}
            {foundCollaborations.length > 0 && (
              <div className="collaborators-found">
                <p className="m-b-1">Already found:</p>
                {foundCollaborations}
              </div>
            )}
          </>
        )}
      </div>
      <div className="content page-project-content">
        <h3 className="is-size-5 is-inline-flex post-card-stats-item has-text-grey-dark has-text-weight-semibold m-b-0">
          <img src={commentIcon} alt="comment icon" />
          <span>Comment</span>
          <span className="is-size-7 is-light icon--is-inline-flex">
            {project.comments.length}
          </span>
        </h3>
        <Fragment>
          <div className="is-block">
            <CommentList
              comments={project.comments}
              user={props.user}
            />
          </div>
          <div className="is-block m-t-1">
            <CommentForm projectId={project.id} />
          </div>
        </Fragment>
      </div>
    </Fragment>
  );
};

const ProjectSideBar = ({ user }: IProjectContentProps) => {
  const project = useContext(ProjectContext);

  const renderProjectAuthor = (project: GET_PROJECT_getProject) => {
    const authorAvatarImgUrl =
      (project.author &&
        project.author.avatar &&
        project.author.avatar.fileUrl &&
        project.author.avatar.fileUrl) ??
      profilePlaceholder;

    const compressedImage = getCompressedImagePath(
      authorAvatarImgUrl,
      'thumbnail'
    );

    return (
      <div className="card is-dropshadowed">
        <header className="card-header is-bordered-bottom m-b-0">
          <p className="card-header-title has-text-weight-normal">
            <span className="is-small is-light">Project by</span>
          </p>
        </header>
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure
                className="image avatar is-48x48"
                style={{ backgroundImage: `url(${compressedImage})` }}
              />
            </div>
            <div className="media-content">
              <p className="is-size-5 m-b-0">
                <Link
                  to={`/user/${project.author && project.author.id}`}
                  className="has-text-black"
                >
                  {project.author &&
                    project.author.profile &&
                    project.author.profile.name}
                </Link>
              </p>
              <p>
                {project.author &&
                  project.author.professions &&
                  project.author.professions.map((profession, idx) => (
                    <span key={idx} className="is-small is-light">
                      {(idx ? ', ' : '') + profession.name}
                    </span>
                  ))}
              </p>
            </div>
          </div>

          <div className="content">
            <p className="is-small">
              {project.author &&
                project.author.profile &&
                project.author.profile.bio && (
                  <PrettyContent
                    content={project.author.profile.bio}
                    isLineBreakEnabled={true}
                  />
                )}
            </p>
          </div>
        </div>
        <footer className="card-footer">
          <Link
            to={`/user/${project.author &&
              project.author.id &&
              project.author.id}`}
            className="card-footer-item is-small has-text-grey-dark"
          >
            Visit Profile
          </Link>

          <Link
            to={`/messages/${project.author &&
              project.author.id &&
              project.author.id}`}
            className="card-footer-item is-small has-text-grey-dark"
          >
            <img
              src={mailIcon}
              alt="mail icon"
              className="image is-inline"
              width="18px"
              title="Send a message"
            />
            <span>Message</span>
          </Link>
        </footer>
      </div>
    );
  };

  return (
    <Fragment>
      {/* Sidebar - Stats */}
      <div className="card is-dropshadowed">
        <div className="card-content p-t-1 p-r-1 p-b-1 p-l-1">
          <div className="is-block">
            <LikeProject
              user={user}
              pageType={'project'}
              postId={project.id}
              likes={project.likes ?? []}
              wasLikedByUser={
                (user && project.likes && Boolean(project.likes.find(like => like.author.id === user.id))) ? true : false
              }
            />
            <InterestedProject
              user={user}
              pageType={'project'}
              projectId={project.id}
              interested={project.interested}
              wasInterestedByUser={(
                user &&
                project.interested &&
                Boolean(
                  project.interested.find(
                    interested => interested.author.id === user.id
                  )
                )) ? true : false
              }
            />
          </div>
        </div>
      </div>

      {/* Sidebar - Project Author */}
      {renderProjectAuthor(project)}

      {/* Sidebar - Fans */}
      <div className="card page-project-sidebar-fans is-dropshadowed">
        <header className="card-header is-block is-shadowless">
          <h3 className="card-header-title has-text-weight-normal is-small">
            Promote this project
          </h3>
          <form action="#">
            <div className="field">
              <div className="control has-icons-right p-r-1 p-l-1">
                <input
                  className="input is-small"
                  type="text"
                  placeholder="Recommend a friend"
                />
                <span className="icon is-small is-right">
                  <img
                    width="14px"
                    className="m-r-1"
                    src={plusCircleSolid}
                    alt="plus circle"
                  />
                </span>
              </div>
            </div>
          </form>
        </header>
        <div className="card-content">
          <h3 className="has-text-weight-normal is-small m-b-1">
            Fans{' '}
            <Link to="#" className="is-light is-pulled-right">
              {project.likes ? project.likes.length : 0} {(project.likes && project.likes.length > 1) ? 'fans' : 'fan'}
            </Link>
          </h3>
          <div className="columns page-project-sidebar-fans-columns is-multiline is-mobile is-table">
            {project.likes &&
              project.likes.map(like => {
                const likeBgImage =
                  like && like.author.avatar && like.author.avatar.fileUrl;
                const compressedLikeBgImage = getCompressedImagePath(
                  likeBgImage,
                  'thumbnail'
                );
                return (
                  <div className="column is-pulled-left" key={like.id}>
                    <Link
                      to={`/user/${like.author && like.author.id}`}
                      className="tooltip"
                    >
                      <figure
                        className="image is-42x42 is-rounded page-project-list-sidebar-fans"
                        style={{
                          backgroundImage: `url(${compressedLikeBgImage ||
                            profilePlaceholder})`
                        }}
                      />
                      <span className="tooltiptext has-text-centered is-size-8">
                        {like.author.profile.name}
                      </span>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const PublicProject: React.FunctionComponent<IProjectShowPageProps> = (
  props: IProjectShowPageProps
) => {
  return (
    <Fragment>
      <div className="container">
        <div className="columns section">
          <div className="column is-three-quarters has-background-white is-paddingless is-rounded is-dropshadowed">
            <ProjectContextProvider projectId={props.match.params.projectId}>
              <ProjectContent />
            </ProjectContextProvider>
          </div>
          <div className="column page-project-sidebar p-t-0">
            <ProjectContextProvider projectId={props.match.params.projectId}>
              <ProjectSideBar user={props.user!} />
            </ProjectContextProvider>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PublicProject;
