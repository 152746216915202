import { useState } from 'react';
import { useMutation } from '@apollo/client';
// import useReactRouter from 'use-react-router';
import { useHistory } from 'react-router-dom'

import { CONFIRM_USER } from '../graphql/CONFIRM_USER';
import { accessTokenStorage } from '../services/auth';

export const useConfirmUser = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [confirmUser] = useMutation(CONFIRM_USER);
  const history = useHistory();

  const handleConfirmUser = async (token: string) => {
    setLoading(true);
    try {
      const response = await confirmUser({
        refetchQueries: ['GET_CURRENT_USER'],
        variables: { token }
      });
      setSuccess(true);

      accessTokenStorage.set(response.data.confirmUser.accessToken);
      history.replace('/');
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return [loading, error, success, handleConfirmUser];
};
