import React, { FC } from 'react'
import { LexicalComposer, InitialConfigType } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { MentionPlugin } from './MentionPlugin'
import EmojiPickerPlugin from './EmojiPickerPlugin';
import { MentionNode } from '../Editor/MentionNode';
import EditorTheme from '../Editor/theme';
import { convertV1Content } from '../../utils/comment';

interface IEditor {
    content: any
}

interface IReadOnly {
    content: any,
    commentIndex: string,
    version: number
}

const Editor: FC<IEditor> = () => {
    return (
        <div className="editor">
            <PlainTextPlugin
                contentEditable={<ContentEditable />}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <MentionPlugin
                usersMentionData={[]}
            />
            <EmojiPickerPlugin isFocus={false}/>
            {/* <EmojiSuggestions /> */}
      </div>
    )
}

const ReadOnly: FC<IReadOnly> = ({
    content,
    commentIndex,
    version
}) => {
    // const editorState: any = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
    let editorState: any
    if (version === 1) {
        editorState = JSON.stringify(convertV1Content(content))
    } else if (version === 2) {
        // editorState = content
        editorState = JSON.stringify(content)
    }

    const initialConfig: InitialConfigType = {
        namespace: `CommentReader${commentIndex}`,
        nodes: [MentionNode],
        theme: EditorTheme,
        editable: false,
        onError,
        editorState
    };
    // console.log('editorState', editorState)
    // console.log('version', version)
    function onError(error) {
        console.error(error);
      }

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <Editor content={content}/>
        </LexicalComposer>
    )
}

export default ReadOnly