import { QueryResult, useMutation } from '@apollo/client';
import {
    Formik,
    FormikValues
} from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import cancelButtonImage from '../../assets/svg/cancel.svg';
import { ADD_PROJECT_UPDATE } from '../../graphql/ADD_PROJECT_UPDATE';
import FormField from '../FormField';
import UploadForm, { EUploadTypes } from '../Upload';
import UploadPhotoContainer from './Forms/UploadPhotoContainer';

interface IUserProfileModalProps {
    modalIsOpen: boolean;
    handleModalToggle: () => void;
    project: any;
    compressedAvatarImage?: any;
}

const projectUpdateValidationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required')
});

const ProjectUpdateModal = ({
  modalIsOpen,
  handleModalToggle,
  project,
  compressedAvatarImage
}: IUserProfileModalProps) => {
    const history = useHistory();
    const [savedMedia, setSavedMedia] = useState<any[]>([]);

    const RenderForm = (renderFormProps: FormikValues) => {
        const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
        } = renderFormProps;

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <section className="modal-card-body is-flex has-flex-direction-column">
                        <div className="field">
                            <div className="field-label">
                                Title
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <FormField
                                        name="title"
                                        type="text"
                                        placeholder="Update title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        additionalInputClasses="has-background-white is-small"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field-label">
                                Update
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <FormField
                                        multiline={true}
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Please describe the update about the project."
                                        value={values.description}
                                        additionalInputClasses="has-background-white is-small"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field-label">
                                Attachment
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <UploadForm
                                            medias={values.medias}
                                            setFieldValue={setFieldValue}
                                            uploadContainer={UploadPhotoContainer}
                                            uploadType={EUploadTypes.projectUpdate}
                                            formType={'new'}
                                            setSavedMedia={setSavedMedia}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot has-background-white p-t-1 p-b-1 is-fullwidth has-justify-content-flex-end">
                        <button type="submit" className='modal-card-foot-update-modal-button font-size-14' disabled={isSubmitting}>
                            Save
                        </button>
                    </footer>
                </form>
            </div>
        )
    }

    const addProjectUpdateCompleted = async data => {
        handleModalToggle()
        return toast.success(
          'New update created! Refreshing project page...',
          {
            onClose: () => history.push(`/project/${project.id}`)
          }
        );
      };

    const [addProjectUpdate] = useMutation<QueryResult>(ADD_PROJECT_UPDATE, {
        onCompleted: addProjectUpdateCompleted,
        refetchQueries: ['GET_PROJECT']
    });

    return (
        <div>
            <div className="modal-card page-user-profile-modal-card">
                <header className="modal-card-head has-background-white has-justify-content-space-between p-t-5">
                    <h3 className='font-size-20'>Update</h3>
                    <button
                        className='modal-card-head-cancel-button is-borderless has-background-white'
                        onClick={handleModalToggle}
                    >
                        <img src={cancelButtonImage} alt="Cancel button" />
                    </button>
                </header>

                <Formik
                    validationSchema={projectUpdateValidationSchema}
                    initialValues={{
                        title: '',
                        description: '',
                        medias: [],
                        savedMedia: []
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const { savedMedia: savedMediaValue, medias, ...rest } = values;
                        const newMedias = savedMedia.map(media => {
                            if (typeof media.id === 'string') {
                                return parseInt(media.id, 10)
                            }

                            return media.id
                        })

                        await addProjectUpdate({
                            variables: {
                                projectId: project.id,
                                ...rest,
                                medias: newMedias
                            }
                        });

                        setSubmitting(false);
                    }}
                >
                    {formikData => RenderForm(formikData)}
                </Formik>
            </div>
        </div>
    )
}

export default ProjectUpdateModal