import { Formik, FormikValues } from 'formik';
import React, { Fragment, useContext } from 'react';

import { MessageContext } from '../../contexts/messageContext';
import FormField from '../FormField';

const MessageReplyForm = () => {
  const { actions, currentRoom } = useContext(MessageContext);

  const renderForm = (formikData: FormikValues) => {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      dirty
    } = formikData;

    return (
      <form
        onSubmit={handleSubmit}
        className="page-messages-column-footer-form"
      >
        <div className='message-container'>
          <div className='control-messages'>
          <FormField
          name="reply"
          type="text"
          placeholder="Messages..."
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.reply}
          multiline={true}
          additionalInputClasses="page-messages-column-footer-form-textarea has-background-white"
        />
          </div>

          <div className='reply-button' style={{ width: '60px' }}>
          <button
          type="submit"
          className={`button page-messages-column-footer-form-button is-white has-text-white has-height-auto has-text-weight-semibold is-flex p-l-r ${isSubmitting &&
            'is-loading'} ${values.reply.length <= 0 &&
            'has-background-white is-light'}`}
          disabled={!dirty || isSubmitting}
        >
              <svg
                width="28"
                height="28"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.20159 12.3201L17.6117 3.91002L3.59488 9.51673L9.20159 12.3201ZM19.09 5.38834L10.6799 13.7984L13.4833 19.4051L19.09 5.38834ZM0.577872 10.3457C-0.228867 9.94229 -0.180318 8.7751 0.657132 8.44012L21.5637 0.077484C22.417 -0.263842 23.2638 0.582959 22.9225 1.43627L14.5599 22.3429C14.2249 23.1803 13.0577 23.2289 12.6543 22.4221L8.62885 14.3711L0.577872 10.3457Z"
                  fill="#4444c1"
                />
              </svg>
        </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Fragment>
      <footer className="card-footer page-messages-column-card-footer is-block is-absolute">
        <Formik
          initialValues={{ reply: '' }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (values.reply === '') {
              return;
            }

            await actions.handleCreateMessage(
              currentRoom.data.getRoom.id,
              values.reply
            );

            setSubmitting(false);
            resetForm();
          }}
        >
          {renderForm}
        </Formik>
      </footer>
    </Fragment>
  );
};

export default MessageReplyForm;
