import type { ReactNode } from 'react'
import { ApolloError } from '@apollo/client';
import React from 'react';

import ContentLoader from '../components/ContentLoader';
import { SpinnerFull } from '../components/Spinner';

export enum LoaderType {
  spin = 'spin',
  content = 'content'
}

interface IRenderContentsParams {
  loading: boolean,
  loaderType?: LoaderType,
  error: ApolloError | undefined,
  data: object[],
  children: ReactNode
}

export const renderContents = ({
  loading,
  loaderType = LoaderType.content,
  error,
  data,
  children
}: IRenderContentsParams) => {
  if (loading) {
    return loaderType === LoaderType.content ? (
      <ContentLoader />
    ) : (
      <SpinnerFull />
    );
  }

  if (!loading && error) {
    return <div className="notification is-danger">Something went wrong.</div>;
  }

  if (!loading && data && !error) {
    return children;
  }

  return null;
};
