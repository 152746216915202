import { GET_CURRENT_USER } from '../graphql/GET_CURRENT_USER';
import { storage } from './storage';

export const accessTokenStorage = storage<string>('token');

export const isLoggedIn = async apolloClient =>
  apolloClient
    .query({ query: GET_CURRENT_USER })
    .then(response => {
      return { user: response.data };
    })
    .catch((e: Error) => {
      accessTokenStorage.clear();
    });
