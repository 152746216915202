import { gql } from '@apollo/client';

export const TOGGLE_UPDATE_COMMENT_LIKE = gql`
  mutation TOGGLE_UPDATE_COMMENT_LIKE($commentId: Float!) {
    toggleProjectUpdateCommentLike(commentId: $commentId) {
      __typename
      author {
        id
        email
        profile {
          id
          name
          bio
          website
        }
      }

      id
      title
      description

      medias {
        id
        fileUrl
      }

      likes {
        id
      }
      wasLikedByUser

      comments {
        id
        version
        contentJson
        wasCommentLikedByUser
        # likeCount
      }
    }
  }
`;