type ImageSize = 'thumbnail' | 'md' | 'lg' | 'xlg';
// type ImageExtension = 'png' | 'jpg' | 'jpeg';

/**
 * getCompressedImage path will return new image path that points to compressed image path
 * @param  {string} url
 * @param  {ImageSize='thumbnail'} size
 * @param  {ImageExtension='png'} ext
 */
export const getCompressedImagePath = (url: string, size: ImageSize = 'thumbnail'): string => {
	const validUrl = url && /^(https:\/\/)([^/]+)\/avatar\/images\/raw\//.test(url);
	if (!validUrl) {
		return '';
	}
	const basePath = url && url.match(/^(https:\/\/)([^/]+)\/avatar\/images\//)![0];
	const filename = url.match(/raw\/(.*)/)![1]
	const filenameWithoutExt = filename.replace(/\.(jpg|png|jpeg)/, '');
	const newFilename = `${basePath}compressed/${size}/${filenameWithoutExt}-${size}`
	return newFilename;
}