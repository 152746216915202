import { gql } from '@apollo/client';

export const UPDATE_COLLABORATION = gql`
  mutation UPDATE_COLLABORATION(
    $collaborationId: ID!
    $skills: [String!]
    $professions: [String!]
    $isFound: Boolean
  ) {
    editCollaboration(
      collaborationId: $collaborationId
      skills: $skills
      professions: $professions
      isFound: $isFound
    ) {
      id
      additionalRequirements
      compensation
      currency
      isFound
      numberOfPeople

      applicants {
        id
      }

      requiredProfessions {
        id
        name
      }

      requiredSkills {
        id
        name
      }

      selectedApplicants {
        id
      }
    }
  }
`;
