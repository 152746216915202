import { gql } from '@apollo/client';

export const CREATE_ROOM = gql`
  mutation CREATE_ROOM($memberIds: [Float!]!, $roomType: String!) {
    createChatRoom(memberIds: $memberIds, roomType: $roomType) {
      __typename
      id
      createdAt

      # messages {
      #   id
      #   content
      #   createdAt
      #   author {
      #     id
      #     email
      #   }
      # }

      owner {
        email
        id

        profile {
          id
          name
        }
      }

      members {
        email
        id

        profile {
          id
          name
        }
      }
    }
  }
`;
