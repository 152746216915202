import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { REQUEST_PASSWORD_RESET } from '../../../graphql/REQUEST_PASSWORD_RESET';
import {
  RequestPasswordReset,
  RequestPasswordResetVariables
} from '../../../graphql/types/RequestPasswordReset';
import FormField from '../../FormField';

const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required')
});

const renderForm = ({ handleSubmit, isSubmitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <header className="content">
        <h1 className="is-size-4">Reset your password</h1>
        <span>
          Enter user account's email address and we will send a password reset
          link.
        </span>
      </header>
      <div className="field">
        <div className="field-body">
          <FormField
            name="email"
            type="text"
            placeholder="Enter your email address"
          />
        </div>
      </div>
      <div className="field">
        <button
          type="submit"
          className={`button has-text-weight-medium is-primary ${isSubmitting &&
            'is-loading'}`}
          disabled={isSubmitting}
        >
          Reset Password
        </button>
      </div>
    </form>
  );
};

const ForgotPasswordEmailForm = () => {
  const [requestPasswordReset] = useMutation<
    RequestPasswordReset,
    RequestPasswordResetVariables
  >(REQUEST_PASSWORD_RESET, {
    onCompleted: () =>
      toast.success(
        'If the email address you provided is a valid account, we\'ll send an email now with a link to reset your password',
        {
          autoClose: false
        }
      ),
    onError: (error: Error) =>
      toast.error(`Something went wrong: ${error.message}`, {
        autoClose: false
      })
  });
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await requestPasswordReset({
          variables: {
            email: values.email
          }
        });

        setSubmitting(false);
        resetForm();
      }}
    >
      {formikData => renderForm(formikData)}
    </Formik>
  );
};

export default ForgotPasswordEmailForm;
