// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
// import {
//   LexicalTypeaheadMenuPlugin,
//   MenuOption,
//   MenuTextMatch,
//   useBasicTypeaheadTriggerMatch
// } from '@lexical/react/LexicalTypeaheadMenuPlugin';
// import { TextNode } from 'lexical';
// import { $createMentionNode } from './MentionNode';
import React, { Fragment } from 'react';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { MentionPlugin } from './MentionPlugin'
import EmojiPickerPlugin from './EmojiPickerPlugin';
import EmojiDropDownPlugin from './EmojiDropDownPlugin';
interface MentionData {
  id: string,
  name: string,
  link: string,
  avatar: string
}

interface EditorProps {
  usersMentionData: MentionData[],
  placeholder: string,
  editorState: object,
  setEditorState: React.Dispatch<any>,
  isFocus: boolean
}

// interface EditorStateChild {
//   direction: 'ltr'|'rtr',
//   format: string,
//   indent: number,
//   type: string,
//   verison: number,
//   children: EditorStateChild[]
// }

// interface EditorState {
//   root: EditorStateChild
// }

/**
 * Input that allows to mention users by typing @
 */
export const Editor: React.FunctionComponent<EditorProps> = ({
  usersMentionData,
  placeholder,
  editorState,
  setEditorState,
  isFocus
}) => {
  function onChange(state) {
    // console.log('editorState', state.toJSON())
    setEditorState(state.toJSON());
  }

  return (
    <Fragment>
      <div className="editor">
        <PlainTextPlugin
          contentEditable={<ContentEditable style={{
            height: '48px',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem'
          }}/>}
          placeholder={
            <div style={{
              position: 'absolute',
              color: '#d9d2d2',
              top: '0',
              left: '15px',
              pointerEvents: 'none',
              userSelect: 'none',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem'
            }}>
              {placeholder}
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <MentionPlugin
          usersMentionData={usersMentionData}
        />
        <EmojiPickerPlugin isFocus={isFocus}/>
        <OnChangePlugin onChange={onChange}/>
        <EmojiDropDownPlugin/>
      </div>
    </Fragment>
  );
};
