import { gql } from '@apollo/client';

export const SAVE_PRESIGNED_LINK = gql`
  mutation SAVE_PRESIGNED_LINK($fileUrl: String!, $uploadType: String!) {
    savePreSignedLink(fileUrl: $fileUrl, uploadType: $uploadType) {
      id
      uploadType
      fileType
      fileUrl
    }
  }
`;
