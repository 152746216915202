import React, { useState } from 'react'
import {
    $createTextNode,
    $getSelection,
    $isRangeSelection
  } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import smile_face from '../../assets/svg/smile-face.svg';
import { Popover } from 'react-tiny-popover'

function EmojiDropDownPlugin() {
    const [editor] = useLexicalComposerContext();
    const [show, setShow] = useState(false)

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        console.log('show', show)
        setShow(!show)
    }

    const onEmojiSelect = (emoji: EmojiClickData, event: MouseEvent) => {
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) {
              return;
            }
            selection.insertNodes([$createTextNode(emoji.emoji)]);
        });
    }

    return (
        <div>
            <Popover
                isOpen={show}
                positions={['top', 'right', 'left', 'bottom']}
                padding={10}
                onClickOutside={() => setShow(false)}
                // ref={clickMeButtonRef!} // if you'd like a ref to your popover's child, you can grab one here
                content={
                    <EmojiPicker
                        onEmojiClick={onEmojiSelect}
                        autoFocusSearch={false}
                        // categories={[{
                        //     category: Categories.SMILEYS_PEOPLE,
                        //     name: ''
                        // }]}
                        searchDisabled={true}
                        skinTonesDisabled={true}
                        previewConfig={{
                            defaultCaption: '',
                            showPreview: false
                        }}
                    />
                }
            >
                <button
                    type='button'
                    className='icon-button is-absolute is-rounded'
                    style={{
                        top: '10px',
                        right: '10px'
                    }}
                    onClick={onClick}
                >
                    <img
                        aria-haspopup='true'
                        src={smile_face}
                        alt='emoji'
                        width='15px'
                    />
                </button>
            </Popover>
        </div>
    )
    }

export default EmojiDropDownPlugin