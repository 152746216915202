import { gql } from '@apollo/client';

export const CREATE_MESSAGE = gql`
  mutation CREATE_MESSAGE($roomId: Float!, $content: String!) {
    createMessage(roomId: $roomId, content: $content) {
      __typename
      id
      content
      createdAt

      author {
        email
        id

        avatar {
          fileUrl
        }
      }
    }
  }
`;
