// import useReactRouter from 'use-react-router';
import { useHistory } from 'react-router-dom'

export const useRedirect = () => {
  const history = useHistory();
  // go back to the previous browser history state if the previous page visited is the home page
  const redirectToHomePage = () => {
    history.location.state === '/' ? history.goBack() : history.push('/');
  };

  return {
    redirectToHomePage
  };
};
