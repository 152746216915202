import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Link } from 'react-router-dom';

import Navigation from '../components/Navigation';
import { apolloClient } from '../services/apollo';

const CommunityGuidelinesPage = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <div className="page-community-guideline">
        <Navigation client={apolloClient} user={undefined} />
        <div className="page-community-guideline-container page-container">
          <div className="container p-t-3 p-b-3">
            <div className="columns m-t-0 m-r-0 m-b-0 m-l-0">
              <div className="column">
                <h1 className="is-size-3 is-bordered-bottom">
                  MatchHat Community Guidelines
                </h1>
                <div className="block">
                  <p>Last updated: February 14, 2021</p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">EXPRESS YOURSELF</h3>
                </div>

                <div className="block">
                  <p>
                    MatchHat is a place where you can express your views through
                    a creative medium. We want to foster positive and diverse
                    artistic communities.
                  </p>
                </div>

                <div className="block">
                  <p>
                    We encourage free speech! However, we do not permit hate
                    speech. It refers to any type of content that promotes
                    violence against or has the primary purpose of inciting
                    hatred against individuals or groups, based on certain
                    attributes, such as race or ethnic origin, religion,
                    disability, diseases, gender, age, sexual orientation/gender
                    identity. In that sense, there is a fine line between what
                    is and what is not considered to be hate speech. If the
                    primary purpose is to incite hatred against or attack a
                    protected group based solely on any of these core
                    attributes, the content crosses the line to hate speech.
                  </p>
                </div>

                <div className="block">
                  <p>
                    We also don’t permit credible threats or content that
                    targets private individuals to degrade or shame them.
                    Personal information meant to blackmail or harass someone as
                    well as sending repeated unwanted messages is strictly
                    prohibited on MatchHat. We reserve the right to remove
                    abusive or malicious content and comments. We reserve the
                    right to delete your account.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    HARMFUL, DANGEROUS, VIOLENT CONTENT
                  </h3>
                </div>

                <div className="block">
                  <p>
                    Please do not encourage others to commit specific acts of
                    violence. Many age groups and sensitivities use MatchHat.
                    Thus we may have to remove intense or graphic violence to
                    ensure the platform stays appropriate for everyone. Content
                    that intends to incite violence, encourage dangerous or
                    illegal activities or threatens people with serious acts of
                    violence may be removed from MatchHat. The same applies for
                    self-harm content. So please refrain from posting content
                    that expresses self-harm in a positive light.
                  </p>
                </div>

                <div className="block">
                  <p>
                    That being said, we understand that people often share this
                    kind of content to condemn, raise awareness or educate. If
                    you do share such content for these reasons, we encourage
                    you to caption your photo with a warning about violence.
                    Please understand that sharing content related to either
                    inciting violence, graphic images for sadistic ends or to
                    glorify violence is not allowed on MatchHat. We reserve the
                    right to delete your account.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">NUDITY AND SEXUAL CONTENT</h3>
                </div>

                <div className="block">
                  <p>
                    We want MatchHat to be a platform of free expression where
                    artists can work around and expose human body subjects in a
                    positive light. We want to accept all art forms, however, in
                    most cases, violent, graphic, or humiliating fetishes are
                    not allowed on MatchHat. In that sense, you cannot post
                    sexually explicit content like pornography.
                  </p>
                </div>

                <div className="block">
                  <p>
                    A post or video that contains nudity or other sexual content
                    may be allowed if the primary purpose is artistic,
                    documentary or educational.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">RESPECT AGE LIMIT</h3>
                </div>

                <div className="block">
                  <p>
                    You must be minimum thirteen years old (13) to access
                    MatchHat platforms. Hence please do not visit or create an
                    account on MatchHat if you are under 13 years old. If you
                    suspect a user to be underage, please report them to us at{' '}
                    <Link to="mailto:contact@matchHat.com">
                      contact@matchhat.com
                    </Link>{' '}
                    or visit our Report Centre. We will disable their account
                    accordingly.
                  </p>
                </div>

                <div className="block">
                  <p>
                    You must also be old enough to consent to the processing of
                    your personal data in your own country. For example, in
                    Italy and Spain the minimum age to consent for data
                    processing is 14 years old, in France it is 15 years old,
                    whereas in Germany, Poland, Ireland and Netherlands, it is
                    16 years old. Please make sure your use of the MatchHat is
                    in line with your national minimum age of consent for data
                    processing.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">AUTHENTICITY</h3>
                </div>

                <div className="block">
                  <p>
                    At MatchHat, we want you to be yourself. Do not impersonate
                    others. Build your own vision and express it through your
                    work. Do not create an account or post content while using
                    another individual's real name, image, or other personal
                    information to deceive people into thinking you are someone
                    else. MatchHat celebrates everyone who has a passion to
                    create and all ideas, no matter how big or small, are
                    welcome. Show us what makes you, you!
                  </p>
                </div>

                <div className="block">
                  <p>
                    If you feel that you are being impersonated, you can contact
                    our team directly at{' '}
                    <Link to="contact@matchhat.com">contact@matchhat.com</Link>.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    SPAM, MISLEADING METADATA AND SCAMS
                  </h3>
                </div>

                <div className="block">
                  <p>
                    Please foster and encourage meaningful and genuine
                    interactions. Respect the authentic work of other MatchHat
                    Users by not creating artificial or misleading descriptions,
                    tags, titles, or thumbnails only meant to increase views.
                  </p>
                </div>

                <div className="block">
                  <p>
                    We encourage conversations to take place on MatchHat.
                    Private messaging and chats can be used to build
                    relationships and facilitate prosperous connections. However
                    please refrain from sharing large amounts of untargeted,
                    unwanted or repetitive content, including comments and spam.
                    We reserve the right to delete your account.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">COPYRIGHTS AND TRADEMARKS</h3>
                </div>

                <div className="block">
                  <p>
                    Be proud of your work. Post your authentic and original
                    content or content that you are authorised to share. Please
                    do not post something you’ve copied or collected and that
                    you do not have the right to post (for instance a
                    copyrighted or trademarked content by a third party).
                  </p>
                </div>

                <div className="block">
                  <p>
                    When you post or display content, which other people
                    contributed on, please make sure you have their express
                    permissions. When you create or become part of projects,
                    make sure you understand the consequences of posting your
                    work within a project and what other collaborators or owners
                    can do with it. We reserve the right to take down, at our
                    sole discretion, any content on MatchHat reported to us as
                    an infringement of intellectual property rights. We reserve
                    the right to delete your account.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Please visit our{' '}
                    <Link to="/terms-of-use">Terms of Use</Link> for more
                    information.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">PRIVACY & PERSONAL INFORMATION</h3>
                </div>

                <div className="block">
                  <p>
                    Please do not release the personal information of MatchHat
                    users or any other relevant third parties unless you have
                    explicit permissions allowing you to do so. We encourage you
                    to let us know if content or comments on MatchHat violate
                    your privacy or sense of safety. Visit our{' '}
                    <Link to="/privacy-policy">Privacy Policy</Link> and{' '}
                    <Link to="#!">Safety Centre</Link> for more details.
                  </p>
                </div>

                <div className="block">
                  <p>
                    If someone has posted your personal information or uploaded
                    content of yours without your consent, you can contact the
                    uploader and ask him to remove the content. If you can’t
                    reach an agreement with the uploader, or if you're
                    uncomfortable contacting him/her, you can contact{' '}
                    <Link to="mailto:contact@matchhat.com">
                      contact@matchhat.com
                    </Link>{' '}
                    and we will determine, in our sole discretion, if the
                    content should be removed.
                  </p>
                </div>

                <div className="block">
                  <p>Make sure you preserve your privacy on MatchHat by:</p>
                  <div className="content">
                    <ul>
                      <li>
                        Thinking carefully before you post personal information.
                        This includes details such as your name, the town you
                        live in, the localisation of your posts.
                      </li>
                      <li>
                        Protecting your account data and not sharing your
                        password with others. MatchHat’s employees will never
                        ask you for your password.
                      </li>
                      <li>
                        Getting permission first. You should get permission
                        before posting information or content related to filming
                        other people or posting their personal information.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="block">
                  <h3 className="is-size-4">SECURITY</h3>
                </div>

                <div className="block">
                  <p>
                    We are a community by people for people. Hence please do not
                    attempt to compromise or breach the security of MatchHat or
                    our servers. This includes load testing or malicious DDoS
                    attacks. We have a strict policy against bots.
                  </p>
                </div>

                <div className="block">
                  <p>
                    If we feel like you are infringing on our stated community
                    guidelines, we will contact you and request that you refrain
                    from the specified breach. If you do not oblige, then we
                    will terminate or suspend your account without further
                    notice.
                  </p>
                </div>

                <div className="block">
                  <p>
                    If you come across a user who you believe to be acting
                    against our specified community guidelines please submit a
                    report to{' '}
                    <Link to="mailto:contact@matchhat.com">
                      contact@matchhat.com
                    </Link>
                    . We will have a team investigate accordingly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default CommunityGuidelinesPage;
