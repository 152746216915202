import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { GoogleLogin } from '@leecheuk/react-google-login';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import useReactRouter from 'use-react-router';
import * as Yup from 'yup';
import googleIcon from '../../../assets/images/icons/google-logo.png';
import { REGISTER_USER } from '../../../graphql/REGISTER_USER';
import Api from '../../../services/api';
import FormField from '../../FormField';

const signUpValidationSchema = Yup.object().shape({
  agreement: Yup.boolean()
    .oneOf([true], 'Must Accept Terms and Conditions')
    .required('Must Accept Terms and Conditions'),
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required'),
  name: Yup.string().required('This field is required'),
  password: Yup.string()
    .min(8, 'Password should have at least 8 characters.')
    .max(24, 'Password should not exceed 24 characters.')
    .required('This field is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords does not match.')
    .required('This field is required')
});

interface ISignUpFormProps {
  setSignUpSuccess: (_: boolean) => void;
}

const SignUpForm = (props: ISignUpFormProps) => {
  const history = useHistory();
  const onCompleted = ({ registerUser }) => {
    toast.success('User successfully created.');
    props.setSignUpSuccess(true);
  };

  const onError = (error: Error) =>
    toast.error(`Cannot create user: ${error.message}`, {
      autoClose: false
    });

  const [signUpUser] = useMutation(REGISTER_USER, {
    onCompleted,
    onError
  });

  const responseGoogle = async response => {
    if ('accessToken' in response) {
      const token = response.accessToken;
      try {
        await Api.registerOrLoginWithGoogle({
          googleToken: token,
          name: response.profileObj.name,
          registerUser: true
        });
        history.replace('/');
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  return (
    <Formik
      initialValues={{
        agreement: '',
        email: '',
        name: '',
        password: '',
        passwordConfirm: ''
      }}
      validationSchema={signUpValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await signUpUser({ variables: values });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, isSubmitting, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit} className="form-box">
          <header className="content has-text-centered">
            <h3 className="form-box-title">Sign up</h3>
          </header>
          <div className="field">
            <div className="field-body">
              <FormField name="name" type="text" placeholder="Full Name" />
            </div>
          </div>
          <div className="field">
            <div className="field-body">
              <FormField name="email" type="email" placeholder="Email" />
            </div>
          </div>
          <div className="field">
            <div className="field-body">
              <FormField
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
          </div>
          <div className="field">
            <div className="field-body">
              <FormField
                name="passwordConfirm"
                type="password"
                placeholder="Confirm Password"
              />
            </div>
          </div>
          <div className="page-sign-up-form-terms">
            <input
              className="page-sign-up-form-terms-check"
              type="checkbox"
              name="agreement"
              onChange={async () => await setFieldValue('agreement', !values.agreement)}
              checked={Boolean(values.agreement)}
            />
            <p className="page-sign-up-form-terms-links">
              I agree to the <Link to="/terms-of-use">Terms of Use</Link> and{' '}
              <Link to="privacy-policy">Privacy Policy</Link>
            </p>
          </div>
          <span className="field-error has-text-danger">
            {errors && errors.agreement}
          </span>

          <div className="field field-login">
            <button
              type="submit"
              className={`button is-secondary has-text-weight-bold ${isSubmitting &&
                'is-loading'}`}
              disabled={isSubmitting}
            >
              Sign up now
            </button>
            <p className="has-text-centered">
              Already have an account?{' '}
              <Link to="/sign-in">
                <span className="has-text-weight-bold">Log in</span>
              </Link>
            </p>
          </div>
          <div className="divider-box">
            <span className="divider-lol">
              <span className="divider-text">
                <i>or</i>
              </span>
            </span>
          </div>
          {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
              responseType={'id_token'}
              render={(renderProps: {
                onClick: () => void;
                disabled: boolean;
              }): any => {
                return (
                  <div className="field field-google-login">
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      type="button"
                      className="button is-primary is-outlined"
                      style={{ borderColor: '#e4e4e4' }}
                    >
                      <img
                        alt="google-login-button"
                        src={googleIcon}
                        style={{ marginRight: '10px' }}
                      />
                      Sign up with google
                    </button>
                  </div>
                );
              }}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default SignUpForm;
