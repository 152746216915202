import type { FormikValues } from 'formik'
import { useMutation } from '@apollo/client';
import { FieldArray, Formik } from 'formik';
import React, { Fragment } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { ADD_USER_SKILL } from '../../../graphql/ADD_USER_SKILL';
import { DELETE_USER_SKILL } from '../../../graphql/DELETE_USER_SKILL';
import { GET_SKILLS_BY_FILTER_QUERY } from '../../../graphql/GET_SKILLS_BY_FILTER_QUERY';
import { GET_CURRENT_USER_me_skills } from '../../../graphql/types/GET_CURRENT_USER';
import {
  IHandleOptionSelect,
  useAutocomplete
} from '../../../hooks/useAutocomplete';
import autoCompleteStyles from './autocompleteStyles';

interface IUserSkillsFormProps {
  skills: GET_CURRENT_USER_me_skills[];
}

const userSkillSchema = Yup.object().shape({
  newSkill: Yup.string().required('This field is required.')
});

const UserSkillForm: React.FunctionComponent<IUserSkillsFormProps> = ({
  skills
}: IUserSkillsFormProps) => {
  const [addUserSkill] = useMutation(ADD_USER_SKILL, {
    onCompleted: () => toast.success('User skill successfully added.')
  });

  const [deleteUserSkill] = useMutation(DELETE_USER_SKILL, {
    onCompleted: () => toast.success('User skill successfully deleted.')
  });

  const skillOptSelectCallback = async (params: IHandleOptionSelect) => {
    if (!params) {
      return;
    }

    const { __isNew__, label, value } = params;

    return await addUserSkill({
      variables: { skillName: __isNew__ ? value : label }
    });
  };

  const {
    loadOptions: skillsLoadingOptions,
    loading: skillsLoading
  } = useAutocomplete({
    query: GET_SKILLS_BY_FILTER_QUERY
  });

  const renderForm = ({ values, handleSubmit }: FormikValues) => (
    <form onSubmit={handleSubmit} className="m-b-1">
      <FieldArray name="skillNames">
        {arrayHelper => {
          return (
            <Fragment>
              <div className="field">
                <div className="field-body">
                  <div className="field">
                    <div className="label is-uppercase is-size-8">Skill</div>
                    <div className="control">
                      <AsyncCreatableSelect
                        cacheOptions={true}
                        isClearable={true}
                        className="is-small auto-complete"
                        classNamePrefix="react-select"
                        escapeClearsValue={true}
                        backspaceRemovesValue={true}
                        isLoading={skillsLoading}
                        onChange={skillOptSelectCallback}
                        loadOptions={skillsLoadingOptions}
                        placeholder={'Eg. Adobe Premiere'}
                        styles={autoCompleteStyles}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null
                        }}
                        value={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column p-t-0">
                  {values.skillNames &&
                    values.skillNames.map((name, idx) => (
                      <Fragment key={`${idx}-${name}`}>
                        <span className="tag page-add-project-card-content-tag is-primary m-b-1">
                          {name}
                          <button
                            className="delete is-small"
                            onClick={async () => {
                              arrayHelper.remove(idx);
                              await deleteUserSkill({
                                variables: {
                                  skillName: name
                                }
                              });
                            }}
                          />
                        </span>
                      </Fragment>
                    ))}
                </div>
              </div>
            </Fragment>
          );
        }}
      </FieldArray>
    </form>
  );

  return (
    <Formik
      validationSchema={userSkillSchema}
      enableReinitialize={true}
      initialValues={{
        newSkill: '',
        skillNames: skills.map(skill => skill.name) || []
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {formikData => renderForm(formikData)}
    </Formik>
  );
};

export default UserSkillForm;
