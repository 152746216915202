import { gql } from '@apollo/client';

export const ADD_PROJECT = gql`
  mutation ADD_PROJECT(
    $title: String!
    $description: String!
    $passionProjectOpts: String
    $commissionedProjectOpts: String
    $type: String!
    $progress: String!
    $medias: [Float!]
    $isPublic: Boolean!
    $fundingStatus: String!
    $genres: [EGenreOpts!]
    $visibility: String
    $locations: [String!]
    $collaborations: [AddProjectCollaborationInput!]
    $videoUrl: String
  ) {
    addProject(
      type: $type
      passionProjectOpts: $passionProjectOpts
      commissionedProjectOpts: $commissionedProjectOpts
      title: $title
      description: $description
      progress: $progress
      fundingStatus: $fundingStatus
      medias: $medias
      isPublic: $isPublic
      visibility: $visibility
      genres: $genres
      locations: $locations
      collaborations: $collaborations
      videoUrl: $videoUrl
    ) {
      __typename
      type
      id
      title
      description
      locations {
        name
      }
      genres {
        name
      }
      progress
    }
  }
`;
