import { useApolloClient, useLazyQuery } from '@apollo/client';
import debounce from 'debounce-promise';
import { useCallback, useState } from 'react';
// import { debounce } from 'lodash'
// import { useLocation } from 'react-router';
// import useReactRouter from 'use-react-router';
import { useHistory, useLocation } from 'react-router-dom'

interface IAutoCompleteProps {
  query: any;
}
interface ISearchProjectResult {
  title: string;
  id: string;
  medias: { fileUrl: string }[];
}
interface ISearchUserResult {
  profile: { name: string };
  avatar: { fileUrl: string };
  professions: { name: string }[]
}

type ISearchResult = ISearchProjectResult & ISearchUserResult;

export enum PathTypes {
  USER = 'user',
  PROJECT = 'project'
}

export const useSearchAutocomplete = (props: IAutoCompleteProps) => {
  const client = useApolloClient();
  const [runQuery, { loading, called }] = useLazyQuery(props.query);
  const [inputStr, setInputStr] = useState<string>('');
  const [optionList, setOptionList] = useState<any[]>([]);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const history = useHistory();

  const location = useLocation();
  const pattern = /^\/creatives|user/i;
  const pathname = location.pathname.match(pattern)
    ? PathTypes.USER
    : PathTypes.PROJECT;

  const onChangeHandler = (params, action) => {
    if (!params || !action) { return; }
    if (params.firstOption) {
      const searchQuery = '/creatives?q=' + inputStr;
      history.push(searchQuery)
    } else {
      history.push(`/${pathname}/${params.id}`);
    }
  }

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const searchQuery = '/creatives?q=' + inputStr;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (pathname === PathTypes.PROJECT) { return; }
      history.push(searchQuery)
      setMenuOpen(false);
    }
  }

  const inputChangeHandler = useCallback(debounce((input: string) => {
    setInputStr(input);
    setMenuOpen(!!input);
  }, 100), [])

  const fetchFromAPI = async (input: string) => {
    // Run a promise-based request for the client to wait for the response.
    const { data: response } = await client.query({
      query: props.query,
      variables: { filter: input }
    });

    const res = response[Object.keys(response)[0]];
    const mapped =
      res &&
      res.length > 0 &&
      res.map(({ title, profile, id, avatar, medias, professions }: ISearchResult) => ({
        avatar: avatar && avatar.fileUrl,
        id,
        label: title || profile.name,
        prjThumbImage: (medias && medias.length > 0 && medias[0].fileUrl) || 'https://via.placeholder.com/150', // default dropdown image for when searching for projects
        professions,
        value: title || profile.name
      }));
    if (pathname === PathTypes.USER) {
      const firstEl = { label: `Searching for ${input}...`, value: `${input}`, firstOption: true }
      mapped.unshift(firstEl)
    }
    setOptionList(mapped);
    return mapped;
  };

  const loadOptions = async (input: string) => {
    if (!input) {
      return;
    }

    return await fetchFromAPI(input);
  };

  // debounce API call
  const deboucedLoadOptions = debounce(loadOptions, 500, { leading: false });

  return {
    called,
    inputChangeHandler,
    inputStr,
    keyDownHandler: pathname === PathTypes.USER ? keyDownHandler : null,
    loadOptions: deboucedLoadOptions,
    loading,
    menuOpen,
    onChangeHandler,
    optionList,
    responseData: null,
    runQuery,
    setInputStr,
    setMenuOpen
  };
};
