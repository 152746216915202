import { gql } from '@apollo/client';

export const GET_ALL_USERS_BY_FILTER = gql`
  query GET_ALL_USERS_BY_FILTER(
    $filterType: String!
    $page: Float!
    $limit: Float!
  ) {
    getAllUsersByFilter(filterType: $filterType, page: $page, limit: $limit) {
      users {
        id
        email

        avatar {
          fileUrl
        }

        desiredCollaborationProfession {
          value: id
          label: name
        }

        skills {
          id
          name
        }

        professions {
          id
          name
        }

        profile {
          id
          name
          bio
          website
          collaborationStatus
          availableAt
        }
      }
      count
      page
    }
  }
`;
