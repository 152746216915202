import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import FormField from '../../FormField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { INITIALIZE_STORYBOARD_IMAGE_GENERATION } from '../../../graphql/INITIALIZE_STORYBOARD_IMAGE_GENERATION';
import { FETCH_STORYBOARD_IMAGE_GENERATION_PROGRESS } from '../../../graphql/FETCH_STORYBOARD_IMAGE_GENERATION_PROGRESS';
import { UPLOAD_GENERATED_STORYBOARD_IMAGE } from '../../../graphql/UPLOAD_GENERATED_STORYBOARD_IMAGE';
import { getShortFilename } from '../../Upload';
import { CHECK_HAS_ACCESS_TO_STORYBOARD_IMAGE_GENERATION_FEATURE } from '../../../graphql/CHECK_HAS_ACCESS_TO_STORYBOARD_IMAGE_GENERATION_FEATURE';

export interface Media {
  id: string;
  uploadType: string;
  fileType: string;
  fileUrl: string;
  isAiGeneratedJustNow?: boolean;
}

const StoryboardGenerationForm: React.FC<{
  setSavedMedia: Dispatch<SetStateAction<Media[]>>;
  setMediaFieldValue: (medias: Media[]) => void;
}> = ({ setSavedMedia, setMediaFieldValue }) => {
  const { loading: isCheckingAccess, data } = useQuery(
    CHECK_HAS_ACCESS_TO_STORYBOARD_IMAGE_GENERATION_FEATURE
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [messageId, setMessageId] = useState('');

  const formSchema = Yup.object().shape({
    scenario: Yup.string()
      .required('This field is required')
      .min(5, 'At least 5 characters')
  });

  const initialValues = {
    scenario: ''
  };

  const handleCloseModal = () => {
    setMessageId('');
    setModalIsOpen(false);
  };

  const [generateStoryboardMutation] = useMutation(
    INITIALIZE_STORYBOARD_IMAGE_GENERATION,
    {
      onCompleted: (res) => {
        const msgId = res?.initializeStoryboardImageGeneration?.messageId;
        if (msgId && typeof msgId === 'string') {
          setMessageId(msgId);
          toast.success('Processing the storyboard image...');
        }
      },
      onError: () => toast.error('Failed to generate image')
    }
  );

  const hasNoAccessToThisFeature =
    !isCheckingAccess &&
    !data?.checkHasAccessToStoryboardImageGenerationFeature?.access;

  if (hasNoAccessToThisFeature) {
    return <></>;
  }

  return (
    <>
      <div className="level level-right">
        <button
          type="button"
          className={`button is-fullwidth-mobile page-add-project-controls-button has-text-weight-semibold is-primary ${
            isCheckingAccess ? 'is-loading' : ''
          }`}
          onClick={() => setModalIsOpen(true)}
          disabled={isCheckingAccess || hasNoAccessToThisFeature}
          style={{
            width: 'auto'
          }}
        >
          Generate With Matchhat
        </button>
      </div>
      <div className={`modal ${modalIsOpen ? 'is-active' : ''}`}>
        <div className="modal-background">
          <section className="container">
            <div className="columns section page-add-project-container">
              <div className="column">
                <div
                  style={{
                    maxHeight: '80vh',
                    overflowY: 'auto'
                  }}
                >
                  <div className="card page-add-project-card is-rounded is-dropshadowed">
                    <Formik
                      validationSchema={formSchema}
                      initialValues={initialValues}
                      onSubmit={async (values, { setSubmitting }) => {
                        await generateStoryboardMutation({
                          variables: values
                        });
                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <>
                          <header className="card-header page-add-project-card-header is-bordered-bottom">
                            <div className="card-header-title is-block">
                              <div className="level">
                                <div className="level-left">
                                  <h1 className="has-text-weight-semibold is-light is-size-5">
                                    Generate Storyboard
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </header>
                          <div className="card-content page-add-project-card-content">
                            <div className="columns">
                              <div className="column is-one-quarter">
                                <h3 className="is-size-5 has-text-weight-bold">
                                  Scenario
                                  <span className="has-text-danger">*</span>
                                </h3>
                              </div>
                              <div className="column is-three-quarters">
                                <div className="field">
                                  <div className="field-body">
                                    <FormField
                                      name="scenario"
                                      type="text"
                                      placeholder="Two ninja fighting in a forest..."
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.scenario}
                                      additionalInputClasses="has-background-white is-small"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {!messageId && (
                            <div className="page-add-project-controls is-bordered-top">
                              <div className="level container page-add-project-controls-container">
                                <div className="level-left page-add-project-controls-container-level" />
                                <div className="level-right page-add-project-controls-container-level">
                                  <button
                                    type="button"
                                    className="button is-fullwidth-mobile is-borderless page-add-project-controls-button has-background-white-ter has-text-weight-semibold has-text-primary"
                                    disabled={isSubmitting}
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className={`button is-fullwidth-mobile page-add-project-controls-button has-text-weight-semibold is-primary ${
                                      isSubmitting && 'is-loading'
                                    }`}
                                    disabled={isSubmitting}
                                    onClick={() => handleSubmit()}
                                  >
                                    Generate
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Formik>

                    {messageId && (
                      <StoryboardImage
                        messageId={messageId}
                        reset={handleCloseModal}
                        setSavedMedia={setSavedMedia}
                        setMediaFieldValue={setMediaFieldValue}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

// 8 seconds
const POLL_INTERVAL_IN_MILLISECONDS = 8000;

const imageNoOptions = [
  {
    value: 0,
    label: 'All'
  },
  {
    value: 1,
    label: 'Top Left (no. 1)'
  },
  {
    value: 2,
    label: 'Top Right (no. 2)'
  },
  {
    value: 3,
    label: 'Bottom Left (no. 3)'
  },
  {
    value: 4,
    label: 'Bottom Right (no. 4)'
  }
];

const StoryboardImage = ({
  messageId,
  setSavedMedia,
  setMediaFieldValue,
  reset
}: {
  messageId: string;
  setSavedMedia: Dispatch<SetStateAction<Media[]>>;
  setMediaFieldValue: (medias: Media[]) => void;
  reset: () => void;
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const [currentProgress, setCurrentProgress] = useState<number | null>(null);
  const [selectedImageNo, setSelectedImageNo] = useState(0);

  const { data, stopPolling, error } = useQuery(
    FETCH_STORYBOARD_IMAGE_GENERATION_PROGRESS,
    {
      variables: {
        messageId
      },
      pollInterval: POLL_INTERVAL_IN_MILLISECONDS,
      onError: () => {
        stopPolling();
        toast.error('Failed to generate the image');
      }
    }
  );

  const [uploadGeneratedStoryboardImageMutate, { loading }] = useMutation(
    UPLOAD_GENERATED_STORYBOARD_IMAGE,
    {
      onError: () => toast.error('Failed to saved the image')
    }
  );

  useEffect(() => {
    const progress = data?.fetchStoryboardImageGenerationProgress?.progress;
    const uri = data?.fetchStoryboardImageGenerationProgress?.uri;

    if (typeof progress === 'number') {
      setCurrentProgress(progress);
    }
    if (typeof uri === 'string') {
      setImageUrl(uri);
    }
    if (progress === 100) {
      stopPolling();
      toast.success('Storyboard generated');
    }
  }, [data]);

  const handleReset = () => {
    setImageUrl('');
    setCurrentProgress(null);
    reset();
  };

  const uploadImage = async () => {
    if (currentProgress !== 100) return;
    const res = await uploadGeneratedStoryboardImageMutate({
      variables: {
        messageId,
        imageNo: selectedImageNo
      }
    });
    const newMedia = res?.data?.uploadGeneratedStoryboardImage;
    if (newMedia) {
      setSavedMedia((prevState) => {
        const updateMedias = [
          ...prevState,
          {
            ...(newMedia as Media),
            isAiGeneratedJustNow: true
          }
        ];
        setMediaFieldValue(updateMedias);
        return updateMedias;
      });
      toast.success('Successfully uploaded the image');
      handleReset();
    }
  };

  return (
    <div className="page-add-project-controls-container">
      {!imageUrl ? (
        <div className="ph-picture level level-item">
          <p>
            Generating...{' '}
            {typeof currentProgress === 'number' ? `${currentProgress}%` : ''}
          </p>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          {currentProgress !== 100 && (
            <p
              style={{
                marginBottom: 5
              }}
            >
              In progress... {currentProgress}%
            </p>
          )}
          <img
            src={imageUrl}
            style={{
              width: '100%',
              height: 'auto'
            }}
          />
        </div>
      )}

      {currentProgress === 100 && (
        <div>
          <div className="card-content page-add-project-card-content">
            <div className="columns">
              <div className="column is-one-quarter">
                <h3 className="is-size-5 has-text-weight-bold">Select Image</h3>
              </div>
              <div className="column is-three-quarters">
                <div className="field">
                  <div className="control switchradio is-flex-desktop is-block-mobile">
                    {imageNoOptions.map((opt) => (
                      <Fragment key={opt.value}>
                        <input
                          type="radio"
                          id={opt.label}
                          value={opt.value}
                          className="switchradio-input is-absolute"
                          onChange={() => setSelectedImageNo(opt.value)}
                          checked={selectedImageNo === opt.value}
                          required
                        />
                        <label
                          htmlFor={opt.label}
                          className="button has-text-weight-bold switchradio-label p-t-1 p-b-1"
                          key={opt.value}
                        >
                          <span className="is-small">{opt.label}</span>
                        </label>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {(currentProgress === 100 || error) && (
        <div className="level level-right">
          <button
            type="button"
            className="button is-fullwidth-mobile is-borderless page-add-project-controls-button has-background-white-ter has-text-weight-semibold has-text-primary"
            onClick={handleReset}
            disabled={loading}
          >
            Close
          </button>
          {!error && (
            <button
              type="button"
              className={`button is-fullwidth-mobile page-add-project-controls-button has-text-weight-semibold is-primary ${
                loading && 'is-loading'
              }`}
              onClick={uploadImage}
            >
              Upload Image
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default StoryboardGenerationForm;

export const StoryboardGeneratedImageTable = ({
  medias,
  setSavedMedia,
  setMediaFieldValue
}: {
  medias: Media[];
  setSavedMedia: Dispatch<SetStateAction<Media[]>>;
  setMediaFieldValue: (medias: Media[]) => void;
}) => {
  const removeFileState = (mediaId: string) => {
    const updatedMedias = medias.filter((media) => media.id !== mediaId);
    setSavedMedia(updatedMedias);
    setMediaFieldValue(updatedMedias);
  };

  /**
   * new generated images will have `isAiGeneratedJustNow` true
   */
  const generatedImages = medias.filter(
    (media) => media.fileUrl && media.isAiGeneratedJustNow
  );

  if (generatedImages.length === 0) {
    return <></>;
  }

  return (
    <div className="list is-hoverable">
      {generatedImages.map((media) => (
        <React.Fragment key={media.id}>
          <a className="list-item columns is-vcentered is-small m-l-0 m-r-0">
            <div className="column is-paddingless">
              <img
                style={{ height: 'auto', width: 40 }}
                src={media.fileUrl}
                alt={media.fileUrl}
              />
            </div>
            <div className="column is-two-thirds is-paddingless">
              {getShortFilename(media.fileUrl)}
            </div>
            <div className="column has-text-centered is-paddingless">
              <span
                className="has-text-weight-semibold has-text-danger"
                onClick={() => removeFileState(media.id)}
              >
                Delete
              </span>
            </div>
          </a>
        </React.Fragment>
      ))}
    </div>
  );
};
