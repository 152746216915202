import type { ReactNode } from 'react';
import React, { createContext, useState } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import { GET_ALL_PROJECTS } from '../graphql/GET_ALL_PROJECTS';
import { renderContents } from './common';

export const PAGE_LIMIT = 5;

interface initialStateType {
  error: ApolloError | undefined,
  isLoading: boolean,
  onFetchMore: any,
  page: number,
  projects: any[],
  totalCount: number
}

const initialState: initialStateType = {
  error: undefined,
  isLoading: false,
  onFetchMore: undefined,
  page: 1,
  projects: [],
  totalCount: 0
};

export const ProjectListContext = createContext(initialState);

interface IProjectContextProviderProps {
  children: ReactNode;
}

export const ProjectListContextProvider = (
  props: IProjectContextProviderProps
) => {
  const { loading, error, data, fetchMore } = useQuery(GET_ALL_PROJECTS, {
    variables: { limit: PAGE_LIMIT, page: 1 }
  });
  const [pageNum, setPageNum] = useState<number>(2);

  const onFetchMore = async () => {
    setPageNum(prevNum => prevNum + 1);
    return await fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getAllProjects: {
            ...cache.getAllProjects,
            projects: [
              ...cache.getAllProjects.projects,
              ...fetchMoreResult.getAllProjects.projects
            ]
          }
        };
      },
      variables: {
        limit: PAGE_LIMIT,
        page: pageNum
      }
    });
  };

  return (
    <ProjectListContext.Provider
      value={{
        error,
        onFetchMore,
        isLoading: loading,
        projects:
          data && data.getAllProjects ? data.getAllProjects.projects : [],
        page: data && data.getAllProjects ? data.getAllProjects.page : 1,
        totalCount:
          data && data.getAllProjects ? data.getAllProjects.totalCount : 0
      }}
    >
      {renderContents({
        children: props.children,
        data,
        error,
        loading
      })}
    </ProjectListContext.Provider>
  );
};
