import type { IClientUser } from '../../interfaces/user';
import React from 'react';

import Navigation from '../../components/Navigation';
import ProjectForm, {
  EFormTypes
} from '../../components/Project/Forms/ProjectForm';

const NewProjectPage: React.FC<IClientUser> = (props: IClientUser) => {
  return (
    <main className="page-add-project">
      <Navigation {...props} />
      <section className="container">
        <ProjectForm {...props} formType={EFormTypes.new} />
      </section>
    </main>
  );
};

export default NewProjectPage;
