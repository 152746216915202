/**
 * Returns show more boolean on user profiles.
 *
 * @param location - react router location
 * @returns Boolean
 */
const showMore = (location: string) => {
  const [, show] = location.split('sm=');
  return show === 'true';
};

export default showMore
