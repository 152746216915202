export default {
  control: base => ({
    ...base,
    // This line disable the blue border
    boxShadow: 'none'
  }),
  menu: (provided: object) => ({
    ...provided,
    zIndex: 40
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#979EBE'
    };
  },
  valueContainer: defaultStyles => {
    return {
      ...defaultStyles
    };
  }
};
