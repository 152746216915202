import type { Dispatch, SetStateAction } from 'react'
import type { IClientUser } from '../../interfaces/user';
import React, {
  Fragment,
  useContext,
  useState
} from 'react';

import { CommentCategory } from '../../components/Comment/constants';
import Navigation from '../../components/Navigation';
import { ProjectFeed } from '../../components/Project/ProjectFeed';
import UserCard from '../../components/User/UserCard';
import UserProfileModal from '../../components/User/UserProfileModal';
import {
  CurrentUserContext,
  CurrentUserContextProvider
} from '../../contexts/currentUserContext';
import { MessageContextProvider } from '../../contexts/messageContext';
import { UserContext, UserContextProvider } from '../../contexts/userContext';
import {
  UserProjectsContext,
  UserProjectsContextProvider
} from '../../contexts/userProjectsContext';

export interface IUserProfile extends IClientUser {
  match: {
    params: {
      userId?: string;
    };
  };
}

const UserProjects = () => {
  const currentUser = useContext(CurrentUserContext);
  const projects = useContext(UserProjectsContext);
  return (
    <Fragment>
      {projects && projects.length > 0 ? (
        projects.map((project, idx) => (
          <ProjectFeed
            key={idx}
            project={project}
            user={currentUser}
            commentFrom={CommentCategory.userProjectListing}
          />
        ))
      ) : (
        <div className="notification is-warning">
          <strong>No available projects yet.</strong>
        </div>
      )}
    </Fragment>
  );
};

const ShowUserCard = ({
  setIsOpen
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const user = useContext(UserContext);

  return (
    <MessageContextProvider currentUser={currentUser}>
      <UserCard
        currentUser={currentUser}
        user={user}
        setIsUserProfileModal={setIsOpen}
      />
    </MessageContextProvider>
  );
};

const UserShow = (props: IUserProfile) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleModalToggle = () => setIsOpen(!modalIsOpen);

  return (
    <main className="page-project-list">
      <Navigation {...props} />
      <section className="container">
        <div className="columns section">
          <div className="column">
            <CurrentUserContextProvider>
              <UserContextProvider userId={props.match.params.userId as any}>
                <ShowUserCard setIsOpen={setIsOpen} />
                <UserProfileModal
                  handleModalToggle={handleModalToggle}
                  modalIsOpen={modalIsOpen}
                />
              </UserContextProvider>
            </CurrentUserContextProvider>
          </div>
          <div className="column is-three-quarters">
            <div className="container">
              <CurrentUserContextProvider>
                <UserProjectsContextProvider userId={props.match.params.userId}>
                  <UserProjects />
                </UserProjectsContextProvider>
              </CurrentUserContextProvider>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default UserShow;
