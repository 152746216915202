import type { IClientUser } from '../interfaces/user';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CreativesFilter from '../components/Creatives/Filter';
import Navigation from '../components/Navigation';
import UserList from '../components/User/UserList';
import { CreativeListContextProvider } from '../contexts/creativeListContext';

export enum EUserFilterTypes {
  default = 'ALL_USERS',
  peopleIamLookingFor = 'PEOPLE_I_AM_LOOKING_FOR_FILTER',
  peopleLookingForMe = 'PEOPLE_LOOKING_FOR_ME_FILTER'
}

const CreativesPage: React.FC<IClientUser> = props => (
  <main className="page-creatives">
    <Fragment>
      <Navigation {...props} />
      <section className="container">
        <CreativeListContextProvider>
          <div className="columns section is-vcentered p-b-0">
            <div className="column">
              <Link to="/creatives">
                <h1 className="is-size-3 has-text-grey-dark has-text-weight-semibold">
                  Creatives
                </h1>
              </Link>
            </div>
          </div>
          <div className="columns p-r-1 p-l-1">
            <CreativesFilter />
            <div className="column is-three-quarters">
              <UserList currentUser={props.user!} />
            </div>
          </div>
        </CreativeListContextProvider>
      </section>
    </Fragment>
  </main>
);

export default CreativesPage;
