import { useApolloClient, useLazyQuery } from '@apollo/client';

export interface IHandleOptionSelect {
  __isNew__: boolean;
  value: string;
  label: string;
}

interface IAutoCompleteProps {
  query: any;
}

/**
 * Auto-complete takes in a valid graphql query and returns its resulting data.
 * This hook only returns one object result at a time.
 */
export const useAutocomplete = (props: IAutoCompleteProps) => {
  const client = useApolloClient();
  const [runQuery, { loading, data, called }] = useLazyQuery(props.query);

  const loadOptions = async (input: string) => {
    if (!input) {
      return;
    }

    // Run a promise-based request for the client to wait for the response.
    const { data: response } = await client.query({
      query: props.query,
      variables: { filter: input }
    });

    return response[Object.keys(response)[0]];
  };

  return {
    called,
    loadOptions,
    loading,
    responseData: data && data[Object.keys(data)[0]],
    runQuery
  };
};
