import React, { useContext } from 'react';
import { ProjectContext } from '../../contexts/projectContext';
import { PrettyContent } from '../Content';
import Video from './Video';

const Story = () => {
    const project = useContext(ProjectContext);

    return (
      <div>
        <div className="page-project-details p-t-1 p-b-2">
            <h3 className="is-size-5 font-size-20 p-t-3 p-b-3 has-text-weight-medium">Project Summary</h3>
            <PrettyContent
              content={project.description}
              isLineBreakEnabled={true}
            />
          </div>
          <Video project={project} />
      </div>
    )
}

export default Story