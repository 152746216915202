import React, { Fragment, useRef, useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import binocularsDarkGreyIcon from '../../assets/svg/binoculars-dark-grey.svg';
import checkCircleDarkGreyIcon from '../../assets/svg/check-circle-dark-grey.svg';
import checkCirclePurpleLighterIcon from '../../assets/svg/check-circle-purple-lighter.svg';
import ellipsisHorizontalIcon from '../../assets/svg/ellipsis-h.svg';
import { ADD_USER_SKILL_addUserSkill_skills } from '../../graphql/types/ADD_USER_SKILL';
import {
  GET_ALL_PROJECTS_getAllProjects_projects,
  GET_ALL_PROJECTS_getAllProjects_projects_collaborations
} from '../../graphql/types/GET_ALL_PROJECTS';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import CommentForm from '../Comment/CommentForm';
import CommentList from '../Comment/CommentList';
import { CommentCategory } from '../Comment/constants';
import { PrettyContent } from '../Content';
import InterestedProject from './Action/Interested';
import LikeProject from './Action/LikeProject';
import ShareLink from './Action/ShareLink';
import ProjectTags from './ProjectTags';
import Video from './Video';

const MAX_DESCRIPTION_LENGTH = 200;

const renderVideoOrMedia = (
  project: GET_ALL_PROJECTS_getAllProjects_projects,
  linkToProject: any
) => {
  return (
    <Fragment>
      {project.videoUrl && <Video project={project} />}
      {!project.videoUrl && project.medias && project.medias.length > 0 && (
        <Link to={linkToProject}>
          <div className="post-card-feature-image">
            <div className="post-card-feature-image-item">
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: 700,
                  height: 'auto',
                  margin: 'auto'
                }}
                src={project.medias[0].fileUrl}
                alt={project.medias[0].fileUrl}
              />
            </div>
          </div>
        </Link>
      )}
    </Fragment>
  );
};

export const ProjectFeed = ({
  project,
  user,
  commentFrom
}: {
  project: GET_ALL_PROJECTS_getAllProjects_projects;
  user: GET_CURRENT_USER_me;
  commentFrom?: CommentCategory;
}) => {
  const linkToProject = {
    pathname: `/project/${project.id}`,
    state: window.location.pathname
  };

  const inputEl = useRef<HTMLInputElement>(null);
  const [collaborations, setCollaborations] = useState<
    GET_ALL_PROJECTS_getAllProjects_projects_collaborations[]
  >([]);
  const [hasTeam, setHasTeam] = useState(false);

  const hasGenres: boolean = project.genres.length > 0;
  const hasMedia: boolean =
    project.videoUrl && Boolean(project.videoUrl.length)
      ? true
      : Boolean(project.medias.length);

  const sortCollaboration = () => {
    if (project.collaborations && project.collaborations.length > 0) {
      setHasTeam(
        project.collaborations.every(
          (
            collaboration: GET_ALL_PROJECTS_getAllProjects_projects_collaborations
          ) => collaboration.isFound
        )
      );
      const sortedCollaborations = project.collaborations
        .slice()
        .sort((collabA, collabB) => {
          return collabA.isFound &&
            Boolean(
              collabA.isFound
                .toString()
                .localeCompare(collabB.isFound.toString())
            )
            ? 1
            : 0;
        });
      setCollaborations(sortedCollaborations);
    }
  };

  const bgImage =
    project.author && project.author.avatar && project.author.avatar.fileUrl
      ? project.author.avatar.fileUrl
      : profilePlaceholder;

  const compressedAvatarImage = getCompressedImagePath(bgImage, 'thumbnail');

  useEffect(() => {
    sortCollaboration();
  }, [project.collaborations]);

  useEffect(() => {
    sortCollaboration();
  }, []);

  return (
    <div
      className="content post-card is-dropshadowed"
      key={`${project.id}-${project.title}`}
    >
      <div
        className={`post-card-header ${
          !hasMedia && 'is-bordered-bottom m-b-0'
        }`}
      >
        <div
          className={`level project-header-level ${
            hasGenres ? 'm-b-0' : 'm-b-1 project-header-level--no-genres'
          } `}
        >
          <p className="is-inline-block is-capitalized is-light">
            {project.type && project.type.toLocaleLowerCase()} project
          </p>
          <div className="level-right">
            <div className="images level-item">
              <img
                src={ellipsisHorizontalIcon}
                alt="ellipsis"
                className="image is-inline is-cursor-pointer"
              />
            </div>
          </div>
        </div>

        <ProjectTags genres={project.genres} />

        <div className="content">
          <Link to={linkToProject}>
            <h3 className="post-card-title">
              <span>{project.title}</span>
            </h3>
          </Link>
          {project.description &&
          project.description.length > MAX_DESCRIPTION_LENGTH ? (
            <Fragment>
              <PrettyContent
                content={project.description.substr(0, MAX_DESCRIPTION_LENGTH)}
                isLineBreakEnabled={false}
              />
              ...{' '}
              <Link to={linkToProject}>
                <span className="has-text-link is-small">Read More</span>
              </Link>
            </Fragment>
          ) : (
            <PrettyContent
              content={project.description}
              isLineBreakEnabled={false}
            />
          )}
        </div>
      </div>

      {renderVideoOrMedia(project, linkToProject)}

      <div className="post-card-label level is-small is-bordered-bottom m-b-0">
        <div className="level-left">
          {project.locations && project.locations[0] && (
            <p className="level-item post-card-label-item is-relative is-capitalized">
              {project.locations[0].name}
            </p>
          )}
          <p className="level-item post-card-label-item is-relative is-capitalized">
            Funding:{' '}
            {project.fundingStatus && project.fundingStatus.toLocaleLowerCase()}
          </p>
        </div>
        {project.createdAt && (
          <div className="level-right">
            <p className="level-item post-card-label-item is-relative">
              Posted: &nbsp;
              <Moment format="YYYY/MM/DD">{project.createdAt}</Moment>
            </p>
          </div>
        )}
      </div>

      <div className="post-card-collaborators is-bordered-bottom">
        {collaborations.length !== 0 ? (
          <Fragment>
            <p className="has-text-weight-semibold is-flex">
              <img
                className="image is-inline"
                src={hasTeam ? checkCircleDarkGreyIcon : binocularsDarkGreyIcon}
                alt={`${
                  hasTeam ? 'check circle dark grey' : 'binoculars dark grey'
                }`}
              />
              {hasTeam ? 'This project now has a team!' : 'Looking for...'}
            </p>
            <div className="post-card-header-collaborators buttons">
              {project.collaborations?.map((collaboration) => (
                <Fragment key={collaboration.id}>
                  {collaboration.requiredSkills &&
                    collaboration.requiredSkills.length > 0 &&
                    collaboration.requiredSkills.map(
                      (
                        skill: ADD_USER_SKILL_addUserSkill_skills,
                        idx: number
                      ) => (
                        <Fragment key={skill.id}>
                          <Link
                            to={linkToProject}
                            key={idx}
                            className="post-card-header-collaborators-item has-text-weight-semibold is-small m-r-1"
                          >
                            <span
                              key={idx}
                              className={`${
                                collaboration.isFound &&
                                'tag is-rounded is-borderless is-lighter is-paddingless'
                              }`}
                            >
                              <span
                                className={`tag-text ${
                                  collaboration.isFound && 'is-flex'
                                }`}
                              >
                                {collaboration.isFound && (
                                  <img
                                    className="image is-inline"
                                    src={checkCirclePurpleLighterIcon}
                                    alt="check circle purpler lighter"
                                  />
                                )}
                                {skill.name}
                              </span>
                            </span>
                          </Link>
                        </Fragment>
                      )
                    )}
                </Fragment>
              ))}
            </div>
          </Fragment>
        ) : (
          <p>
            This project is currently <strong>not</strong> looking for
            collaborations.
          </p>
        )}
      </div>

      <article className="post-card-media media media-author p-t-0 p-b-0">
        <figure
          className="media-left image avatar is-48x48 m-r-1 m-b-1 m-l-0"
          style={{
            backgroundImage: `url(${
              compressedAvatarImage || profilePlaceholder
            })`
          }}
        />
        <div className="media-content">
          <div className="content">
            <p className="is-small">
              <span>Created by </span>
              <Link to={`/user/${project.author && project.author.id}`}>
                {project.author &&
                  project.author.profile &&
                  project.author.profile.name}
              </Link>{' '}
            </p>
            <p>
              {project.author &&
                project.author.professions &&
                project.author.professions.length > 0 &&
                project.author.professions.map((profession, idx) => (
                  <span key={idx} className="is-size-7">
                    {(idx ? ', ' : '') + profession.name}
                  </span>
                ))}
            </p>
          </div>
        </div>
      </article>

      <div className="post-card-stats p-t-0 p-b-0">
        <LikeProject
          pageType={'project'}
          postId={project.id}
          likes={project.likes ? project.likes : []}
          user={user}
          wasLikedByUser={
            project.likes &&
            Boolean(project.likes.find((like) => like.author.id === user.id))
              ? true
              : false
          }
        />
        <InterestedProject
          pageType={'project'}
          projectAuthorId={project.author?.id}
          currentUserId={user.id}
          projectId={project.id}
          user={user}
          interested={project.interested}
          wasInterestedByUser={
            project.interested &&
            Boolean(
              project.interested.find((itrs) => itrs.author.id === user.id)
            )
          }
        />
        <ShareLink project={project} />
        <hr className="m-b-0" />
      </div>
      <div className="p-r-1 p-l-1">
        <CommentList
          comments={project.comments}
          commentCategory={commentFrom}
          user={user}
        />
        <CommentForm
          ref={inputEl}
          projectId={project.id}
          user={user}
          author={project.author ?? undefined}
          commentFrom={commentFrom}
        />
      </div>
    </div>
  );
};
