import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { apolloClient } from './services/apollo';
import * as serviceWorker from './serviceWorker';
import './styles/index.sass';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import Routes from './routes';

export const isProd = /^(prod|production)$/i.test(process.env.NODE_ENV || '');

const ROLLBAR_CONFIG = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  // only enable Rollbar in production
  enabled: isProd,
  payload: {
    environment: 'production'
  }
};

// notifications
toast.configure({
  autoClose: 3000
});

const root = createRoot(document.getElementById('root')!);

root.render(
  <RollbarProvider config={ROLLBAR_CONFIG}>
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary>
      <Routes />
      </ErrorBoundary>
    </ApolloProvider>
  </RollbarProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
