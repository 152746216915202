export function convertV1Content(content: any) {
    const state: any = {
        root: {
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'root',
            version: 1,
            children: [{
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'paragraph',
                version: 1,
                children: []
            }]
        }
    }

    if (!content.blocks) {
        return state
    }

    const entityMap: any[] = []
    for (const [key] of Object.entries(content.entityMap)) {
        const entity = content.entityMap[key];
        if (entity.type === 'mention') {
            entityMap.push({
                type: 'mention',
                data: entity.data.mention
            })
        }
    }
    const entity = entityMap.length > 0? entityMap[0]: undefined;

    for (let i = 0; i < content.blocks.length; i++) {
        const block = content.blocks[i];

        let isMapped = false
        if (entity && entity.type === 'mention') {
            isMapped = true
            const tagIndex = block.text.indexOf(entity.data.name)
            if (tagIndex >= 0) {
                const sentenceComponents = block.text.split(entity.data.name)

                let accumulateLength = 0
                let isAdded = false
                for (let j = 0; j < sentenceComponents.length; j++) {
                    const sentenceComponent = sentenceComponents[j];

                    if (isAdded) {
                        // const textNode = $createTextNode(sentenceComponent);
                        // paragraphNode.append(textNode);
                        state.root.children[0].children.push({
                            detail: 0,
                            format: 0,
                            style: '',
                            text: sentenceComponent,
                            type: 'text',
                            version: 1
                        })
                    } else if (accumulateLength >= tagIndex) {
                        // const mentionNode = $createMentionNode(entity.data.name);
                        // paragraphNode.append(mentionNode);
                        state.root.children[0].children.push({
                            detail: 1,
                            format: 0,
                            mentionName: entity.data.name,
                            mode: 'segmented',
                            style: '',
                            text: entity.data.name,
                            type: 'mention',
                            version: 1
                        })
                        accumulateLength += entity.data.name.length
                        isAdded = true
                    } else {
                        // const textNode = $createTextNode(sentenceComponent);
                        // paragraphNode.append(textNode);
                        state.root.children[0].children.push({
                            detail: 0,
                            format: 0,
                            style: '',
                            text: sentenceComponent,
                            type: 'text',
                            version: 1
                        })
                        accumulateLength += sentenceComponent.length
                    }
                }
            }
        }
        if (!isMapped) {
            // Create a new TextNode
            // const textNode = $createTextNode(block.text);
            // Append the text node to the paragraph
            // paragraphNode.append(textNode);

            state.root.children[0].children.push({
                detail: 0,
                format: 0,
                style: '',
                text: block.text,
                type: 'text',
                version: 1
            })
        }
    }

    return state
}