import { gql } from '@apollo/client';

export const DELETE_USER_SKILL = gql`
  mutation DELETE_USER_SKILL($skillName: String!) {
    deleteUserSkill(skillName: $skillName) {
      id
      skills {
        name
        id
      }
    }
  }
`;
