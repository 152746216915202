import { gql } from '@apollo/client';

export const ADD_USER_PROFESSION = gql`
  mutation ADD_USER_PROFESSION($professionName: String!) {
    addUserProfession(professionName: $professionName) {
      id
      professions {
        name
        id
      }
    }
  }
`;
