import React from 'react';

import { GET_PROJECT_getProject_genres } from '../../graphql/types/GET_PROJECT';
import { projectGenreOptions } from './Forms/constants';

interface IGenreTagsProps {
  genres: GET_PROJECT_getProject_genres[];
}

const ProjectTags = ({ genres }: IGenreTagsProps) => {
  return (
    <div className="level-left project-header-level-left is-block">
      {genres.length > 0 && (
        <div className="tags level-item project-header-level-left-item m-b-1">
          {genres.map((genre: GET_PROJECT_getProject_genres) => (
            <span
              className="tag project-header-tag has-text-primary is-small"
              key={genre.id}
            >
              {
                projectGenreOptions.find(
                  (genreOpt: { value: string }) => genreOpt.value === genre.name
                )?.label
              }
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectTags;
