import React, { Fragment, useEffect, useState } from 'react';
// import { Redirect  } from 'react-router';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

import { apolloClient } from '../services/apollo';
import { isLoggedIn } from '../services/auth';
import { pageView, trackUser } from '../services/googleAnalytics';
import Progress from './Progress';
import RoomListsContextProvider from '../contexts/roomListsContext';

interface ILoggedInUser {
  id?: string;
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState<ILoggedInUser>({});
  const [isOnboarded, setIsOnBoarded] = useState<boolean>(false);
  const location = useLocation();

  useMount(async () => {
    setIsLoading(true);
    const auth: any = await isLoggedIn(apolloClient);
    if (auth && auth.user && auth.user.me) {
      setLoggedInUser(auth.user.me);
      setIsAuthenticated(true);
      setIsOnBoarded(auth.user.me.isOnboarded);
      setIsLoading(false);
      return;
    }
    setIsAuthenticated(false);
    return setIsLoading(false);
  });

  useEffect(() => {
    if (loggedInUser && loggedInUser.id) {
      trackUser(loggedInUser.id);
      pageView(location.pathname);
    }
  }, [location, loggedInUser]);

  const getComponentOrRedirect = (props) => {
    if (!isAuthenticated) {
      return <Redirect to="/landing" />;
    }

    return !isOnboarded && props.match.url !== '/onboarding' ? (
      <Redirect to="/onboarding" />
    ) : (
      <Component {...props} user={loggedInUser} />
    );
  };

  return (
    <Fragment>
      <RoomListsContextProvider>
        <Progress isAnimating={isLoading} />
        {!isLoading && (
          <Route {...rest} render={(props) => getComponentOrRedirect(props)} />
        )}
      </RoomListsContextProvider>
    </Fragment>
  );
};

export default PrivateRoute;
