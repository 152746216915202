import { Field, useField } from 'formik';
import React from 'react';

export interface IFormFieldProps {
  ref?: React.Ref<HTMLInputElement>;
  name?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  labelRequired?: boolean;
  hasAddons?: JSX.Element;
  multiline?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  additionalControlClasses?: string[];
  additionalFieldClasses?: string[];
  additionalInputClasses?: string;
  displayErrorMessage?: boolean;
  onChange?: (eventOrTextValue: string | React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
}

interface ICheckboxProps {
  name: string;
  value: string;
  label: string;
}

export const Checkbox = (props: ICheckboxProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }) => {
        const isValueChecked = field.value.includes(props.value);
        return (
          <label className="checkbox checkbox-container is-small">
            {props.label}
            <input
              type="checkbox"
              {...props}
              checked={isValueChecked}
              onChange={() => {
                // Toggles the selected values in the current field
                if (isValueChecked) {
                  const removeCurrentValues = field.value.filter(
                    (value: string | number | string[]) => value !== props.value
                  );
                  form.setFieldValue(props.name, removeCurrentValues);
                } else {
                  const addCurrentValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, addCurrentValue);
                }
              }}
            />
            <span className="checkmark is-absolute m-l-0" />
          </label>
        );
      }}
    </Field>
  );
};

const FormField: React.FunctionComponent<IFormFieldProps> = React.forwardRef((
  props: IFormFieldProps,
  ref: React.Ref<HTMLInputElement>
) => {
  const [field, meta] = useField(props.name!);

  const additionalFieldClasses =
    props.additionalFieldClasses && props.additionalFieldClasses.join(' ');
  const additionalControlClasses =
    props.additionalControlClasses && props.additionalControlClasses.join(' ');

  const inputElement = props.multiline ? (
    <textarea
      className={`textarea ${props.additionalInputClasses} ${meta.error &&
        meta.touched &&
        'input-error'}`}
      {...field}
      placeholder={props.placeholder}
    />
  ) : (
    <input
      className={`input ${
        props.additionalInputClasses
      } ${props.displayErrorMessage &&
        meta.error &&
        meta.touched &&
        'input-error'}`}
      {...field}
      type={props.type}
      ref={ref}
      placeholder={props.placeholder}
    />
  );

  return (
    <div className={`field ${additionalFieldClasses}`}>
      {props.label && (
        <label className={`label ${additionalFieldClasses}`}>
          {props.label}
          {props.labelRequired && <span className="has-text-danger">*</span>}
        </label>
      )}
      <div className={`control ${additionalControlClasses}`}>
        {props.iconLeft}
        {inputElement}
        {props.iconRight}
      </div>
      {props.hasAddons}
      {props.displayErrorMessage && (
        <span className="field-error has-text-danger">
          {meta.error && meta.touched && meta.error}
        </span>
      )}
    </div>
  );
});

FormField.defaultProps = {
  additionalControlClasses: [],
  additionalInputClasses: '',
  displayErrorMessage: true,
  multiline: false
};

export default FormField;
