import { gql } from '@apollo/client';

export const ADD_PROJECT_COMMENT = gql`
  mutation ADD_PROJECT_COMMENT($projectId: String!, $commentJson: JSONObject!, $commentRaw: String!) {
    addProjectComment(projectId: $projectId, commentJson: $commentJson, commentRaw: $commentRaw) {
      __typename
      id
      version
      contentJson
      createdAt
      author {
        id
        email
        avatar {
          fileUrl
        }
        profile {
          id
          name
        }
      }

      likes {
        id
        author {
          id
          profile {
            id
            name
          }
        }
      }
    }
  }
`;
