import type { IClientUser } from '../interfaces/user';
import React from 'react';
import SignInForm from '../components/User/Forms/SignInForm';
import { SignContextProvider } from '../contexts/signInContext';

const SignInPage: React.FC<IClientUser> | any = (props: IClientUser) => {
  return (
    <SignContextProvider>
      <div className="page-sign-in">
        <SignInForm {...props} />
      </div>
    </SignContextProvider>
  );
};

export default SignInPage;
