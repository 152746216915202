import { gql } from '@apollo/client';

export const REGISTER_USER = gql`
  mutation REGISTER_USER(
    $email: String!
    $name: String!
    $password: String!
    $passwordConfirm: String!
  ) {
    registerUser(
      email: $email
      name: $name
      password: $password
      passwordConfirm: $passwordConfirm
    ) {
      id
    }
  }
`;
