import { gql } from '@apollo/client';

export const CONFIRM_USER = gql`
  mutation CONFIRM_USER($token: String!) {
    confirmUser(token: $token) {
      __typename
      accessToken
      user {
        id
        email
        userConfirmed
        confirmationToken
        avatar {
          fileUrl
        }
        isOnboarded
        profile {
          id
          name
          bio
          website
          collaborationStatus
        }

        skills {
          id
          name
        }

        professions {
          id
          name
        }
      }
    }
  }
`;
