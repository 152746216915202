import { gql } from '@apollo/client';

export const ADD_USER_SKILL = gql`
  mutation ADD_USER_SKILL($skillName: String!) {
    addUserSkill(skillName: $skillName) {
      id
      skills {
        name
        id
      }
    }
  }
`;
