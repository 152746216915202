import { InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import React, { forwardRef, Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import sendIconWhite from '../../assets/svg/send-icon-white.svg';
import { PAGE_LIMIT } from '../../contexts/projectListContext';
import { ADD_PROJECT_COMMENT } from '../../graphql/ADD_PROJECT_COMMENT';
import { ADD_PROJECT_UPDATE_COMMENT } from '../../graphql/ADD_PROJECT_UPDATE_COMMENT';
import { GET_ALL_PROJECTS } from '../../graphql/GET_ALL_PROJECTS';
import { GET_ALL_PROJECTS_BY_USER } from '../../graphql/GET_ALL_PROJECTS_BY_USER';
import { GET_ALL_USERS_MENTION } from '../../graphql/GET_ALL_USERS_MENTION';
import { GET_PROJECT } from '../../graphql/GET_PROJECT';
import { GET_ALL_PROJECTS_getAllProjects_projects_author } from '../../graphql/types/GET_ALL_PROJECTS';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { useRedirect } from '../../hooks/useRedirect';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import { CommentCategory } from '../Comment/constants';
import { LexicalComposer, InitialConfigType } from '@lexical/react/LexicalComposer';
import { Editor } from '../Editor';
import { MentionNode } from '../Editor/MentionNode';
import EditorTheme from '../Editor/theme';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
interface MentionData {
  id: string,
  name: string,
  link: string,
  avatar: string
}
interface ICommentProject {
  projectId: string;
  user?: GET_CURRENT_USER_me;
  commentFrom?: CommentCategory;
  author?: GET_ALL_PROJECTS_getAllProjects_projects_author;
  ref?: React.Ref<HTMLInputElement>;
}

const FormWrapper: React.FunctionComponent<ICommentProject> = (props: ICommentProject) => {
    const [usersMentionData, setUsersMentionData] = useState<MentionData[]>([]);
    const [addProjectComment] = useMutation(ADD_PROJECT_COMMENT);
    const [addProjectUpdateComment] = useMutation(ADD_PROJECT_UPDATE_COMMENT, {
      refetchQueries: ['GET_PROJECT']
    });
    const [editorState, setEditorState] = useState<any>();
    const { redirectToHomePage } = useRedirect();
    const [editor] = useLexicalComposerContext();
    const [isFocus, setIsFocus] = useState(false)

    const response = useQuery(GET_ALL_USERS_MENTION);
    const responseData = response.data;
    React.useEffect(() => {
      if (responseData) {
        const allUsersMentionData: MentionData[] = responseData.getAllUsersMention.map(
          user => {
            return {
              avatar:
                getCompressedImagePath(user.avatar.fileUrl, 'thumbnail') ||
                profilePlaceholder,
              id: user.id,
              link: `${process.env.REACT_APP_MATCHHAT_BASE_URL}/user/${user.id}`,
              name: user.profile.name
            };
          }
        );
        setUsersMentionData(allUsersMentionData);
      }
    }, [responseData]);

    const isCommentEmpty = (): boolean => {
      // const contentState = editorState.getCurrentContent();
      // return contentState.getPlainText().trim() === '';
      // console.log('editorState', typeof editorState)
      // return Boolean(editorState)
      return !editorState || editorState.root.children.length === 0 || editorState.root.children[0].children.length === 0
    };

    const renderForm: React.FC = (formikData: FormikValues) => {
      const { handleSubmit, isSubmitting } = formikData;
      const avatarImageUrl = props.user && props.user.avatar && props.user.avatar.fileUrl;

      return (
        <div className="comment-form">
          <article className="comment-form-media media">
            <figure
              className="media-left image avatar is-32x32"
              style={{
                backgroundImage: `url(${getCompressedImagePath(
                  avatarImageUrl!,
                  'thumbnail'
                ) || profilePlaceholder})`
              }}
            />
            <div className="comment-form-media-content media-content is-paddingless">
              <form onSubmit={handleSubmit}>
                <div className="is-flex">
                  <div
                    className="is-small comment-form-control-input"
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                  >
                    <Editor
                      usersMentionData={usersMentionData}
                      editorState={editorState}
                      setEditorState={setEditorState}
                      placeholder="Enter comment"
                      isFocus={isFocus}
                    />
                  </div>
                  <Fragment>
                    <button
                      type="submit"
                      className={`button comment-form-control-button has-cursor-pointer ${isSubmitting &&
                        'is-loading'}`}
                      disabled={isCommentEmpty() || isSubmitting}
                    >
                      <img
                        src={sendIconWhite}
                        alt="send icon white"
                        className="image is-16x16"
                      />
                    </button>
                  </Fragment>
                </div>
              </form>
            </div>
          </article>
        </div>
      );
    };

    return (
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (!editorState) {
            return
          }

          if (!props.user) {
            redirectToHomePage();
            return;
          }

          if (props.commentFrom === CommentCategory.projectUpdate) {
            await addProjectUpdateComment({
              variables: {
                commentJson: editorState,
                // commentJson: convertToRaw(contentState),
                // commentRaw: contentPlainText,
                commentRaw: JSON.stringify(editorState),
                updateId: props.projectId
              }
            })
          } else {
            await addProjectComment({
              update: (cache: InMemoryCache, { data }) => {
                /**
                 * There are 2 sources of cache data of a given project.
                 * One from the getAllProjects() query and one from the getProject() query.
                 *
                 * When a user adds a comment from the project listings page, then we get the
                 * current project data's properties from the getAllProjects() query.
                 * Similarly, when a user adds a comment from the project's page, then we get the
                 * current project data's properties from the getProject() query.
                 */
                let readQueryResult;
                let query;
                let variables;
                let writeQueryData;

                if (props.commentFrom === CommentCategory.projectListing) {
                  query = GET_ALL_PROJECTS;
                  variables = { limit: PAGE_LIMIT, page: 1 };
                  readQueryResult = cache.readQuery({ query, variables });
                  const projectsWithNewComment = readQueryResult.getAllProjects.projects.map(
                    p => {
                      if (p.id !== props.projectId) {
                        return p;
                      }

                      return {
                        ...p,
                        comments: [...p.comments, data.addProjectComment]
                      };
                    }
                  );
                  writeQueryData = {
                    getAllProjects: {
                      ...readQueryResult.getAllProjects,
                      projects: [...projectsWithNewComment]
                    }
                  };
                } else if (props.commentFrom === CommentCategory.userProjectListing) {
                  query = GET_ALL_PROJECTS_BY_USER;
                  // variables = { userId: parseInt(props.author!.id, 10) };
                  variables = { userId: props.author!.id };
                  readQueryResult = cache.readQuery({ query, variables });
                  const projectsWithNewComment = readQueryResult.getAllProjectsByUser.map(
                    p => {
                      if (p.id !== props.projectId) {
                        return p;
                      }

                      return {
                        ...p,
                        comments: [...p.comments, data.addProjectComment]
                      };
                    }
                  );

                  writeQueryData = {
                    getAllProjectsByUser: [...projectsWithNewComment]
                  };
                } else if (props.commentFrom === CommentCategory.project) {
                  query = GET_PROJECT;
                  variables = { projectId: props.projectId };
                  readQueryResult = cache.readQuery({ query, variables });
                  const currentProject = readQueryResult.getProject;
                  writeQueryData = {
                    getProject: {
                      ...currentProject,
                      comments: [
                        ...currentProject.comments,
                        data.addProjectComment
                      ]
                    }
                  };
                } else {
                  toast.error(
                    'There is something wrong happen when you like ...'
                  );
                }

                return cache.writeQuery({
                  data: { ...writeQueryData },
                  query,
                  variables
                });
              },

              variables: {
                commentJson: editorState,
                // commentJson: convertToRaw(contentState),
                // commentRaw: contentPlainText,
                commentRaw: JSON.stringify(editorState),
                projectId: props.projectId
              }
            });
          }

          setSubmitting(false);
          setEditorState(null);
          const newEditorState = editor.parseEditorState('{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}');
          editor.setEditorState(newEditorState);
          resetForm();
        }}
      >
        {renderForm}
      </Formik>
    );
}

const CommentProject: React.FunctionComponent<ICommentProject> = forwardRef(
  (props: ICommentProject, ref: React.Ref<HTMLInputElement>) => {
    function onError(error) {
      console.error(error);
    }

    const initialConfig: InitialConfigType = {
      namespace: 'CommentEditor',
      nodes: [MentionNode],
      theme: EditorTheme,
      onError
    };

    return (
      <LexicalComposer initialConfig={initialConfig} >
          <FormWrapper {...props}/>
      </LexicalComposer>
    )
  }
)

CommentProject.defaultProps = {
  commentFrom: CommentCategory.project
};

export default CommentProject;
