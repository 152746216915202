import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
  query GET_ALL_NOTIFICATIONS {
    getAllNotifications {
      __typename
      id
      model
      modelId
      readAt
      actor {
        id
        email
        avatar {
          fileUrl
        }

        profile {
          id
          name
        }
      }
      receiver {
        id
        email
      }
      type
      createdAt
    }
  }
`;
