import { gql } from '@apollo/client';

export const PRESIGN_LINK = gql`
  mutation PRESIGN_LINK(
    $fileName: String!
    $fileType: String!
    $uploadType: String!
  ) {
    preSignLink(
      fileName: $fileName
      fileType: $fileType
      uploadType: $uploadType
    ) {
      signedRequest
      url
    }
  }
`;
