import { gql } from '@apollo/client';

export const HAS_SINGLE_CHAT_HISTORY = gql`
  query HAS_SINGLE_CHAT_HISTORY($otherUserId: Float!) {
    hasSingleChatHistory(otherUserId: $otherUserId) {
      hasRoomHistory
      room {
        __typename
        id
        members {
          email
          id
        }
      }
    }
  }
`;
