import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import arrowDown from '../../assets/svg/arrow-down.svg';
import checkCircleGreenFilled from '../../assets/svg/check-circle-green__filled.svg';
import ellipsis from '../../assets/svg/ellipsis-h-solid.svg';
import { ProjectContext } from '../../contexts/projectContext';
import { ADD_USER_SKILL_addUserSkill_skills } from '../../graphql/types/ADD_USER_SKILL';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { GET_PROJECT_getProject_collaborations } from '../../graphql/types/GET_PROJECT';
import { UPDATE_COLLABORATION } from '../../graphql/UPDATE_COLLABORATION';
import { PrettyContent } from '../Content';

interface IProjectCollabAccordionProps {
  collaboration: GET_PROJECT_getProject_collaborations;
  user?: GET_CURRENT_USER_me;
}

const ProjectCollabAccordion: FunctionComponent<
  IProjectCollabAccordionProps
> = (props: IProjectCollabAccordionProps) => {
  const project = useContext(ProjectContext);
  const isProjectOwner =
    project &&
    project.author &&
    props.user &&
    project.author.id === props.user.id;

  const [isActive, setActive] = useState<boolean>(false);
  const [isActiveCollabOpts, setIsActiveCollabOpts] = useState<boolean>(false);
  const accordionContentRef: React.MutableRefObject<HTMLDivElement | null> = useRef(
    null
  );

  const toggleAccordion = () => setActive(!isActive);
  const toggleCollabOpts = () => setIsActiveCollabOpts(!isActiveCollabOpts);

  const [editCollaboration] = useMutation(UPDATE_COLLABORATION, {
    onCompleted: () => toast.success('Collaboration updated.')
  });

  const {
    additionalRequirements,
    compensation,
    id,
    isFound,
    requiredSkills
  } = props.collaboration;

  const toggleCollaborationIsFound = async (isFoundValue: boolean) => {
    await editCollaboration({
      variables: {
        collaborationId: id,
        isFound: isFoundValue,
        skills: requiredSkills ? requiredSkills.map(skill => skill.name) : []
      }
    });

    toggleCollabOpts();
  };

  const DropdownItem = () => (
    <Link
      to="#!"
      className="dropdown-item"
      onClick={e => {
        e.preventDefault();
        toggleCollaborationIsFound(!isFound ?? false);
      }}
    >
      {!isFound ? "Change to 'Found'" : 'Remove'}
    </Link>
  );

  return (
    <div className="card has-background-grey" key={id}>
      <header
        className={`card-header is-shadowless is-paddingless accordion ${isActive &&
          'active'}`}
      >
        <p className="card-header-title" onClick={toggleAccordion}>
          <span className="card-header-icon is-paddingless m-r-1">
            <img
              className={`accordion__icon ${isActive &&
                !isFound &&
                'accordion--rotate'}`}
              src={isFound ? checkCircleGreenFilled : arrowDown}
              alt={isFound ? 'check circle green filled' : 'arrow down'}
              width={isFound ? '14px' : '10px'}
            />
          </span>
          <span
            className={`${
              isFound ? 'has-text-weight-normal' : 'has-text-weight-bold'
            }`}
            onClick={toggleAccordion}
          >
            {requiredSkills
              ? requiredSkills.map(
                  (skill: ADD_USER_SKILL_addUserSkill_skills, idx: number) => (
                    <span key={skill.id}>
                      {skill.name}
                      {requiredSkills.length !== idx + 1 ? ', ' : ''}
                    </span>
                  )
                )
              : 'None'}
          </span>
        </p>
        {isProjectOwner && (
          <div className="card-header-icon is-light">
            <div
              className={`dropdown is-right ${isActiveCollabOpts &&
                'is-active'}`}
            >
              <div className="dropdown-trigger">
                <img
                  aria-haspopup="true"
                  src={ellipsis}
                  alt="ellipsis"
                  width="18px"
                  onClick={toggleCollabOpts}
                />
              </div>
              <div className="dropdown-menu" role="menu">
                <div className="dropdown-content is-overflow-visible">
                  <DropdownItem />
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      <div
        className="accordion__content card-content is-paddingless"
        ref={accordionContentRef}
        style={{
          maxHeight: isActive ? accordionContentRef?.current?.scrollHeight : 0
        }}
      >
        <div className="columns page-project-collaborators-columns is-small p-t-1 p-r-1 p-l-1">
          <div className="column page-project-collaborators-column is-one-third has-background-white">
            <div className="content">
              <p className="m-b-1">
                <span className="is-light is-block is-size-7">Location:</span>
                { (project.locations && project.locations.length !== 0) && project.locations[0].name }
              </p>
              <p className="is-capitalized">
                <span className="is-light is-block is-size-7">
                  Compensation:
                </span>
                {compensation && compensation.toLocaleLowerCase()}
              </p>
            </div>
          </div>
          <div className="column page-project-collaborators-column has-background-white">
            <div className="content">
              <p className="m-b-1">
                <span className="is-light is-block is-size-7">
                  Skills and Requirements
                </span>
                {requiredSkills
                  ? requiredSkills.map(
                      (
                        skill: ADD_USER_SKILL_addUserSkill_skills,
                        idx: number
                      ) => (
                        <span key={skill.id}>
                          {skill.name}
                          {requiredSkills.length !== idx + 1 ? ', ' : ''}
                        </span>
                      )
                    )
                  : 'None'}
              </p>
              <p>
                <span className="is-light is-block is-size-7">
                  Additional Information:
                </span>
                <PrettyContent
                  content={additionalRequirements ?? ''}
                  isLineBreakEnabled={true}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="is-flex is-pulled-right">
          <Link
            to="#"
            className="card-footer-item has-text-weight-semibold has-text-grey-dark is-borderless"
          >
            Recommend
          </Link>
          <Link
            to="#"
            className="card-footer-item has-text-weight-semibold has-text-grey-dark is-borderless"
          >
            Interested
          </Link>
          <Link to="#" className="card-footer-item">
            <img src={ellipsis} alt="" width="18px" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCollabAccordion;
