import React, { Fragment, SyntheticEvent, useContext, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import backButtonImage from '../../assets/svg/back.svg';
import editProjectImage from '../../assets/svg/edit.svg';
import mailIcon from '../../assets/svg/mail.svg';
import plusCircleSolid from '../../assets/svg/plus-circle-solid.svg';
import { MessageContext } from '../../contexts/messageContext';
import {
  ProjectContext,
  ProjectContextProvider
} from '../../contexts/projectContext';
import { UserContext, UserContextProvider } from '../../contexts/userContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { GET_PROJECT_getProject } from '../../graphql/types/GET_PROJECT';
import { useRedirect } from '../../hooks/useRedirect';
import { IProjectShowPageProps } from '../../pages/project/show';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import { PrettyContent } from '../Content';
import ImageSlider from '../ImageSlider';
import Tab from '../Tab';
import InterestedProject from './Action/Interested';
import LikeProject from './Action/LikeProject';
import ShareLink from './Action/ShareLink';
import Collaborators from './ProjectCollaborator';
import Comment from './ProjectComment';
import Story from './ProjectStory';
import ProjectTags from './ProjectTags';
import Update from './ProjectUpdate';

interface IProjectContentProps {
  user: GET_CURRENT_USER_me;
}

interface ISidebarContentProps {
  project: GET_PROJECT_getProject;
  compressedImage: string;
}

const ProjectContent = ({ user }: IProjectContentProps) => {
  const project = useContext(ProjectContext);
  const isProjectOwner =
    project && project.author && user && project.author.id === user.id;

  return (
    <Fragment>
      <div className="content page-project-content is-bordered-bottom p-b-0 m-b-0">
        <header className="project-header">
          <div
            className={`level project-header-level ${
              !project.genres.length && !isProjectOwner && 'm-b-0'
            }`}
          >
            <ProjectTags genres={project.genres} />
            {isProjectOwner && (
              <div className="level-right">
                <Link to={`/project/${project.id}/update`} className="is-flex">
                  <img
                    src={editProjectImage}
                    alt="Edit project"
                    className="image is-inline"
                  />
                  <span className="is-small">Edit</span>
                </Link>
              </div>
            )}
          </div>
          <div className="project-header-top is-bordered-bottom is-block">
            <h1 className="is-size-4 is-relative p-r-3">{project.title}</h1>
          </div>
          <div className="project-header-bottom">
            <div className="level is-small">
              <div className="level-left">
                {project.type && (
                  <p className="level-item is-capitalized">
                    {project.type.toLocaleLowerCase()}
                  </p>
                )}
                {project.locations && project.locations[0] && (
                  <p className="level-item is-capitalized">
                    {project.locations[0].name}
                  </p>
                )}
                <p className="level-item is-capitalized">
                  Funding:{' '}
                  {project.fundingStatus &&
                    project.fundingStatus.toLocaleLowerCase()}
                </p>
              </div>
              <div className="level-right">
                <p className="level-item">
                  Posted: &nbsp;
                  <Moment format="YYYY/MM/DD">{project.createdAt}</Moment>
                </p>
              </div>
            </div>
          </div>
        </header>
        <ImageSlider medias={project.medias} />
      </div>
      <Tab
        contents={[
          {
            title: 'Story',
            contentCount: 0,
            body: <Story />
          },
          {
            title: 'Update',
            contentCount: project.updates.length,
            body: <Update user={user} />
          },
          {
            title: 'Collaborators',
            contentCount: project.collaborations
              ? project.collaborations.length
              : 0,
            body: <Collaborators user={user} />
          },
          {
            title: 'Comment',
            contentCount: project.comments.length,
            body: <Comment user={user} />
          }
        ]}
      />
    </Fragment>
  );
};

// Content for ProjectSidebar
const SidebarContent = ({ project, compressedImage }: ISidebarContentProps) => {
  const user = useContext(UserContext);
  const { actions } = useContext(MessageContext);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleSendMessageOnClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsSelecting(true);
    try {
      await actions.handleSelectOtherUser(user);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSelecting(false);
    }
  };

  return (
    <div className="card is-dropshadowed">
      <header className="card-header is-bordered-bottom m-b-0">
        <p className="card-header-title has-text-weight-normal">
          <span className="is-small is-light">Project by</span>
        </p>
      </header>
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure
              className="image avatar is-48x48"
              style={{ backgroundImage: `url(${compressedImage})` }}
            />
          </div>
          <div className="media-content">
            <p className="is-size-5 m-b-0">
              <Link
                to={`/user/${project.author && project.author.id}`}
                className="has-text-black"
              >
                {project.author &&
                  project.author.profile &&
                  project.author.profile.name}
              </Link>
            </p>
            <p>
              {project.author &&
                project.author.professions &&
                project.author.professions.map((profession, idx) => (
                  <span key={idx} className="is-small is-light">
                    {(idx ? ', ' : '') + profession.name}
                  </span>
                ))}
            </p>
          </div>
        </div>

        <div className="content">
          <p className="is-small">
            {project.author &&
              project.author.profile &&
              project.author.profile.bio && (
                <PrettyContent
                  content={project.author.profile.bio}
                  isLineBreakEnabled={true}
                />
              )}
          </p>
        </div>
      </div>
      <footer className="card-footer">
        <Link
          to={`/user/${
            project.author && project.author.id && project.author.id
          }`}
          className="card-footer-item is-small has-text-grey-dark"
        >
          Visit Profile
        </Link>

        <div
          className="card-footer-item is-small has-text-grey-dark has-cursor-pointer"
          onClick={handleSendMessageOnClick}
        >
          <img
            src={mailIcon}
            alt="mail icon"
            className="image is-inline"
            width="18px"
            title="Send a message"
          />
          <span>{isSelecting ? 'Loading' : 'Message'}</span>
        </div>
      </footer>
    </div>
  );
};
const ProjectSideBar = ({ user }: IProjectContentProps) => {
  const project = useContext(ProjectContext);

  const renderProjectAuthor = (project: GET_PROJECT_getProject) => {
    const authorAvatarImgUrl =
      (project.author &&
        project.author.avatar &&
        project.author.avatar.fileUrl &&
        project.author.avatar.fileUrl) ??
      profilePlaceholder;

    const compressedImage = getCompressedImagePath(
      authorAvatarImgUrl,
      'thumbnail'
    );

    return (
      <UserContextProvider userId={project.author!.id}>
        <SidebarContent project={project} compressedImage={compressedImage} />
      </UserContextProvider>
    );
  };

  return (
    <Fragment>
      {/* Sidebar - Stats */}
      <div className="card is-dropshadowed">
        <div className="card-content p-t-1 p-r-1 p-b-1 p-l-1">
          <div className="is-block is-flex has-flex-direction-column">
            <LikeProject
              user={user}
              pageType={'project'}
              postId={project.id}
              likes={project.likes ? project.likes : []}
              wasLikedByUser={
                project.likes &&
                Boolean(
                  project.likes.find((like) => like.author.id === user.id)
                )
                  ? true
                  : false
              }
            />
            <InterestedProject
              user={user}
              pageType={'project'}
              projectId={project.id}
              interested={project.interested}
              wasInterestedByUser={
                project.interested &&
                Boolean(
                  project.interested.find(
                    (interested) => interested.author.id === user.id
                  )
                )
              }
            />
            <ShareLink project={project} />
          </div>
        </div>
      </div>

      {/* Sidebar - Project Author */}
      {renderProjectAuthor(project)}

      {/* Sidebar - Fans */}
      <div className="card page-project-sidebar-fans is-dropshadowed">
        <header className="card-header is-block is-shadowless">
          <h3 className="card-header-title has-text-weight-normal is-small">
            Promote this project
          </h3>
          <form action="#">
            <div className="field">
              <div className="control has-icons-right p-r-1 p-l-1">
                <input
                  className="input is-small"
                  type="text"
                  placeholder="Recommend a friend"
                />
                <span className="icon is-small is-right">
                  <img
                    width="14px"
                    className="m-r-1"
                    src={plusCircleSolid}
                    alt="plus circle"
                  />
                </span>
              </div>
            </div>
          </form>
        </header>
        <div className="card-content">
          <h3 className="has-text-weight-normal is-small m-b-1">
            Fans{' '}
            <Link to="#" className="is-light is-pulled-right">
              {project.likes
                ? project.likes.length > 1
                  ? `${project.likes.length} fans`
                  : `${project.likes.length} fan`
                : 0}
            </Link>
          </h3>
          <div className="columns page-project-sidebar-fans-columns is-multiline is-mobile is-table">
            {project.likes &&
              project.likes.map((like) => {
                const likeBgImage =
                  like && like.author.avatar && like.author.avatar.fileUrl;
                const compressedLikeBgImage = getCompressedImagePath(
                  likeBgImage,
                  'thumbnail'
                );
                return (
                  <div className="column is-pulled-left" key={like.id}>
                    <Link
                      to={`/user/${like.author && like.author.id}`}
                      className="tooltip"
                    >
                      <figure
                        className="image is-42x42 is-rounded page-project-list-sidebar-fans"
                        style={{
                          backgroundImage: `url(${
                            compressedLikeBgImage || profilePlaceholder
                          })`
                        }}
                      />
                      <span className="tooltiptext has-text-centered is-size-8">
                        {like.author.profile.name}
                      </span>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const PrivateProject: React.FunctionComponent<IProjectShowPageProps> = (
  props: IProjectShowPageProps
) => {
  const { redirectToHomePage } = useRedirect();

  return (
    <Fragment>
      <div className="container">
        <div className="section p-b-0 project-control w-pc-25">
          {props.user && (
            <a
              href="#"
              className="has-text-weight-medium is-flex is-light is-small"
              onClick={() => redirectToHomePage()}
            >
              <span className="m-r-1">
                <img src={backButtonImage} alt="Back button" />
              </span>
              <strong>Back to projects</strong>
            </a>
          )}
        </div>
        <div className="columns section">
          <ProjectContextProvider projectId={props.match.params.projectId}>
            <div className="column is-three-quarters has-background-white is-paddingless is-rounded is-dropshadowed">
              <ProjectContent user={props.user!} />
            </div>
            <div className="column page-project-sidebar p-t-0">
              <ProjectSideBar user={props.user!} />
            </div>
          </ProjectContextProvider>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivateProject;
