import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GET_CURRENT_USER {
    me {
      id
      email
      userConfirmed
      confirmationToken
      avatar {
        fileUrl
      }
      isOnboarded
      profile {
        id
        name
        bio
        website
        collaborationStatus
      }

      desiredCollaborationProfession {
        value: id
        label: name
      }

      skills {
        id
        name
      }

      professions {
        id
        name
      }
    }
  }
`;
