import type { match } from 'react-router-dom';
import type { IClientUser } from '../../interfaces/user';
import React from 'react';

import Navigation from '../../components/Navigation';
import PrivateProject from '../../components/Project/PrivateProject';
import PublicProject from '../../components/Project/PublicProject';

interface IProjectShowMatch extends match {
  params: {
    projectId: string;
  };
}

export interface IProjectShowPageProps extends IClientUser {
  projectId: string;
  match: IProjectShowMatch;
}

const ShowProjectPage: React.FC<IProjectShowPageProps> = (
  props: IProjectShowPageProps
) => {
  return (
    <main className="page-project">
      <Navigation client={props.client} user={props.user} />
      {props.user ? <PrivateProject {...props} user={props.user} /> : <PublicProject {...props} />}
    </main>
  );
};

export default ShowProjectPage;
