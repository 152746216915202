import React, { useContext } from 'react';
import { ProjectContext } from '../../contexts/projectContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { GET_PROJECT_getProject_collaborations } from '../../graphql/types/GET_PROJECT';
import ProjectCollabAccordion from './ProjectCollabAccordion';

interface IProjectContentProps {
  user: GET_CURRENT_USER_me;
}

const Collaborators = ({ user }: IProjectContentProps) => {
  const project = useContext(ProjectContext);
  const hasCollaborations: boolean =
    (project.collaborations && project.collaborations.length > 0)? true: false;
  const lookingForCollaborations: JSX.Element[] = [];
  const foundCollaborations: JSX.Element[] = [];

  if (hasCollaborations) {
    project.collaborations?.forEach(
      (collaboration: GET_PROJECT_getProject_collaborations, idx: number) => {
        if (!collaboration.isFound) {
          lookingForCollaborations.push(
            <ProjectCollabAccordion
              key={idx}
              collaboration={collaboration}
              user={user}
            />
          );
        } else {
          foundCollaborations.push(
            <ProjectCollabAccordion
              key={idx}
              collaboration={collaboration}
              user={user}
            />
          );
        }
      }
    );
  }

  return (
    <div className="content page-project-collaborators m-b-0 is-bordered-bottom">
      <h3 className="is-size-5">Collaborators</h3>
      {!hasCollaborations ? (
        <p className="p-b-1">
          This project is currently <strong>not</strong> looking for
          collaborations.
        </p>
      ) : (
        <>
          {lookingForCollaborations.length > 0 && (
            <div className="collaborators-looking m-b-2">
              <p className="m-b-1">
                This project is looking for collaborators!
              </p>
              {lookingForCollaborations}
            </div>
          )}
          {foundCollaborations.length > 0 && (
            <div className="collaborators-found">
              <p className="m-b-1">Already found:</p>
              {foundCollaborations}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Collaborators