export const projectProgressOptions = [
  {
    label: 'Select progress status',
    value: ''
  },
  {
    label: 'Ideation',
    value: 'IDEATION'
  },
  {
    label: 'Fund Raising',
    value: 'FUND_RAISING'
  },
  {
    label: 'Pre-production',
    value: 'PRE_PRODUCTION'
  },
  {
    label: 'Production',
    value: 'PRODUCTION'
  },
  {
    label: 'Post-production',
    value: 'POST_PRODUCTION'
  },
  {
    label: 'Distribution',
    value: 'DISTRIBUTION'
  },
  {
    label: 'Marketing',
    value: 'MARKETING'
  },
  {
    label: 'Completed',
    value: 'COMPLETED'
  }
];

export const projectFundingStatusOptions = [
  {
    label: 'Raising Funds',
    value: 'RAISING FUNDS'
  },
  {
    label: 'Secured',
    value: 'SECURED'
  },
  {
    label: 'Revenue Share',
    value: 'REVENUE SHARE'
  },
  {
    label: 'Out Of Pocket',
    value: 'OUT OF POCKET'
  },
  {
    label: 'No Funding',
    value: 'NO FUNDING'
  }
];

export const passionProjectOptions = [
  {
    label: 'Pending',
    value: 'PENDING'
  },
  {
    label: 'Available',
    value: 'AVAILABLE'
  },
  {
    label: 'Hidden',
    value: 'HIDDEN'
  }
];

export const commissionedProjectOptions = [
  {
    label: 'Pending',
    value: 'PENDING'
  },
  {
    label: 'Available',
    value: 'AVAILABLE'
  }
];

export const projectTypeOptions = [
  {
    label: 'Passion project',
    value: 'PASSION'
  },
  {
    label: 'Commissioned',
    value: 'COMMISSIONED'
  }
];

export const projectShareableOptions = [
  {
    label: 'Matchhat Community',
    value: 'PRIVATE'
  },
  {
    label: 'Public',
    value: 'PUBLIC'
  }
];

export const projectShareableStatus = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};

export const collaboratorCompensationTypeOptions = [
  {
    label: 'Please select...',
    value: 'UNPAID'
  },
  {
    label: 'Paid',
    value: 'PAID'
  },
  {
    label: 'Low Paid',
    value: 'LOW PAID'
  },
  {
    label: 'Basic Expenses Paid',
    value: 'BASIC EXPENSES PAID'
  },
  {
    label: 'Trade Skills',
    value: 'TRADE SKILLS'
  },
  {
    label: 'Karma Points',
    value: 'KARMA POINTS'
  }
];

export const projectGenreOptions = [
  {
    label: 'Video',
    value: 'Video'
  },
  {
    label: 'Photography',
    value: 'Photography'
  },
  {
    label: 'Multimedia',
    value: 'Multimedia'
  },
  {
    label: 'Music',
    value: 'Music'
  },
  {
    label: 'Digital',
    value: 'Digital'
  },
  {
    label: 'Dance & Theater',
    value: 'DanceAndTheater'
  },
  {
    label: 'Games',
    value: 'Games'
  },
  {
    label: 'Arts & Crafts',
    value: 'ArtsAndCrafts'
  },
  {
    label: 'Social Impact',
    value: 'SocialImpact'
  },
  {
    label: 'Design & Illustration',
    value: 'DesignAndIllustration'
  }
];
