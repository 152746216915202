import type { FC } from 'react'
import type { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import type { GET_USER_getUser } from '../../graphql/types/GET_USER';
import React, { Fragment, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CreativeListContext } from '../../contexts/creativeListContext';
import { MessageContextProvider } from '../../contexts/messageContext';
import ContentLoader from '../ContentLoader';
import Spinner from '../Spinner';
import UserCreativeCard from './UserCreativeCard';

interface IUserListProps {
  currentUser: GET_CURRENT_USER_me;
}

const UserList: FC<IUserListProps> = ({ currentUser }) => {
  const {
    creatives: users,
    isLoading,
    page,
    totalCreatives,
    onFetchMore
  } = useContext(CreativeListContext);
  const nextPage = page + 1;

  const CreativesCardLoader = (
    <Fragment>
      <div className="column">
        <ContentLoader />
      </div>
      <div className="column">
        <ContentLoader />
      </div>
      <div className="column">
        <ContentLoader />
      </div>
    </Fragment>
  );

  if (isLoading) {
    return <div className="columns">{CreativesCardLoader}</div>;
  }

  return (
    <InfiniteScroll
      className="columns page-creatives-userlist is-multiline"
      dataLength={users.length}
      hasMore={users.length < totalCreatives}
      loader={
        <div className="column is-12 page-creatives-userlist-loader">
          <Spinner />
        </div>
      }
      next={() => onFetchMore(nextPage)}
      scrollThreshold={0.8}
      endMessage={
        <p className="column has-text-centered m-b-3 is-12">
          {users.length === 0
            ? 'Nothing to show.'
            : 'No more creatives to show.'}
        </p>
      }
    >
      {users &&
        users.map((user: GET_USER_getUser, idx: number) => (
          <MessageContextProvider key={idx} currentUser={currentUser}>
            <UserCreativeCard user={user} currentUser={currentUser} />
          </MessageContextProvider>
        ))}
    </InfiniteScroll>
  );
};

export default UserList;
