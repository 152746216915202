import React, { Fragment, useEffect, useState } from 'react';
import { GET_ALL_PROJECTS_getAllProjects_projects } from '../../graphql/types/GET_ALL_PROJECTS';
import { GET_PROJECT_getProject } from '../../graphql/types/GET_PROJECT';
import {
  validVimeoUrlRegex,
  validYoutubeUrlRegex,
  vimeoUrlParse,
  youtubeUrlParse
} from '../../services/videoUrlParser';

interface IProjectProps {
  project: GET_ALL_PROJECTS_getAllProjects_projects | GET_PROJECT_getProject;
}

interface IEmbedVideo {
  id: string;
  type: VideoType;
}

enum VideoType {
  youtube,
  vimeo
}

const getVideoUrl = (embedVideo: IEmbedVideo): string => {
  if (embedVideo.type === VideoType.vimeo) {
    return `https://player.vimeo.com/video/${embedVideo.id}`;
  }

  if (embedVideo.type === VideoType.youtube) {
    /**
     * We cannot diable showing related videos on youtube,
     * but rel=0 will help to show the related videos from the same channel as the video.
     */
    return `https://www.youtube.com/embed/${embedVideo.id}?rel=0`;
  }

  return ''
};

const Video = ({ project }: IProjectProps) => {
  const [embedVideo, setEmbedVideo] = useState<IEmbedVideo | null>(null);
  const { videoUrl } = project;

  const parseUrlAndSetVideo = (): void => {
    if (videoUrl && videoUrl.match(validYoutubeUrlRegex)) {
      const youtubeVideoId = youtubeUrlParse(videoUrl);
      if (youtubeVideoId) {
        setEmbedVideo({
          id: youtubeVideoId,
          type: VideoType.youtube
        });
      }
    } else if (videoUrl && videoUrl.match(validVimeoUrlRegex)) {
      vimeoUrlParse(videoUrl).then(vimeoVideoId => {
        if (vimeoVideoId) {
          setEmbedVideo({
            id: vimeoVideoId,
            type: VideoType.vimeo
          });
        }
      });
    }
  };

  useEffect(parseUrlAndSetVideo, [project, project.videoUrl]);

  return (
    <Fragment>
      {embedVideo && embedVideo.id && (
        <div className="video-wrapper">
          <iframe
            title="title"
            id="player"
            width="100%"
            height="100%"
            src={getVideoUrl(embedVideo)}
            frameBorder="0"
          />
        </div>
      )}
    </Fragment>
  );
};

export default Video;
