import { gql } from '@apollo/client';

export const DELETE_PROJECT = gql`
  mutation DELETE_PROJECT($projectId: Float!) {
    deleteProject(projectId: $projectId) {
      __typename
      id
    }
  }
`;
