import React, { useContext } from 'react';

import { CurrentUserContext } from '../../contexts/currentUserContext';
import UserProfileForm from './Forms/UserProfileForm';

interface IUserProfileModalProps {
  modalIsOpen: boolean;
  handleModalToggle: () => void;
}

const UserProfileModal = ({
  modalIsOpen,
  handleModalToggle
}: IUserProfileModalProps) => {
  const user = useContext(CurrentUserContext);

  return (
    <div className={`modal ${modalIsOpen && 'is-active'}`}>
      <div className="modal-background" onClick={handleModalToggle} />
      <UserProfileForm user={user} onClose={handleModalToggle} />
    </div>
  );
};

export default UserProfileModal;
