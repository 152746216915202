import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { accessTokenStorage } from './auth';

// import fragmentTypes from '../fragmentTypes';

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData: fragmentTypes
// });

const httpLink = createHttpLink({
  credentials: 'same-origin',
  uri: process.env.REACT_APP_GRAPHQL_API_URL
});

const authLink = setContext(async (_, { headers }) => {
  const token = accessTokenStorage.get();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export const apolloClient = new ApolloClient({
  // cache: new InMemoryCache({ fragmentMatcher }),
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});
