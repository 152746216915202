import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { toast } from 'react-toastify';

import { GET_PROFESSIONS_BY_FILTER_QUERY } from '../../../graphql/GET_PROFESSIONS_BY_FILTER_QUERY';
import { GET_CURRENT_USER_me_desiredCollaborationProfession } from '../../../graphql/types/GET_CURRENT_USER';
import { UPDATE_USER_DESIRED_COLLABORATION_PROFESSION } from '../../../graphql/UPDATE_USER_DESIRED_COLLABORATION_PROFESSION';
import {
  IHandleOptionSelect,
  useAutocomplete
} from '../../../hooks/useAutocomplete';
import autoCompleteStyles from './autocompleteStyles';

interface IUserCollaborateWithProps {
  desiredCollaborationProfession:
    | GET_CURRENT_USER_me_desiredCollaborationProfession
    | IHandleOptionSelect;
}

const UserCollaborateWith = ({
  desiredCollaborationProfession
}: IUserCollaborateWithProps) => {
  const [updateUserDesiredCollaborationProfession, { error }] = useMutation(
    UPDATE_USER_DESIRED_COLLABORATION_PROFESSION,
    {
      onCompleted: () =>
        toast.success(
          'User desired collaboration profession successfully updated.'
        )
    }
  );

  useEffect(() => {
    if (error) {
      toast.error(error.message.replace('GraphQL error: ', ''));
    }
  }, [error]);

  const collaborateWithOptsSelectCb = async (params: IHandleOptionSelect) => {
    if (!params) {
      return;
    }

    const { __isNew__, label, value } = params;

    await updateUserDesiredCollaborationProfession({
      variables: { professionName: __isNew__ ? value : label }
    });
  };

  const { loadOptions, loading } = useAutocomplete({
    query: GET_PROFESSIONS_BY_FILTER_QUERY
  });

  return (
    <div className="field">
      <div className="field-body">
        <div className="field">
          <div className="label is-uppercase is-size-8">
            Who would you like to collaborate with? (Eg. Animator)
          </div>
          <div className="control">
            <AsyncCreatableSelect
              backspaceRemovesValue={true}
              cacheOptions={true}
              className="is-small"
              escapeClearsValue={true}
              isClearable={true}
              isLoading={loading}
              loadOptions={loadOptions}
              onChange={collaborateWithOptsSelectCb}
              placeholder={'Eg. Animator'}
              styles={autoCompleteStyles}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              value={desiredCollaborationProfession as any}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCollaborateWith;
