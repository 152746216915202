export default {
  menu: (provided: object) => ({
    ...provided,
    zIndex: 40
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#979EBE'
    };
  },
  valueContainer: defaultStyles => {
    return {
      ...defaultStyles,
      padding: '8px 8px'
    };
  }
};
