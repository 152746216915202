import { gql } from '@apollo/client';

export const UPDATE_USER_PROFILE = gql`
  mutation UPDATE_USER(
    $name: String!
    $bio: String
    $website: String
    $collaborationStatus: String
    $isOnboarded: Boolean
  ) {
    editUserProfile(
      name: $name
      bio: $bio
      website: $website
      collaborationStatus: $collaborationStatus
      isOnboarded: $isOnboarded
    ) {
      isOnboarded
      profile {
        website
        id
        name
        bio
        collaborationStatus
      }
    }
  }
`;
