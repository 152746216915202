import { ApolloProvider } from '@apollo/client';
import React, { useState } from 'react';

import confirmUserImage from '../assets/images/register/confirm-user.svg';
import figure from '../assets/images/register/figure.png';
import Navigation from '../components/Navigation';
import SignUpForm from '../components/User/Forms/SignUpForm';
import { apolloClient } from '../services/apollo';

const SignUp = () => {
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  return (
    <ApolloProvider client={apolloClient}>
      <div className="page-sign-up">
        <Navigation client={apolloClient} user={undefined} />
        <div className="page-sign-up-container">
          {signUpSuccess ? (
            <div className="columns">
              <div className="is-half page-sign-up-success">
                <img src={confirmUserImage} alt="background-confirm-user" />
                <h4 className="is-size-4 is-size-5-mobile has-text-weight-bold has-text-primar">
                  Verify your email address
                </h4>
                <p>
                  Please check your email to confirm your and activate your
                  account.
                </p>
              </div>
            </div>
          ) : (
            <div className="columns">
              <div className="column">
                <div className="page-sign-up-welcome">
                  <h2 className="page-sign-up-welcome-title is-size-3 is-size-5-mobile has-text-weight-bold has-text-primary m-b-2">
                    Welcome to MatchHat!
                  </h2>
                  <h3 className="page-sign-up-welcome-description is-size-6-mobile has-text-weight-bold">
                    Find a community of talent on MatchHat! <br />
                    Hard to build great things alone.
                  </h3>
                  <div className="page-sign-up-welcome-image-rectangle">
                    <img src={figure} alt="background-rectangle" />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="page-sign-up-form">
                  <div className="card is-rounded is-dropshadowed">
                    <div className="card-content">
                      <SignUpForm setSignUpSuccess={setSignUpSuccess} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ApolloProvider>
  );
};

export default SignUp;
