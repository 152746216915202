import type { Dispatch, SetStateAction } from 'react'
import type { IClientUser } from '../../interfaces/user';
import React, {
  Fragment,
  useContext,
  useState
} from 'react';

import { Link } from 'react-router-dom';
import { CommentCategory } from '../../components/Comment/constants';
import Navigation from '../../components/Navigation';
import { ProjectFeed } from '../../components/Project/ProjectFeed';
import UserCard from '../../components/User/UserCard';
import UserProfileModal from '../../components/User/UserProfileModal';
import {
  CurrentUserContext,
  CurrentUserContextProvider
} from '../../contexts/currentUserContext';
import {
  UserProjectsContext,
  UserProjectsContextProvider
} from '../../contexts/userProjectsContext';

const CurrentUserProjects = () => {
  const projects = useContext(UserProjectsContext);
  const user = useContext(CurrentUserContext);

  return (
    <article className="tile is-child">
      {projects && projects.length > 0 ? (
        projects.map((project, idx) => (
          <ProjectFeed
            key={idx}
            project={project}
            user={user}
            commentFrom={CommentCategory.userProjectListing}
          />
        ))
      ) : (
        <article className="tile is-child page-user-profile-next">
          <div className="card page-user-profile-next-card is-rounded is-dropshadowed">
            <header className="card-header page-user-profile-next-card-header header-padding has-text-centered is-block p-b-0">
              <h1 className="has-text-weight-bold is-size-5">
                Post your project, find collaborators!
              </h1>
              <p className="is-small m-b-1">
                Share your project with the community.
              </p>
              <Link to="/project/new">
                <button className="button is-size-regular is-primary">
                  <span className="has-text-weight-bold is-small">
                    New Project
                  </span>
                </button>
              </Link>
            </header>
            <div className="card-content page-user-profile-next-card-content is-paddingless is-rounded">
              <div className="page-user-profile-next-card-content-gradient" />
            </div>
          </div>
        </article>
      )}
    </article>
  );
};

const CurrentUserCard = ({
  setIsOpen
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const currentUser = useContext(CurrentUserContext);
  if (currentUser.id) {
    return (
      <UserCard currentUser={currentUser} setIsUserProfileModal={setIsOpen} />
    );
  }
  return null;
};

const ProjectsWrapper = () => {
  const user = useContext(CurrentUserContext);

  return (
    <UserProjectsContextProvider userId={user.id}>
      <CurrentUserProjects />
    </UserProjectsContextProvider>
  );
};

const CurrentUserProfile: React.FC<IClientUser> = (props: IClientUser) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleModalToggle = () => setIsOpen(!modalIsOpen);

  return (
    <Fragment>
      <main className="page-user-profile">
        <Navigation {...props} />
        <section className="container">
          <div className="columns section">
            <div className="column">
              <CurrentUserContextProvider>
                <CurrentUserCard setIsOpen={setIsOpen} />
                <UserProfileModal
                  handleModalToggle={handleModalToggle}
                  modalIsOpen={modalIsOpen}
                />
              </CurrentUserContextProvider>
            </div>
            <div className="column is-half">
              <div className="tile is-ancestor is-block">
                <div className="tile is-parent is-vertical is-block">
                  <CurrentUserContextProvider>
                    <ProjectsWrapper />
                  </CurrentUserContextProvider>
                </div>
              </div>
            </div>
            <div className="column page-user-profile-sidebar" />
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default CurrentUserProfile;
