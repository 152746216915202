import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import shareIcon from '../../../assets/svg/share.svg';
import ProjectShareModal from '../ProjectShareModal';
import { GET_ALL_PROJECTS_getAllProjects_projects } from '../../../graphql/types/GET_ALL_PROJECTS';
import { GET_PROJECT_getProject, GET_PROJECT_getProject_updates } from '../../../graphql/types/GET_PROJECT';

interface ProjectInfo { project: GET_ALL_PROJECTS_getAllProjects_projects | GET_PROJECT_getProject_updates | GET_PROJECT_getProject }

const ShareLink = ({ project }: ProjectInfo) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const handleModalToggle = () => {
        setIsOpen(!modalIsOpen);
    };

    return (
        <>
            <Link
              to="#"
              className="post-card-stats-item is-inline-flex has-text-grey-dark has-text-weight-semibold is-small"
              onClick={handleModalToggle}
            >
              <img src={shareIcon} alt="share icon" style={{ width: '15px' }} />
              <span>Share</span>
            </Link>
            {modalIsOpen && (
              <>
                <div className={`modal ${modalIsOpen && 'is-active'}`}>
                  <div
                    className="modal-background"
                    onClick={handleModalToggle}
                  />
                  <ProjectShareModal
                    modalIsOpen={modalIsOpen}
                    handleModalToggle={handleModalToggle}
                    project={project}
                  />
                </div>
              </>
            )}
        </>
    )
}

export default ShareLink