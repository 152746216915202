import type { ReactNode } from 'react'
import React, { createContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS_BY_FILTER } from '../graphql/GET_ALL_USERS_BY_FILTER';
import { GET_USERS_BY_FILTER_QUERY } from '../graphql/GET_USERS_BY_FILTER_QUERY';

export enum EUserFilterTypes {
  default = 'ALL_USERS',
  search = 'FILTERED_USERS_FROM_QUERY_STRING',
  peopleIamLookingFor = 'PEOPLE_I_AM_LOOKING_FOR_FILTER',
  peopleLookingForMe = 'PEOPLE_LOOKING_FOR_ME_FILTER'
}

export const PAGE_LIMIT = 12;

const initialState = {
  creatives: [],
  error: undefined as any,
  filterType: EUserFilterTypes.default,
  isLoading: false,
  onFetchMore: (_: number) => null as any,
  page: 1,
  setFilterType: (_: EUserFilterTypes) => null as any,
  totalCreatives: 0
};

export const CreativeListContext = createContext(initialState);

interface ICreativeListProviderProps {
  children: ReactNode;
}

export const CreativeListContextProvider = (
  props: ICreativeListProviderProps
) => {
  // const location = useLocation();
  // const pattern = /^\/creatives|user/i;
  // const pathname = location.pathname.match(pattern)

  const queryString = window.location.search;
  let filterTypes = EUserFilterTypes.default
  let searchStr;
  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    searchStr = urlParams.get('q')
    if (searchStr) {
      filterTypes = EUserFilterTypes.search
    }
  }
  const [filterType, setFilterType] = useState<EUserFilterTypes>(
    filterTypes
  );
  const [pageNum, setPageNum] = useState<number>(2);

  const query = searchStr ? GET_USERS_BY_FILTER_QUERY : GET_ALL_USERS_BY_FILTER;
  const queryVar: any = searchStr ? { variables: { filter: searchStr } } : {
    variables: {
      filterType,
      limit: PAGE_LIMIT,
      page: 1
    }
  }

  const { loading, error, data, fetchMore } = useQuery(query, queryVar);

  const onFetchMore = async () => {
    setPageNum(prevNum => prevNum + 1);
    return await fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getAllUsersByFilter: {
            ...cache.getAllUsersByFilter,
            users: [
              ...cache.getAllUsersByFilter.users,
              ...fetchMoreResult.getAllUsersByFilter.users
            ]
          }
        };
      },
      variables: {
        limit: PAGE_LIMIT,
        page: pageNum
      }
    });
  };

  const usersData = (data && data.getAllUsersByFilter) ? (data && data.getAllUsersByFilter.users) : (data && data.getUsersByFilter);
  return (
    <CreativeListContext.Provider
      value={{
        creatives:
          usersData || [],
        error,
        filterType,
        isLoading: loading,
        onFetchMore,
        page:
          data && data.getAllUsersByFilter ? data.getAllUsersByFilter.page : 1,
        setFilterType,
        totalCreatives:
          data && data.getAllUsersByFilter ? data.getAllUsersByFilter.count : 0
      }}
    >
      {props.children}
    </CreativeListContext.Provider>
  );
};
