import type { GET_USER_getUser } from '../graphql/types/GET_USER';
import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { GET_USER } from '../graphql/GET_USER';
import { renderContents } from './common';

const initData: GET_USER_getUser = {
  __typename: 'User',
  avatar: {
    __typename: 'Media',
    fileUrl: ''
  },
  confirmationToken: '',
  desiredCollaborationProfession: {
    __typename: 'Profession',
    label: '',
    value: ''
  },
  email: '',
  id: 0,
  isOnboarded: false,
  professions: [],
  profile: {
    __typename: 'UserProfile',
    availableAt: null,
    bio: null,
    collaborationStatus: '',
    id: '',
    name: '',
    website: null
  },
  skills: [],
  userConfirmed: false
};

export const UserContext = createContext(initData);

interface IUserContextProvider {
  children: ReactNode;
  userId: string;
}

export const UserContextProvider = (props: IUserContextProvider) => {
  const [userData, setUserData] = useState<GET_USER_getUser>(initData);
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { userId: typeof props.userId === 'string'? parseInt(props.userId, 10): props.userId }
  });

  useEffect(() => {
    if (data) {
      setUserData(data.getUser);
    }
  }, [loading]); // eslint-disable-line

  return (
    <UserContext.Provider value={userData}>
      {renderContents({
        children: props.children,
        data,
        error,
        loading
      })}
    </UserContext.Provider>
  );
};
