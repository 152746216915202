import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_ALL_NOTIFICATIONS } from '../graphql/GET_ALL_NOTIFICATIONS';

const initState = {
  isLoading: false,
  notifications: []
};

export const useNotification = () => {
  const [notificationsData, setNotificationsData] = useState<object[]>(
    initState.notifications
  );
  const { loading, error, data } = useQuery(GET_ALL_NOTIFICATIONS);

  useEffect(() => {
    if (data) {
      setNotificationsData(data.getAllNotifications);
    }
  }, [data]);

  return {
    error,
    isLoading: loading,
    notifications: notificationsData
  };
};
