import { sanitize } from 'dompurify';
import React, { FunctionComponent } from 'react';
import { parseUrl } from '../services/url';

interface IPrettyContentProps {
  content: string;
  isLineBreakEnabled: boolean;
  isCurrentUser?: string;
}

export const PrettyContent: FunctionComponent<IPrettyContentProps> = ({
  content,
  isLineBreakEnabled,
  isCurrentUser = ''
}) => {
  const lineBreakable = isLineBreakEnabled ? 'enable-line-break' : ''
  return (
    <div className={`${lineBreakable} ${isCurrentUser}`}>
      <p
        dangerouslySetInnerHTML={{
          __html: parseUrl(sanitize(content))
        }}
      />
    </div>
  );
};
