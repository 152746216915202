import { gql } from '@apollo/client';

export const UPLOAD_GENERATED_STORYBOARD_IMAGE = gql`
  mutation UPLOAD_GENERATED_STORYBOARD_IMAGE(
    $messageId: String!
    $imageNo: Float!
  ) {
    uploadGeneratedStoryboardImage(messageId: $messageId, imageNo: $imageNo) {
      id
      uploadType
      fileType
      fileUrl
    }
  }
`;
