import type { InMemoryCache } from '@apollo/client';
import type { ReactNode } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useState } from 'react';
// import useReactRouter from 'use-react-router';
import { useHistory } from 'react-router-dom';

import { CREATE_MESSAGE } from '../graphql/CREATE_MESSAGE';
import { CREATE_ROOM } from '../graphql/CREATE_ROOM';
import { GET_ROOM } from '../graphql/GET_ROOM';
import { HAS_SINGLE_CHAT_HISTORY } from '../graphql/HAS_SINGLE_CHAT_HISTORY';
import { GET_CURRENT_USER_me } from '../graphql/types/GET_CURRENT_USER';
import { GET_ROOM_getRoom } from '../graphql/types/GET_ROOM';
import { GET_USER_getUser } from '../graphql/types/GET_USER';
import { UPDATE_READ_AT_MESSAGES } from '../graphql/UPDATE_READ_AT_MESSAGES';
import {
  GET_PAGINATED_ROOMS_getPaginatedRooms_members,
  GET_PAGINATED_ROOMS as GET_PAGINATED_ROOMS_RESPONSE
} from '../graphql/types/GET_PAGINATED_ROOMS';
import { GET_PAGINATED_ROOMS } from '../graphql/GET_PAGINATED_ROOMS';
import { CREATE_ROOM_createChatRoom } from '../graphql/types/CREATE_ROOM';
import { useRoomListsContext } from './roomListsContext';
import { CREATE_MESSAGE_createMessage } from '../graphql/types/CREATE_MESSAGE';

interface IGetRoom {
  getRoom: GET_ROOM_getRoom;
}

const initContextValues = {
  actions: {
    getOtherUser: (
      _: GET_PAGINATED_ROOMS_getPaginatedRooms_members[]
    ): GET_PAGINATED_ROOMS_getPaginatedRooms_members | null => null,
    handleCreateMessage: (_: number, __: string) => Promise<void>,
    handleCreateRoom: (_: number[], __ = 'single') => Promise<void>,
    handleLoadRoom: (_: string) => Promise<void>,
    handleReadAtMessages: (_: number[], __: number) => Promise<void>,
    handleSelectOtherUser: (_: GET_USER_getUser) => Promise<void>
  },
  currentRoom: {
    called: false,
    data: {
      getRoom: {
        createdAt: null,
        id: undefined,
        members: [],
        messages: []
      }
    },
    error: null,
    loading: false
  },
  currentUser: {},
  singleChatHistory: {
    called: false,
    data: {
      hasSingleChatHistory: false
    },
    error: null,
    loading: false
  }
} as any;

export const MessageContext = createContext(initContextValues);

interface IMessageContextProviderProps {
  children: ReactNode;
  currentUser: GET_CURRENT_USER_me;
}

export const MessageContextProvider = (props: IMessageContextProviderProps) => {
  const history = useHistory();
  const [selectedOtherUser, setSelectedOtherUser] =
    useState<GET_CURRENT_USER_me | null>(null);

  const { currentPage, roomsPerPage } = useRoomListsContext();

  const [
    loadRoom,
    {
      called: roomCalled,
      error: roomError,
      data: roomData,
      loading: roomLoading
    }
  ] = useLazyQuery(GET_ROOM);
  const [
    hasSingleChatHistory,
    {
      called: singleChatHistoryCalled,
      error: singleChatHistoryError,
      data: singleChatHistoryData,
      loading: singleChatHistoryLoading
    }
  ] = useLazyQuery(HAS_SINGLE_CHAT_HISTORY, {
    onCompleted: async (d) => {
      if (!selectedOtherUser) {
        alert('Please select other user');
        return;
      }
      // If there are no room history, create a new room
      if (d.hasSingleChatHistory && !d.hasSingleChatHistory.hasRoomHistory) {
        await handleCreateRoom([Number(selectedOtherUser.id)]);
      }

      // If a room already exist, then redirect to the room URL.
      if (
        d.hasSingleChatHistory &&
        d.hasSingleChatHistory.hasRoomHistory &&
        d.hasSingleChatHistory.room
      ) {
        return redirectToRoom(d.hasSingleChatHistory.room.id);
      }
    }
  });

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [createRoom] = useMutation(CREATE_ROOM);
  const [updateReadAtMessages] = useMutation(UPDATE_READ_AT_MESSAGES);

  const handleLoadRoom = async (roomId: string) => {
    return await loadRoom({
      variables: {
        roomId: Number(roomId)
      }
    });
  };

  /**
   * Creates messages inside an existing room.
   * After creating the message, push the new message in the current Room's messages array cache.
   */
  const handleCreateMessage = async (roomId: number, content: string) => {
    await createMessage({
      update: (cache: InMemoryCache, { data: messageData }) => {
        const currentQuery = {
          query: GET_ROOM,
          variables: {
            roomId: Number(roomId)
          }
        };

        const cacheData = cache.readQuery<IGetRoom>({ ...currentQuery });
        if (!cacheData) {
          return;
        }

        // Adding new data to the current room's messages array
        const newCacheData = structuredClone(cacheData);
        newCacheData.getRoom.messages.push(messageData.createMessage);

        cache.writeQuery({
          data: newCacheData,
          ...currentQuery
        });

        /**
         * update `recentMessage` in room lists
         */
        const paginatedRoomQuery = {
          query: GET_PAGINATED_ROOMS,
          variables: {
            limit: roomsPerPage,
            page: currentPage
          }
        };
        const cachedPaginatedRoomsData =
          cache.readQuery<GET_PAGINATED_ROOMS_RESPONSE>(paginatedRoomQuery);

        if (cachedPaginatedRoomsData && messageData?.createMessage) {
          const createdMessage =
            messageData.createMessage as CREATE_MESSAGE_createMessage;
          const foundRoom = cachedPaginatedRoomsData.getPaginatedRooms.find(
            (r) => r.id === String(roomId)
          );
          if (foundRoom) {
            const updatedRooms: GET_PAGINATED_ROOMS_RESPONSE['getPaginatedRooms'] =
              [
                {
                  ...foundRoom,
                  hasUnreadMessage: false,
                  recentMessage: createdMessage.content
                },
                ...cachedPaginatedRoomsData.getPaginatedRooms.filter(
                  (r) => !!r.recentMessage && r.id !== String(roomId)
                )
              ];

            cache.writeQuery({
              data: {
                ...cacheData,
                getPaginatedRooms: updatedRooms
              },
              ...paginatedRoomQuery
            });
          }
        }
      },
      variables: {
        content,
        roomId: Number(roomId)
      }
    }).catch((e) => {
      throw new Error(e.message);
    });
  };

  /**
   * Updates each messages once it is viewed.
   * Only update readAt dates if there is no existing data.
   */
  const handleReadAtMessages = async (messageIds: number[], roomId: number) => {
    await updateReadAtMessages({
      update: (cache: InMemoryCache, { data: readAtData }) => {
        if (readAtData && readAtData.updateReadAtMessages.length === 0) {
          return;
        }

        const query = {
          query: GET_ROOM,
          variables: { roomId }
        };
        const room = cache.readQuery<IGetRoom>(query);
        if (room?.getRoom) {
          room.getRoom.hasUnreadMessage = false;

          cache.writeQuery({
            data: room,
            ...query
          });
        }
      },
      variables: {
        messageIds
      }
    }).catch((e) => {
      throw new Error(e.message);
    });
  };

  const getOtherUser = (
    members: GET_PAGINATED_ROOMS_getPaginatedRooms_members[]
  ) => {
    const otherUser = members.find((m) => m.id !== props.currentUser.id);
    return otherUser;
  };

  /**
   * When selecting user, the API searches for an existing single room types.
   * If it does not exist, it creates a new one. Otherwise, it redirects the page to the existing chatroom URL.
   */
  const handleSelectOtherUser = async (user: GET_CURRENT_USER_me) => {
    try {
      setSelectedOtherUser(user);
      await hasSingleChatHistory({
        variables: {
          otherUserId: parseInt(user.id, 10)
        }
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  /**
   *
   * After creating the room between the other user, this function redirects the page to the newly created chatroom.
   *
   * @param memberIds - other user ID
   * @param type - currently only supports 'single' rooms
   */
  const handleCreateRoom = async (memberIds: number[], type = 'single') => {
    await createRoom({
      update: (cache: InMemoryCache, { data: rData }) => {
        const paginatedRoomQuery = {
          query: GET_PAGINATED_ROOMS,
          variables: {
            limit: roomsPerPage,
            page: currentPage
          }
        };
        const cacheData =
          cache.readQuery<GET_PAGINATED_ROOMS_RESPONSE>(paginatedRoomQuery);

        // Adding new data to the current room's messages array
        if (cacheData && rData?.createChatRoom) {
          const createdRoom =
            rData.createChatRoom as CREATE_ROOM_createChatRoom;

          const updatedRooms = [
            {
              ...createdRoom,
              hasUnreadMessage: false,
              recentMessage: null
            },
            ...cacheData.getPaginatedRooms.filter((r) => !!r.recentMessage)
          ];
          cache.writeQuery({
            data: {
              ...cacheData,
              getPaginatedRooms: updatedRooms
            },
            ...paginatedRoomQuery
          });
        }
        return redirectToRoom(rData.createChatRoom.id);
      },
      variables: {
        memberIds,
        roomType: type
      }
    });
  };

  const redirectToRoom = (roomId: number) =>
    history.push(`/messages/${roomId}`);

  /**
   * Explicit declaration of the context provider value
   *
   * API Descriptions:
   *
   * actions - list of functions to manipulate the messageContextProvider states
   * currentRoom - Current chatroom information
   * currentUser - Current logged-in user information
   * singleChatHistory - Lazy literal response data of the HAS_SINGLE_CHAT_HISTORY query
   */
  const contextProviderValues = {
    actions: {
      getOtherUser,
      handleCreateMessage,
      handleCreateRoom,
      handleLoadRoom,
      handleReadAtMessages,
      handleSelectOtherUser
    },
    currentRoom: {
      called: roomCalled,
      data: roomData,
      error: roomError,
      loading: roomLoading
    },
    currentUser: props.currentUser,
    singleChatHistory: {
      called: singleChatHistoryCalled,
      data: singleChatHistoryData,
      error: singleChatHistoryError,
      loading: singleChatHistoryLoading
    }
  };

  return (
    <MessageContext.Provider value={contextProviderValues}>
      {props.children}
    </MessageContext.Provider>
  );
};
