import React, { Fragment, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ProjectListContext } from '../../contexts/projectListContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { CommentCategory } from '../Comment/constants';
import Spinner from '../Spinner';
import { ProjectFeed } from './ProjectFeed';

interface IProjectList {
  userId?: number;
  currentUserId?: string;
  user: GET_CURRENT_USER_me;
}

const ProjectList: React.FunctionComponent<IProjectList> = (
  props: IProjectList
) => {
  // console.log('props2', props)
  const { projects, totalCount, onFetchMore } = useContext(ProjectListContext);

  return (
    <div className="container">
      <InfiniteScroll
        className="is-overflow-visible"
        dataLength={projects.length}
        hasMore={projects.length < totalCount}
        loader={<Spinner />}
        next={onFetchMore}
        scrollThreshold={0.8}
        endMessage={
          <p className="notification has-text-centered">
            No more projects to show.
          </p>
        }
      >
        {projects.map((project, idx) => (
          <Fragment key={idx}>
            <ProjectFeed
              project={project}
              user={props.user}
              commentFrom={CommentCategory.projectListing}
            />
          </Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ProjectList;
