import { gql } from '@apollo/client';

export const GET_USERS_BY_FILTER_QUERY = gql`
  query GET_USERS_BY_FILTER_QUERY($filter: String!) {
    getUsersByFilter(filter: $filter) {
      id
      avatar {
        fileUrl
      }
      profile {
        id
        name
      }
      professions {
        id
        name
      }
    }
  }
`;
