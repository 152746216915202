import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import likeIcon from '../../assets/images/icons/like.png';
import { TOGGLE_COMMENT_LIKE } from '../../graphql/TOGGLE_COMMENT_LIKE';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { TOGGLE_UPDATE_COMMENT_LIKE } from '../../graphql/TOGGLE_UPDATE_COMMENT_LIKS';
import { CommentCategory } from './constants';
export interface IComment {
    commentId: string,
}

interface ILikeComment {
    commentId: string,
    commentCategory?: CommentCategory,
    likes: {
        author: {
          id: string,
          profile: {
            id: string,
            name: string,
            __typename: string
          }
        }
    }[],
    user: GET_CURRENT_USER_me;
}

const LikeComment: React.FunctionComponent<ILikeComment> = ({ commentId, commentCategory, likes, user }: ILikeComment) => {
    const [isOwner, setIsOwner] = React.useState(false)
    const [toggleCommentLike] = useMutation(TOGGLE_COMMENT_LIKE, {
        variables: {
            commentId
        },
        refetchQueries: commentCategory === CommentCategory.projectListing? ['GET_ALL_PROJECTS']: ['GET_PROJECT']
    });

    const [toggleUpdateCommentLike] = useMutation(TOGGLE_UPDATE_COMMENT_LIKE, {
        variables: {
            commentId: typeof commentId === 'string'? parseInt(commentId, 10): commentId
        },
        refetchQueries: ['GET_PROJECT']
    });

    const handleLike = async () => {
        if (commentCategory === CommentCategory.project || commentCategory === CommentCategory.projectListing) {
            await toggleCommentLike();
        } else if (commentCategory === CommentCategory.projectUpdate) {
            await toggleUpdateCommentLike()
        } else {
            toast.error(
              'There is something wrong happen when you like ...!'
            );
        }
    }

    React.useEffect(() => {
        const isExist = likes.find((like) => like.author.id === user.id)
        if (isExist) {
            setIsOwner(true)
        } else {
            setIsOwner(false)
        }
    }, [])

    return (
        <>
            <button
                onClick={handleLike}
                className="like-button"
            >
                <img src={likeIcon} alt='like icon' />
                {likes.length !== 0 && (
                    <span
                        className={isOwner? 'is-blue': 'is-light'}
                    >
                    {`${likes.length} LIKES`}
                    </span>
                )}
                {/* {(likeCount && likeCount !== 0) && (
                    <span
                        className={`is-light`}
                    >
                       {`${likeCount} LIKES`}
                    </span>
                )}  */}
            </button>
        </>
    )
}

export default LikeComment;