import React, { Fragment, useContext, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import { ProjectContext } from '../../contexts/projectContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { GET_PROJECT_getProject_updates } from '../../graphql/types/GET_PROJECT';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import CommentForm from '../Comment/CommentForm';
import CommentList from '../Comment/CommentList';
import { CommentCategory } from '../Comment/constants';
import ImageSlider from '../ImageSlider';
import LikeProject from './Action/LikeProject';
import ShareLink from './Action/ShareLink';
import ProjectUpdateModal from './ProjectUpdateModal';

interface IProjectContentProps {
  user: GET_CURRENT_USER_me;
}

interface IProjectUpdateProps {
  projectUpdate: GET_PROJECT_getProject_updates;
  index: number,
  user: GET_CURRENT_USER_me
}

const ProjectUpdate = ({ projectUpdate, index, user }: IProjectUpdateProps) => {
  const authorAvatarImgUrl =
      (projectUpdate.author &&
        projectUpdate.author.avatar &&
        projectUpdate.author.avatar.fileUrl &&
        projectUpdate.author.avatar.fileUrl) ||
      profilePlaceholder;

  const compressedImage = getCompressedImagePath(
    authorAvatarImgUrl,
    'thumbnail'
  );

  return (
    <div className='p-l-1 p-r-1 is-bordered-bottom'>
      <div className='is-flex m-t-1 m-b-1'>
        <p className='sub-title font-size-10  m-l-0 m-r-1'>Update #{index}</p>
        <p className='sub-title font-size-10 m-l-0 m-r-1'>
          <Moment format="YYYY.MM.DD">{projectUpdate.createdAt}</Moment>
        </p>
      </div>
      <div className='title'>{projectUpdate.title}</div>
      <div className="media is-bordered-bottom">
        <div className="media-left">
          <figure
            className="image avatar is-48x48"
            style={{ backgroundImage: `url(${compressedImage})` }}
          />
        </div>
        <div className="media-content">
          <p className="is-size-5 m-b-0">
            <Link
              to={`/user/${projectUpdate.author && projectUpdate.author.id}`}
              className="has-text-primary"
            >
              {projectUpdate.author &&
                projectUpdate.author.profile &&
                projectUpdate.author.profile.name}
            </Link>
          </p>
          <p>
            {projectUpdate.author &&
              projectUpdate.author.professions &&
              projectUpdate.author.professions.map((profession, idx) => (
                <span key={idx} className="is-small is-light">
                  {(idx ? ', ' : '') + profession.name}
                </span>
              ))}
          </p>
        </div>
      </div>
      <p className='description m-t-2 m-b-2'>
        {projectUpdate.description}
      </p>
      {projectUpdate.medias.length !== 0 && (
        <ImageSlider medias={projectUpdate.medias} />
      )}
      <div className='is-flex'>
        <LikeProject
          user={user}
          pageType={'projectUpdate'}
          postId={projectUpdate.id}
          likes={projectUpdate.likes? projectUpdate.likes: []}
          // wasLikedByUser={projectUpdate.wasLikedByUser}
          wasLikedByUser={
            (projectUpdate.likes &&
            Boolean(projectUpdate.likes.find(like => like.author.id === user.id)))? true: false
          }
        />
        <ShareLink project={projectUpdate} />
      </div>
      <Fragment>
        <div className="is-block">
          <CommentList comments={projectUpdate.comments} commentCategory={CommentCategory.projectUpdate} user={user}/>
        </div>
        <div className="is-block m-t-1">
          <CommentForm user={user} projectId={projectUpdate.id} commentFrom={CommentCategory.projectUpdate}/>
        </div>
      </Fragment>
    </div>
  )
}

const PostProjectUpdate = () => {
  const project = useContext(ProjectContext);
  const [updateIsOpen, setUpdateIsOpen] = useState(false)
  const handleUpdateModalToggle = () => {
    setUpdateIsOpen(!updateIsOpen);
  };

  return (
    <header className='panel-header is-bordered-bottom'>
      <h2 className='panel-header-update-title has-text-weight-semibold'>Post Update!</h2>
      <p className='panel-header-update-description'>Share updates for your project</p>
      <button
        className='panel-header-update-button'
        onClick={handleUpdateModalToggle}
      >
        Post Update
      </button>
      {updateIsOpen && (
        <>
          <div className={`modal ${updateIsOpen && 'is-active'}`}>
            <div
              className="modal-background"
              onClick={handleUpdateModalToggle}
            />
            <ProjectUpdateModal
              modalIsOpen={updateIsOpen}
              handleModalToggle={handleUpdateModalToggle}
              project={project}
            />
          </div>
        </>
      )}
    </header>
  )
}

const ProjectUpdatePanel = ({ user }: IProjectContentProps) => {
    const project = useContext(ProjectContext);
    const isProjectOwner =
    project && project.author && user && project.author.id === user.id;

    return (
      <div className='project-update'>
        {isProjectOwner && <PostProjectUpdate/>}
        { project.updates && project.updates.map((projectUpdate, index, self) => {
            return (
              <ProjectUpdate
                key={index}
                projectUpdate={projectUpdate}
                index={self.length - index}
                user={user}
              />
            )
          })
        }
      </div>
    )
}

export default ProjectUpdatePanel