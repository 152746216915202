import { useMutation } from '@apollo/client';
import axios from 'axios';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { toast } from 'react-toastify';
import { PRESIGN_LINK } from '../graphql/PRESIGN_LINK';
import { SAVE_PRESIGNED_LINK } from '../graphql/SAVE_PRESIGNED_LINK';
import { EFormTypes } from './Project/Forms/ProjectForm';

export enum EUploadTypes {
  avatar = 'avatar',
  project = 'project',
  projectUpdate = 'projectUpdate'
}

export const formatFilename = (filename: string) => {
  const currentDateTime = new Date();
  const date =
    currentDateTime.getDate() +
    '-' +
    (currentDateTime.getMonth() + 1) +
    '-' +
    currentDateTime.getFullYear();

  const randomString = Math.random()
    .toString(36)
    .substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
};

export const getShortFilename = (filename: string) => {
  const filenameArr = filename.split('/');
  const shortFilename = filenameArr[filenameArr.length - 1];
  return shortFilename;
}

export const uploadToS3 = async (file: any, signedRequest: string) => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  };
  return await axios.put(signedRequest, file, options).catch((err: Error) => {
    if (err) {
      throw new Error('PUT request error');
    }
  });
};

interface IUploadProps {
  // uploadContainer: () => void;
  uploadContainer: any;
  uploadType?: EUploadTypes;
  setFieldValue?: (params: any, value: any) => void;
  medias?: any[];
  formType: string;
  setSavedMedia?: Dispatch<SetStateAction<any>>;
}

const IUploadDefaultProps = {
  file: null,
  formType: 'new',
  medias: [],
  name: '',
  uploadType: EUploadTypes.avatar
};

const Upload: React.FunctionComponent<IUploadProps> = (props: IUploadProps) => {
  const [preSignLink] = useMutation(PRESIGN_LINK);
  const [savePreSignedLink] = useMutation(SAVE_PRESIGNED_LINK);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preSignUploadAndSaveDB = async ({
    file,
    uploadType
  }: {
    file: File;
    uploadType: EUploadTypes;
  }) => {
    try {
      const signedLink = await preSignLink({
        variables: {
          fileName: formatFilename(file.name),
          fileType: file.type,
          uploadType
        }
      });

      const { signedRequest, url } = signedLink.data.preSignLink;
      await uploadToS3(file, signedRequest);
      await setUploadFilesState(prevState =>
        prevState!.map(el =>
          el.key === `${file.name}-${file.size}`
            ? {
              ...el,
              isUploaded: true
            }
            : el
        )
      );

      const newMedia = await savePreSignedLink({
        variables: {
          fileUrl: url,
          uploadType
        }
      });

      // Attach to project form's state
      if (uploadType === 'project' || uploadType === 'projectUpdate') {
        return props.setSavedMedia!(prevState => [
          ...prevState,
          newMedia.data.savePreSignedLink
        ]);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const onDrop = useCallback(async (files: File[]) => {
    if (props.uploadType === EUploadTypes.avatar) {
      const file = files[0];
      await preSignUploadAndSaveDB({ file, uploadType: EUploadTypes.avatar });
    } else {
      return (
        files.length > 0 &&
        files.map(async (file: File, idx: number) => {
          await setUploadFilesState(prevState => [
            ...prevState!,
            {
              isUploaded: false,
              key: `${file.name}-${file.size}`,
              meta: file
            }
          ]);

          await preSignUploadAndSaveDB({
            file,
            uploadType: EUploadTypes.project
          });
        })
      );
    }
  }, []); // eslint-disable-line

  const onDropRejected = () =>
    toast.error('You can not upload files more than 5MB.', {
      autoClose: false
    });

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 5000000, // 5MB
    onDrop,
    onDropRejected
  });

  const isNewUpload = props.formType === EFormTypes.new;

  const [uploadFilesState = [], setUploadFilesState] = useState(
    isNewUpload ? [] : props.medias
  );

  const removeFileState = (upload: any) => {
    if (isNewUpload) {
      return setUploadFilesState([
        ...uploadFilesState.filter(
          (currUpload: any) => !Object.is(currUpload.meta, upload.meta)
        )
      ]);
    } else {
      setUploadFilesState((prevState: any = []) => {
        const prevUploadLength = prevState.length;
        const newUploadState = [
          ...uploadFilesState.filter(
            (currUpload: any) => !Object.is(currUpload.id, upload.id)
          )
        ];

        const uploadItemRemoved = prevUploadLength > newUploadState.length;

        if (uploadItemRemoved) {
          props.setFieldValue!('medias', newUploadState);
        }

        return newUploadState;
      });
    }
  };

  const renderUploadMeta = (file: any) => (
    <a className="list-item columns is-vcentered is-small m-l-0 m-r-0">
      <div className="column is-paddingless">
        <img
          style={{ height: 'auto', width: 40 }}
          key={`${file.meta.size}-${file.meta.name}`}
          src={URL.createObjectURL(file.meta)}
          alt={file.meta.name}
        />
      </div>
      <div className="column is-two-thirds is-paddingless">
        {getShortFilename(file.meta.name)}
      </div>
      <div className="column has-text-centered is-paddingless">
        <span
          className="has-text-weight-semibold has-text-danger"
          onClick={() => removeFileState(file)}
        >
          Delete
        </span>
      </div>
    </a>
  );

  const renderNewUpload = () => {
    console.log({ uploadFilesState })
    return uploadFilesState.map((file, idx: number) => (
      <React.Fragment key={idx}>
        {file.isUploaded ? (
          renderUploadMeta(file)
        ) : (
          <a className="list-item columns is-vcentered is-small m-l-0 m-r-0">
            <div className="column v">
              <progress className="progress is-large is-info" max="100" />
            </div>
            <div className="column is-paddingless">{file.meta.name}</div>
          </a>
        )}
      </React.Fragment>
    ));
  };

  const renderUpdateUpload = () => {
    console.log(uploadFilesState);
    return uploadFilesState.map((file, idx: number) => (
      <React.Fragment key={idx}>
        {file.meta ? (
          renderUploadMeta(file)
        ) : (
          <a className="list-item columns is-vcentered is-small m-l-0 m-r-0">
            <div className="column is-paddingless">
              <img
                style={{ height: 'auto', width: 40 }}
                src={file.fileUrl}
                alt={file.fileUrl}
              />
            </div>
            <div className="column is-two-thirds is-paddingless">
              {getShortFilename(file.fileUrl)}
            </div>
            <div className="column has-text-centered is-paddingless">
              <span
                className="has-text-weight-semibold has-text-danger"
                onClick={() => removeFileState(file)}
              >
                Delete
              </span>
            </div>
          </a>
        )}
      </React.Fragment>
    ));
  }

  const UploadedTable = () => {
    return (
      <div className="list is-hoverable">
        {isNewUpload ? renderNewUpload() : renderUpdateUpload()}
      </div>
    );
  };

  return (
    <React.Fragment>
      <section className="m-b-1">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {props.uploadContainer()}
        </div>
      </section>
      {
        (props.uploadType === EUploadTypes.project || props.uploadType === EUploadTypes.projectUpdate) && <UploadedTable />}
    </React.Fragment>
  );
};

Upload.defaultProps = IUploadDefaultProps;

export default Upload;
