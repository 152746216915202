import { ApolloProvider } from '@apollo/client';
import React from 'react';

import figure from '../assets/images/register/figure.png';
import Navigation from '../components/Navigation';
import { apolloClient } from '../services/apollo';

const NotFound = () => (
  <ApolloProvider client={apolloClient}>
    <div className="page-not-found">
      <Navigation client={apolloClient} user={undefined} />
      <div className="page-not-found-content content">
        <h1 className="page-not-found-content-title">
          Sorry, We cannot find what you're looking for.
        </h1>
        <div className="page-not-found-content-image">
          <img src={figure} alt="background-rectangle" />
        </div>
      </div>
    </div>
  </ApolloProvider>
);
export default NotFound;
