import React, { createContext, useContext, useState } from 'react';
import { GET_PAGINATED_ROOMS as PaginatedRooms } from '../graphql/types/GET_PAGINATED_ROOMS';
import { useQuery } from '@apollo/client';
import { GET_PAGINATED_ROOMS } from '../graphql/GET_PAGINATED_ROOMS';

export type Room = PaginatedRooms['getPaginatedRooms'][number];

interface RoomListsContextValues {
  rooms: Room[];
  error: unknown;
  loading: boolean;
  handleFetchNextRoomPage: () => Promise<void>;
  hasMoreRoomsToFetch: boolean;
  isFetchingMoreRooms: boolean;
  roomsPerPage: number;
  currentPage: number;
}

const RoomListsContext = createContext<RoomListsContextValues | null>(null);

const INITIAL_PAGE = 1;
const ROOMS_LIMIT = 10;

/**
 * useContext hook for the getting paginated room lists
 * Make sure to wrap the components with `RoomListsContextProvider` before using this hook
 */
export const useRoomListsContext = () => {
  const currentRoomListsContext = useContext(RoomListsContext);
  if (!currentRoomListsContext) {
    throw new Error(
      'useRoomListsContext has to be used within <RoomListsContextProvider>'
    );
  }
  return currentRoomListsContext;
};

const RoomListsContextProvider = (props: { children: React.ReactNode }) => {
  const [currentRoomsPage, setCurrentRoomsPage] = useState(INITIAL_PAGE);
  const [hasMoreRoomsToFetch, setHasMoreRoomsToFetch] = useState(true);
  const { loading, error, data, fetchMore } = useQuery(GET_PAGINATED_ROOMS, {
    variables: {
      limit: ROOMS_LIMIT,
      page: INITIAL_PAGE
    },
    onCompleted: (data) => {
      const roomsData = data?.getPaginatedRooms as Room[] | undefined;
      const totalRoomsLength = roomsData?.length ?? 0;
      if (!totalRoomsLength || totalRoomsLength < ROOMS_LIMIT) {
        setHasMoreRoomsToFetch(false);
      }
    },
    onError: () => setHasMoreRoomsToFetch(false)
  });

  const handleFetchNextRoomPage = async () => {
    if (!hasMoreRoomsToFetch) return;
    const nextPage = currentRoomsPage + 1;

    await fetchMore({
      updateQuery: (prevCache, { fetchMoreResult }) => {
        const newRoomsData = fetchMoreResult?.getPaginatedRooms as
          | Room[]
          | undefined;
        if (!newRoomsData || newRoomsData.length < ROOMS_LIMIT) {
          setHasMoreRoomsToFetch(false);
        }
        if (!newRoomsData?.length) {
          return prevCache;
        }
        return {
          ...prevCache,
          getPaginatedRooms: [...prevCache.getPaginatedRooms, ...newRoomsData]
        };
      },
      variables: {
        limit: ROOMS_LIMIT,
        page: nextPage
      }
    });
    setCurrentRoomsPage(nextPage);
  };

  const values: RoomListsContextValues = {
    rooms: (data?.getPaginatedRooms ?? []) as Room[],
    error,
    loading,
    handleFetchNextRoomPage,
    hasMoreRoomsToFetch,
    isFetchingMoreRooms: loading,
    currentPage: currentRoomsPage,
    roomsPerPage: ROOMS_LIMIT
  };

  return (
    <RoomListsContext.Provider value={values}>
      {props.children}
    </RoomListsContext.Provider>
  );
};

export default RoomListsContextProvider;
