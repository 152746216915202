import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface IShowMoreProps {
  toggle: (e) => void;
  isShowMore: boolean;
}

const ShowMore: FC<IShowMoreProps> = (props: IShowMoreProps) => (
  <Link to="" onClick={props.toggle}>
    <span
      className={`content is-small has-text-link ${props.isShowMore &&
        'is-inline-block'}`}
    >
      {props.isShowMore ? 'Show less' : 'Show more'}
    </span>
  </Link>
);

export default ShowMore;
