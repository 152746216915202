import { gql } from '@apollo/client';

export const GET_ALL_PROJECTS_BY_USER = gql`
  query GET_ALL_PROJECTS_BY_USER($userId: Float) {
    getAllProjectsByUser(userId: $userId) {
      __typename
      author {
        id
        email

        avatar {
          fileUrl
        }

        professions {
          id
          name
        }

        profile {
          id
          name
          bio
          website
        }
      }

      id
      type
      title
      locations {
        id
        name
      }
      visibility
      description
      fundingStatus

      medias {
        id
        fileUrl
      }

      projectStatus
      genres {
        id
        name
      }
      progress

      likes {
        id
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }
        }
      }

      interested {
        id
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }
        }
      }

      wasLikedByUser

      collaborations {
        id
        isFound
        requiredSkills {
          id
          name
        }

        requiredProfessions {
          id
          name
        }

        numberOfPeople

        applicants {
          id
        }

        selectedApplicants {
          id
        }
      }

      comments {
        id
        contentJson
        createdAt
        # likeCount
        version
        wasCommentLikedByUser
        author {
          id
          email

          avatar {
            fileUrl
          }

          profile {
            id
            name
          }
        }
        likes {
          id
          author {
            id
            email
  
            avatar {
              fileUrl
            }
  
            profile {
              id
              name
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
