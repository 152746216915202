import type { AxiosResponse } from 'axios';
import axios from 'axios';

export const validVideoUrlRegex = /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/; // eslint-disable-line
export const validYoutubeUrlRegex = /^(http:\/\/|https:\/\/)(youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/ // eslint-disable-line
export const validVimeoUrlRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w\/]+)([\?].*)?$/ // eslint-disable-line

export const youtubeUrlParse = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const vimeoUrlParse = async (url: string) => {
  const vimeoApiParserUrl = `https://vimeo.com/api/oembed.json?url=${url}`;

  try {
    const videoProp: AxiosResponse = await axios.get(vimeoApiParserUrl);

    if (videoProp !== null && videoProp.data && videoProp.data.uri) {
      const urlComponent = videoProp.data.uri.split('/videos/')
      return urlComponent[1];
    }

    return false;
  } catch {
    return false;
  }
};
