import React, { FC, ReactNode, useState } from 'react';

interface ITabContent {
  title: string,
  contentCount: number,
  body: ReactNode
}

interface ITabProps {
  contents: ITabContent[];
}

const Tab: FC<ITabProps> = (props: ITabProps) => {
  const [activeTab, setActiveTab] = useState(
    props.contents.length > 0 ? props.contents[0].title : null
  );

  return (
    <div className="tabs is-boxed">
      <ul style={{ margin: 0 }}>
        {props.contents.map((content: ITabContent) => (
          <li
            className={`tabs-body-item ${activeTab === content.title &&
              'is-active'}`}
            key={content.title}
            onClick={() => setActiveTab(content.title)}
          >
            <a className=''>
              <span className="m-r-1">
                {content.title}
              </span>
              <span className="">
                {content.contentCount}
              </span>
            </a>
          </li>
        ))}
      </ul>
      <div className="tabs-body">
        {props.contents.map((content: ITabContent) => (
          <div
            className={`tabs-body-item p-t-1 p-r-1 p-b-1 p-l-1 ${activeTab ===
              content.title && 'is-active'}`}
            key={content.title}
          >
            {/* <content.body /> */}
            {content.body}
          </div>
        ))}
      </div>
    </div>
  );
};

Tab.defaultProps = {
  contents: []
};

export default Tab;
