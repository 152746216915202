import type { RefObject } from 'react';
import { useEffect } from 'react';

/**
 * Utility hook to auto scroll at the very bottom of the attached reference dom
 *
 * @param ref - Reference to dom
 */
export const useScrollToBottom = (ref: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  });

  return null;
};
