import { gql } from '@apollo/client';

export const SAVE_USER_AVATAR = gql`
  mutation SAVE_USER_AVATAR($url: String!) {
    saveUserAvatar(url: $url) {
      id
      email
      avatar {
        fileUrl
      }
      profile {
        id
        name
        bio
        website
      }

      skills {
        id
        name
      }

      professions {
        id
        name
      }
    }
  }
`;
