import { gql } from '@apollo/client';

export const GET_PROJECTS_BY_FILTER = gql`
  query GET_PROJECTS_BY_FILTER($filter: String!) {
    getProjectsByFilter(filter: $filter) {
      __typename
      id
      title
      medias {
        fileUrl
      }
    }
  }
`;
