import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { apolloClient } from '../services/apollo';

const TermsOfUsePage = () => (
  <ApolloProvider client={apolloClient}>
    <div className="page-terms-of-use">
      <Navigation client={apolloClient} user={undefined} />
      <div className="page-terms-of-use-container page-container">
        <div className="container p-t-3 p-b-3">
          <div className="columns m-t-0 m-r-0 m-b-0 m-l-0">
            <div className="column">
              <h1 className="is-size-3 is-bordered-bottom">
                Terms and Conditions
              </h1>

              <div className="block">
                <p>
                  Welcome to MatchHat, the playground for creative
                  collaboration! Our platform provides our community of creators
                  with tools to discover new creators, collaborate with each
                  other, and bring projects to life.
                </p>
              </div>
              <div className="block">
                <p>
                  We want to provide MatchHat users with a safe and enjoyable
                  experience. To ensure this happens in a secure and lawful way,
                  we have set out our{' '}
                  <strong>
                    Terms of Use below, which you must agree to before using
                    MatchHat
                  </strong>
                  .
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">Terminology:</h3>
                <p>
                  This page explains the terms by which you may access and use
                  the MatchHat social platform. These Services include the
                  MatchHat website,, services, and/or other software
                  (“Services”) provided by MatchHat. (“<strong>MatchHat</strong>
                  ,” “<strong>we</strong>
                  ,” or “<strong>us</strong>”). These Terms apply to all
                  services that may be in existence now or may exist in the
                  future. This TERMS OF USE Agreement (“
                  <strong>Agreement</strong>”) applies to any “user” of
                  MatchHat, which means all visitors, registered users, and
                  others who access the Service (“
                  <strong>Users</strong>”).
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">Contract:</h3>
                <p>
                  Please take some time to carefully read the following Terms of
                  Use Agreement to ensure that you understand each provision. BY
                  ACCESSING OR USING THE SERVICE, YOU SIGNIFY THAT YOU HAVE
                  READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF
                  YOU ARE NOT ELIGIBLE TO USE THE SERVICE, OR DO NOT AGREE TO
                  THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU DO NOT HAVE
                  OUR PERMISSION TO USE THE SERVICE.
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">Community Guidelines</h3>
                <p>
                  You must also adhere to our{' '}
                  <Link to="/community-guidelines">Community Guidelines</Link>{' '}
                  which, and are incorporated into these terms. We recommend
                  that you read through them to understand and agree on the
                  expected behaviour of our Users. MatchHat will take necessary
                  action if you do not follow these rules. We reserve the right
                  to delete your account.
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">Privacy Policy and Copyrights:</h3>
                <p>
                  You acknowledge and agree that, as provided in greater detail
                  in this Agreement:
                </p>
                <div className="content">
                  <ul>
                    <li>
                      BY ACCESSING OR USING THE SERVICE, YOU AGREE TO THE
                      COLLECTION, USE AND SHARING OF YOUR INFORMATION AS SET
                      FORTH IN THE{' '}
                      <Link to="/privacy-policy">MATCHHAT PRIVACY POLICY</Link>
                      (“
                      <strong>PRIVACY POLICY</strong>”), WHICH IS INCORPORATED
                      TO THESE TERMS BY REFERENCE HEREIN.
                    </li>
                    <li>
                      <strong>
                        YOU ARE AND SHALL REMAIN THE OWNER OF THE USER CONTENT)
                        YOU CREATE, PROVIDE OR OTHERWISE MAKE AVAILABLE THROUGH
                        THE SERVICES. MATCHHAT CLAIMS NO OWNERSHIP RIGHTS TO
                        YOUR USER CONTENT; HOWEVER, IN THIS AGREEMENT YOU GRANT
                        MATCHHAT A LIMITED LICENSE TO YOUR USER CONTENT TO: (i)
                        PROVIDE THE SERVICE; AND (ii) TO USE YOUR USER CONTENT
                        FOR THE LIMITED USES IDENTIFIED IN OUR PRIVACY POLICY.
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="block">
                <h3 className="is-size-4">Responsibility:</h3>
                <p>
                  BY ACCEPTING THE TERMS AND CONDITIONS OF THIS AGREEMENT,{' '}
                  <strong>
                    YOU ARE AGREEING TO A CONCILIATION PROCEDURE PRIOR TO ANY
                    LITIGATION. You also hereby WAIVE YOUR RIGHT TO ANY CLASS
                    ACTION/JURY TRIAL (see RESPONSIBILITY)
                  </strong>
                  .
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">Change:</h3>
                <p>
                  As we grow our platform, we may update our Terms from time to
                  time. We will make you aware of any significant changes by
                  sending a notice to the email address connected to your
                  MatchHat user account, or by placing a notice on a prominent
                  location on our Services. We will issue a notice via the
                  previous methods to all Users, to signify an upcoming change
                  to our Terms. If you do not agree with the upcoming change(s),
                  please use the tools provided to delete your account.
                </p>
              </div>

              <div className="block content">
                <ol type="I">
                  <li className="m-b-1">
                    <strong>
                      REGISTRATION and ACCESS TO MATCHHAT SERVICES:
                    </strong>
                    <ol type="1">
                      <li>ELIGIBILITY</li>
                      <li>CONTENT</li>
                      <li>MATCHHAT ACCOUNTS</li>
                      <li>SERVICE RULES</li>
                      <li>MATCHHAT APP</li>
                    </ol>
                  </li>
                  <li className="m-b-1">
                    <strong>USE OF MATCHHAT SERVICES:</strong>
                    <ol type="1">
                      <li>COLLABORATION ON MATCHHAT</li>
                      <li>MATCHHAT PROPRIETARY RIGHTS</li>
                      <li>BETA SERVICES</li>
                      <li>NO PROFESSIONAL ADVICE</li>
                      <li>PRIVACY</li>
                      <li>CHILDREN AND OUR SERVICES</li>
                      <li>SECURITY</li>
                      <li>USER’S CONTENT COPYRIGHTS</li>
                    </ol>
                  </li>
                  <li>
                    <strong>RESPONSIBILITIES</strong>
                    <ol type="1">
                      <li>THIRD-PARTY LINKS AND INFORMATION</li>
                      <li>CREDITS</li>
                      <li>INDEMNITY</li>
                      <li>NO WARRANTY</li>
                      <li>LIMITATION OF LIABILITY</li>
                      <li>MISCELLANEOUS</li>
                    </ol>
                  </li>
                </ol>
              </div>

              <div className="block">
                <h3 className="is-size-4">
                  I. REGISTRATION AND ACCESS TO MATCHHAT SERVICES:
                </h3>
                <p>
                  MatchHat is designed to provide a lively and interactive
                  community for its Users to share creative Content (defined
                  below) with each other. In order that it is done safely and
                  lawfully, this Agreement between you and MatchHat establishes
                  some rules set out below.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">1. ELIGIBILITY</h4>
                <p>
                  This is a CONTRACT between you and MatchHat.{' '}
                  <strong>
                    YOU MUST READ AND AGREE TO THESE TERMS BEFORE USING THE
                    MATCHHAT SERVICES
                  </strong>
                  .
                </p>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">1.1. Binding contract:</h5>
                <p>
                  Through ACCESSING MATCHHAT (visiting, using, whether or not
                  you create a registered account), you are UNDERSTANDING AND
                  AGREEING TO BE BOUND by this AGREEMENT. This therefore means
                  that if you do not agree to these Terms, you should not use or
                  visit MatchHat.
                </p>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">1.2. Age restriction:</h5>
                <p>
                  You must be a minimum thirteen years old (13) to access or use
                  the Services. Any use or access to the Services by anyone
                  under thirteen (13) is prohibited and in violation of this
                  Agreement. In addition, the Service is not available to any
                  Users previously removed from the Service by MatchHat. You
                  must also be old enough to consent to the processing of your
                  personal data in your own country. Please refer to the Section
                  below to learn more about the minimum age for consent to data
                  processing CHILDREN AND OUR SERVICES.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">2. CONTENT</h4>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">2.1. Content:</h5>
                <p>
                  MatchHat allows Users to post, provide or submit content such
                  as profile information, videos, images, text, music, sound
                  recording, animations, logos, comments, questions, projects,
                  data and other content or information and any such materials a
                  User submits, posts, displays, shares or otherwise makes
                  available on the Service is referred to as “User Content”,
                  “Content” or “content”.
                </p>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">2.2. License:</h5>
                <div className="content">
                  <ul>
                    <li>
                      <strong>The content you provide remains yours.</strong>
                    </li>
                  </ul>
                  <p>
                    We claim no ownership right over User Content created by
                    you. The User Content you create, post, or otherwise make
                    available through the Service remains yours. As between you
                    and MatchHat, you retain any and all right, title and
                    interest in and to the User Content you create, post, or
                    otherwise make available through the Service. MatchHat does
                    not claim any ownership rights over your any content that is
                    posted, displayed or made available on our site, Services
                    and application.
                  </p>
                  <ul>
                    <li>
                      <strong>
                        But you agree to grant a license to MatchHat:
                      </strong>
                    </li>
                  </ul>
                  <p>
                    This license is being used by MatchHat as necessary to
                    provide the Services, and for such other limited uses
                    identified in our{' '}
                    <Link to="/privacy-policy">Privacy Policy</Link>. To promote
                    MatchHat and to celebrate the collaborations on the
                    platform, we may post your content on MatchHat and may also
                    include it on other media services, including any social
                    media, newsletters, television programming, print
                    publications (such as magazines for instance), or
                    advertising. It will therefore become accessible and be
                    linked to or copied from across the public Internet as well
                    as in other media.
                  </p>
                  <p>
                    Please do NOT SUBMIT OR DISPLAY ANYTHING ON MATCHHAT THAT
                    YOU WOULD NOT LIKE TO BE USED IN THIS WAY and note that we
                    MAY NOT BE ABLE TO PREVENT FURTHER USE BY THIRD PARTIES
                    WITHOUT YOUR PERMISSION.
                  </p>
                  <p>
                    This license is not being used by MatchHat to sell your User
                    Content to third parties or otherwise profit off of your
                    User Content in any way unrelated to MatchHat providing and
                    promoting the Services. It also does not give MatchHat any
                    rights to own your User Content or limit your ability to use
                    your User Content however you wish outside of the Services.
                  </p>
                  <p>
                    You will remain responsible for the accuracy, copyright
                    compliance, legality, decency, or any other aspect of your
                    Content.
                  </p>
                  <p>
                    You also warrant that ANY 'MORAL RIGHTS' IN POSTED MATERIALS
                    HAVE BEEN WAIVED.
                  </p>
                  <ul>
                    <li>
                      <strong>
                        And you also agree to grant a license other users:
                      </strong>
                    </li>
                  </ul>
                  <p className="block">
                    By providing or sharing User Content through the Service,
                    you hereby agree to grant each User of MatchHat a
                    NON-EXCLUSIVE ROYALTY FREE LICENSE, TO ACCESS YOUR USER
                    CONTENT THROUGH THE SERVICES, AND TO USE, DISPLAY, MODIFY,
                    REPRODUCE AND PERFORM SUCH USER CONTENT, as permitted
                    through the functionality of the Service and under this
                    Agreement. Thus, you agree to ALLOW OTHERS TO VIEW, DISPLAY,
                    MODIFY AND/OR SAVE YOUR USER CONTENT IN ACCORDANCE WITH YOUR
                    SETTINGS AND THIS AGREEMENT.
                  </p>
                  <p className="block">
                    Where you collaborate on a Project, you acknowledge and
                    agree that any copyright works created by you and
                    incorporated into the Project shall be licensed by the
                    Project Owner and any other Collaborators, for use on the
                    MatchHat Platform, on the same terms as set forth above.
                  </p>
                  <p className="block">
                    Any copyright works created by you and incorporated into a
                    Project shall not be licensed for use outside of the
                    MatchHat platform. The Project Owner shall be required to
                    seek the permission and consent of the copyright owner to
                    display, use, modify, or make any other use of any
                    Collaborator’s copyright works incorporated in their Project
                    on terms agreed between the Project Owner and the
                    Collaborator.
                  </p>
                  <p className="block">
                    You reserve all the rights to your User Content not
                    expressly granted in this Agreement and in our Privacy
                    Policy. MatchHat does not claim any ownership or license to
                    the User Content, except as mentioned in this Agreement.
                  </p>
                  <p className="block">
                    Please be aware that it is very difficult to remove content
                    from the Internet that has become publicly available.
                  </p>
                  <p className="block">
                    If you have any questions about this Section 2.2., please
                    contact us at{' '}
                    <a href="mailto:contact@matchhat.com">
                      contact@matchhat.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">2.3. MatchHat license:</h5>
                <p>
                  MatchHat reserves and owns all rights to its name, logo and
                  website, App, including any Intellectual Property rights
                  related to them. You may not reuse, distribute, copy, or
                  duplicate any part of MatchHat.
                </p>
              </div>

              <div className="block">
                <strong>Intellectual Property:</strong>
                <p>
                  For the purposes of this Agreement, “Intellectual Property
                  Rights” means all patent rights, copyright rights, mask work
                  rights, moral rights, rights of publicity, trademark, trade
                  dress and service mark rights, goodwill, trade secret rights
                  and other intellectual property rights as may now exist or
                  hereafter come into existence, and all applications therefore
                  and registrations, renewals and extensions thereof, under the
                  laws of any state, country, territory or other jurisdiction.
                </p>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">
                  2.4. Representations and Warranties:
                </h5>

                <div className="content">
                  <ul>
                    <li>
                      <strong>
                        You are responsible for the rights attached to your
                        Content
                      </strong>
                    </li>
                  </ul>
                </div>

                <p>
                  As a User, you agree that you are responsible for all Content
                  that you submit on MatchHat. You must assume all risks
                  associated with it, including accuracy, intellectual property
                  claims, or any other legal rights claims. You represent and
                  warrant that:
                </p>

                <div className="content">
                  <ol>
                    <li>
                      you either OWN the Content you post, or that you HAVE ALL
                      THE NECESSARY RIGHTS TO POST that Content on MatchHat,
                    </li>
                    <li>
                      your Content does NOT INFRINGE any third party’s rights,
                      and
                    </li>
                    <li>
                      you CAN GRANT THE LICENSES you grant in these Terms.
                    </li>
                  </ol>

                  <ul>
                    <li>
                      <strong>
                        In connection with your Content, you affirm, represent
                        and warrant that:
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="block">
                <strong>You have the relevant third parties’ consent</strong>
                <p>
                  You have obtained and are solely responsible for{' '}
                  <strong>obtaining all consents</strong>, as may be required by
                  law, to post any User Content relating to third parties,
                  including (without limitation) the written consent and release
                  of liability of each and every identifiable natural person in
                  the User Content, if any, to use such person’s name or
                  likeness in the manner contemplated by the Service and this
                  Agreement. You must have explained and received express
                  specific consent of everyone who worked on or features in your
                  Content, and if they are under eighteen (18) to their parents
                  or guardians, how it may be used, especially for photographs,
                  videos or music.
                </p>
              </div>

              <div className="block">
                <strong>You respect the laws and third parties’ rights</strong>
                <p>
                  Your User Content and MatchHat’s use thereof as contemplated
                  by this Agreement and the Service will{' '}
                  <strong>
                    not violate any law or infringe any rights of any third
                    party
                  </strong>
                  , including but not limited to any Intellectual Property
                  Rights and privacy rights.
                </p>
              </div>

              <div className="block">
                <strong>Truth and Accuracy:</strong>
                <p>
                  To the best of your knowledge, all your User Content and other
                  information that you provide to us is{' '}
                  <strong>truthful and accurate</strong>.
                </p>
              </div>

              <div className="block">
                <strong>No payment liability to MatchHat:</strong>
                <p>
                  MatchHat may exercise the rights to your User Content granted
                  under this Agreement{' '}
                  <strong>
                    without liability for payment of any union fees, residuals,
                    payments, fees, or royalties payable
                  </strong>{' '}
                  under any collective bargaining agreement or otherwise.
                </p>
              </div>

              <div className="block">
                <strong>
                  You may still need to register your copyrights with the
                  appropriate authorities:
                </strong>
                <p>
                  You understand that publishing your User Content on the
                  Service is not a substitute for registering it with the
                  appropriate authority or union in charge of copyrights
                  registration or any other rights organisation.
                </p>
              </div>

              <div className="block">
                <strong>
                  You understand and agree not to post, display or make
                  available any of the following Content on MatchHat that:
                </strong>
                <div className="content">
                  <ul>
                    <li>
                      may create a risk of harm, loss, physical or mental
                      injury, emotional distress, death, disability,
                      disfigurement, or physical or mental illness to you, to
                      any other person, or to any animal;
                    </li>
                    <li>
                      may create a risk of any other loss or damage to any
                      person or property;
                    </li>
                    <li>
                      seeks to harm or exploit children by exposing them to
                      inappropriate content, asking for personally identifiable
                      details or otherwise;
                    </li>
                    <li>may constitute or contribute to a crime or tort;</li>
                    <li>
                      contains any information or content that we deem to be
                      unlawful, harmful, abusive, racially or ethnically
                      offensive, defamatory, infringing, invasive of personal
                      privacy or publicity rights, harassing, humiliating to
                      other people (publicly or otherwise), libelous,
                      threatening, profane, obscene, or otherwise objectionable;
                    </li>
                    <li>
                      contains any information or content that is illegal
                      (including, without limitation, the disclosure of insider
                      information under securities law or of another party’s
                      trade secrets);
                    </li>
                    <li>
                      contains any information or content that you do not have a
                      right to make available under any law or under contractual
                      or fiduciary relationships;
                    </li>
                    <li>
                      contains any information or content that you know is not
                      correct and current or;
                    </li>
                    <li>
                      violates any school or other applicable policy, including
                      those related to cheating or ethics.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="block">
                <p>
                  MatchHat reserves the right, but is not obligated,{' '}
                  <strong>to reject and/or remove any User Content</strong> that
                  MatchHat believes, in its sole discretion, in violation any of
                  these provisions. You agree to immediately remove any Content
                  from your Service on receiving notice requesting you to do so
                  from us or from any third party (including any court or law
                  enforcement agency) and you must notify us immediately of any
                  such third party request.
                </p>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">
                  MatchHat disclaims any responsibility regarding User Content:
                </h5>

                <p>
                  You are responsible for your use of the Services and for any
                  Content you provide, including compliance with applicable
                  laws, rules, and regulations. You should only provide Content
                  that you are comfortable sharing with others.
                </p>
              </div>

              <div className="block">
                <p>
                  Although we take reasonable care with the Content displayed on
                  MatchHat. MATCHHAT MAY NOT MONITOR OR CONTROL THE CONTENT
                  POSTED VIA THE SERVICES AND CANNOT TAKE RESPONSIBILITY FOR
                  SUCH CONTENT. Unlike traditional physical media, the Content
                  is provided in a dynamic environment with regular changes and
                  therefore inaccuracies may occur from time to time. Please
                  double-check the competences, accuracy and reliability of the
                  Content if you are in doubt. Also please use common sense and
                  caution when using third party material. ANY USE OR RELIANCE
                  ON ANY CONTENT OR MATERIALS POSTED VIA THE SERVICE OR OBTAINED
                  BY YOU THROUGH THE SERVICE IS AT YOUR OWN RISK. WE DO NOT
                  ENDORSE, SUPPORT, REPRESENT OR GUARANTEE THE COMPLETENESS,
                  TRUTHFULNESS, ACCURACY, OR RELIABILITY OF ANY CONTENT OR
                  COMMUNICATIONS POSTED VIA THE SERVICES OR ENDORSE ANY OPINIONS
                  EXPRESSED VIA THE SERVICE.
                </p>
              </div>

              <div className="block">
                <p>
                  You understand that by using the Services, you may be exposed
                  to CONTENT THAT IS OFFENSIVE, HARMFUL, INACCURATE OR OTHERWISE
                  INAPPROPRIATE FOR CHILDREN AND/OR ANYONE UNDER 18, OR IN SOME
                  CASES, POSTS THAT HAVE BEEN MISLABELED OR ARE OTHERWISE
                  DECEPTIVE OR UNSUITED FOR YOUR PURPOSE. Every Content is the
                  sole responsibility of the person who originated such Content
                  and you agree that MATCHHAT SHALL NOT BE LIABLE FOR ANY
                  DAMAGES YOU ALLEGE TO INCUR AS A RESULT OF OR RELATING TO ANY
                  USER CONTENT.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>
                    MATCHHAT IS NOT RESPONSIBLE AND CANNOT BE HELD LIABLE FOR
                    ANY USER CONTENT THAT YOU OR ANY OTHER USERS OR THIRD-PARTY
                    POST, SEND, OR OTHERWISE MAKE AVAILABLE OVER THE SERVICE.
                    YOU SHALL BE SOLELY RESPONSIBLE FOR YOUR USER CONTENT AND
                    THE CONSEQUENCES OF POSTING IT, PUBLISHING IT, SHARING IT,
                    OR OTHERWISE MAKING IT AVAILABLE ON THE SERVICE
                  </strong>
                  , AND YOU AGREE THAT WE ARE ONLY ACTING AS A PASSIVE CONDUIT
                  FOR THE ONLINE DISTRIBUTION AND PUBLICATION OF YOUR USER
                  CONTENT.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">3. MATCHHAT ACCOUNTS</h4>
              </div>

              <div className="block">
                <h5 className="has-text-weight-bold">
                  Creation of your account:
                </h5>
                Your MatchHat account gives you access to the services and
                functionalities that we may establish and maintain from time to
                time and at our sole discretion.
              </div>

              <div className="block">
                <p>
                  <strong>i. Account on behalf of a company:</strong>
                </p>
                <p>
                  If you open a MatchHat account on behalf of a company,
                  organisation, or other entity, then (i) “you” includes you and
                  that entity, and (ii) you represent and warrant that you are
                  an authorised representative of the entity with the authority
                  to bind the entity to this Agreement, and that you agree to
                  this Agreement on the entity’s behalf.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>
                    ii. Responsibility of the control and activity of your
                    account:
                  </strong>
                </p>
                <p>
                  You may never use another User’s account without permission.
                  When creating your account, you must provide accurate and
                  complete information, and you must keep this information up to
                  date.
                </p>
              </div>

              <div className="block">
                You are solely responsible for the activity that occurs on your
                account, and you must keep your account password secure. The
                password with your account must be at least eight (8) characters
                and we encourage you to make it “strong” by using a combination
                of upper and lowercase letters, numbers and symbols. You must
                notify MatchHat immediately of any breach of security or
                unauthorized use of your account. Please note that MatchHat will
                not be liable for any losses caused by any unauthorized use of
                your account. We cannot and will not be liable for any loss or
                damage arising from your failure to comply with the above.
              </div>

              <div className="block">
                <p>
                  <strong>iii. Use of your information</strong>
                </p>
                <p>
                  You may control your User profile and how you interact with us
                  on the platform by changing the Settings within the MatchHat
                  Software as defined below. We may provide you with different
                  types of communication. Visit our{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link> for more
                  information.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Service communications:</strong>
                </p>
                <p>
                  We may need to provide you with certain communications, such
                  as service announcements and administrative messages. These
                  are made to inform you on the activity of your account and the
                  service (for instance data breach, password reset, changes of
                  features to the service etc). These communications are
                  considered PART OF THE SERVICES AND YOUR ACCOUNT, AND YOU MAY
                  NOT BE ABLE TO OPT-OUT FROM RECEIVING THEM. Thus, by providing
                  MatchHat your email address, YOU CONSENT TO MATCHHAT USING
                  YOUR EMAIL ADDRESS TO SEND YOU SERVICE-RELATED NOTICES and
                  including any notices required by law, in lieu of
                  communication by postal mail. However, if you don’t agree to
                  such communications sent to you, you can disable your account.
                  If you would want us to disable your account, please contact:
                  contact@matchhat.com
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Notification communications:</strong>
                </p>
                <p>
                  We will send notification emails based on the activity of your
                  account, for example when another User invites you as a
                  collaborator, or when you receive a private message.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Marketing communications:</strong>
                </p>
                <p>
                  We may also send you communications to offer you the best
                  possible service and keep you updated. Therefore we may use
                  your email address to send you other messages, such as
                  newsletters, promotions or special offers. If you do not want
                  to receive such email messages, YOU MAY OPT OUT by following
                  the “Unsubscribe” procedure. Opting out may prevent you from
                  receiving email messages regarding updates, improvements, or
                  offers. Please note that we are not selling or sharing your
                  email address with a third party for marketing purposes.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>iv. User interactions</strong>
                </p>
                <p>
                  You are solely responsible for your interactions with other
                  MatchHat Users. We reserve the right, but have no obligation,
                  to monitor disputes between you and other Users. MatchHat
                  shall have no liability for your interactions with other
                  Users, or for any User’s action or inaction.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>3.2. Deletion of content and account</strong>
                </p>
                <p>
                  At any time, you may delete your Content from MatchHat
                  directly on the post, or delete your account in “Settings”.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Usernames:</strong> MatchHat reserves the right to
                  force forfeiture of any profile name for any reason.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Content:</strong> We reserve the right to remove
                  Content that violates our{' '}
                  <Link to="/terms-of-use">Terms of Use</Link>, our{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  (such as copyright or trademark violations, for example), or
                  our{' '}
                  <Link to="/community-guidelines">
                    Community Guidelines
                  </Link>{' '}
                  (including but not limited to any inappropriate Content,
                  impersonation, unlawful conduct, or harassment), or as are
                  required to do so by law. Information regarding specific
                  policies and the process for reporting violations can be found
                  in our <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Termination or Suspension of your account:</strong>{' '}
                  MatchHat also reserves the right to terminate or suspend your
                  account at any time, with or without notice and at our sole
                  discretion. We may permanently or temporarily terminate or
                  suspend your access to the Service without notice and
                  liability for any reason, including if you violate any
                  provision of this Agreement or for no reason. When a User’s
                  account is terminated, you lose all rights to access or use
                  MatchHat. When you delete posted Content or your account is
                  terminated, we will use reasonable efforts to remove all of
                  your Content from MatchHat.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Backed up Data:</strong> Please note that the Content
                  you delete may persist for a limited period of time in backup
                  copies and may still be visible where others have shared it.
                  If you delete your account or Content, it may be permanently
                  unrecoverable. Also we may retain backups of your Content on
                  our servers (though not publicly visible) even after you ask
                  to have it deleted and for a maximum of seven days. When you
                  use MatchHat you agree to our{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>, which
                  describes what information we collect and how we use it,
                  process it and store it.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Change of the Services:</strong> We may, with or
                  without prior notice, change the Services, stop providing the
                  Services or any feature of it, or even create usage limits for
                  the Services, to you or to every Users generally.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>Agreement still binding:</strong>
                </p>
                <p>
                  Upon termination for any reason or no reason, you continue to
                  be bound by this Agreement.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">4. SERVICE RULES</h4>
              </div>

              <div className="block">
                <p>
                  Subject to the terms and conditions of this Agreement, you are
                  hereby granted a non-exclusive, limited, non-transferable,
                  freely revocable license to use the Services as permitted by
                  the features of the Services. MatchHat may terminate this
                  license at any time for any reason or no reason.
                </p>
              </div>

              <div className="block">
                <p>
                  MatchHat reserves all rights not expressly granted herein in
                  the Service and the MatchHat Content.
                </p>
              </div>

              <div className="block">
                <p>
                  When you visit or use MatchHat Services, you agree not to
                  engage in any of the following prohibited activities:
                </p>
              </div>

              <div className="block">
                <div className="content">
                  <ol>
                    <li>
                      copying, distributing, or disclosing any part of the
                      Service in any medium, including without limitation by any
                      automated or non-automated “scraping”;
                    </li>
                    <li>
                      not to interfere with analytic codes and tools that the
                      Content may include that allow us to gather information on
                      use of the platform
                    </li>
                    <li>
                      using any automated system, including without limitation
                      “robots,” “spiders,” “offline readers,” etc., to access
                      the Service in a manner that sends more request messages
                      to the MatchHat servers than a human can reasonably
                      produce in the same period of time by using a conventional
                      web browser (except that MatchHat grants the operators of
                      public search engines revocable permission to use spiders
                      to copy publicly available materials from MatchHat for the
                      sole purpose of and solely to the extent necessary for
                      creating publicly available searchable indices of the
                      materials, but not caches or archives of such materials);
                    </li>
                    <li>
                      transmitting spam, chain letters, or other unsolicited
                      email;
                    </li>
                    <li>
                      attempting to interfere with, compromise the system
                      integrity or security or decipher any transmissions to or
                      from the servers running the Service;
                    </li>
                    <li>
                      taking any action that imposes, or may impose at our sole
                      discretion an unreasonable or disproportionately large
                      load on our infrastructure;
                    </li>
                    <li>
                      uploading invalid data, viruses, worms, or other software
                      agents through the Service;
                    </li>
                    <li>
                      collecting or harvesting any personally identifiable
                      information, including account names, from the Service;
                    </li>
                    <li>
                      using the Service for any commercial solicitation
                      purposes;
                    </li>
                    <li>
                      impersonating another person or otherwise misrepresenting
                      your affiliation with a person or entity, conducting
                      fraud, hiding or attempting to hide your identity;
                    </li>
                    <li>interfering with the proper working of the Service;</li>
                    <li>
                      accessing any content on the Service through any
                      technology or means other than those provided or
                      authorized by the Service;
                    </li>
                    <li>
                      bypassing the measures we may use to prevent or restrict
                      access to the Service, including without limitation
                      features that prevent or restrict use or copying of any
                      content or enforce limitations on use of the Service or
                      the content therein; or
                    </li>
                    <li>
                      the Content may include advertising and sponsorship
                      material but that you shall not be entitled to any revenue
                      from this and you are responsible for ensuring its
                      display.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="block">
                <p>
                  <strong>Streaming:</strong>
                </p>
                <p>
                  Accessing any audiovisual content that may be available on the
                  Services for any purpose or in any manner other than Streaming
                  (as defined below) is expressly prohibited unless explicitly
                  permitted by the functionality of the Services. “Streaming”
                  means a contemporaneous digital transmission of an audiovisual
                  work via the Internet from the MatchHat Service to a User’s
                  device in such a manner that the data is intended for
                  real-time viewing and not intended to be copied, stored,
                  permanently downloaded, or redistributed by the User.
                </p>
              </div>

              <div className="block">
                <p>
                  Notwithstanding the usage limits and refresh requirements
                  established by us from time to time, we can at any time
                  without notice limit or block access to any MatchHat Service
                  and Content, including where the stability and integrity of
                  the MatchHat services is under threat.
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">II. USE OF MATCHHAT SERVICES</h3>
              </div>

              <div className="block">
                <h4 className="is-size-5">1. COLLABORATION ON MATCHHAT</h4>
              </div>

              <div className="block">
                <p>
                  The key to success in the creative industry is collaboration.
                  MatchHat built a product that actively encourages and supports
                  it. Create a project, invite other users and collaborate
                  together to make ideas happen!
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>1.1. Project Owner</strong>
                </p>
                <p>
                  When you create a project, you become its owner (the “Project
                  Owner”), meaning that you have the ability to create and
                  modify the Project content as well as the ability to delete
                  the whole project.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>1.2. Project chat and comments</strong>
                </p>
                <p>
                  Users collaborating on a project can use a private chat room
                  to discuss their collaborative work. In both private and
                  public projects, this chat room is private to project
                  collaborators only.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>1.4. Project setting</strong>
                </p>
                <p>
                  Please think carefully about what you share and with whom. You
                  are responsible for what you upload and for determining if the
                  level of access to your User Content is appropriate. When you
                  share User Content on MatchHat, it is your responsibility to
                  understand and accept the extent to which you are letting
                  other users see and use your User Content.
                </p>
              </div>

              <div className="block">
                <p>
                  Please keep in mind that we may create reasonable technical
                  limits on file size, storage space, processing capacity, and
                  other technical limits.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>1.5. Project copyrights</strong>
                </p>
                <p>
                  The Project Owner and each Collaborator have a license to use
                  the content created by any Collaborator and the Project Owner
                  on a Project solely for use on the MatchHat Platform pursuant
                  to the general license agreed by each User under Section
                  (I)(2) of the Terms of Use.
                </p>
              </div>

              <div className="block">
                <p>
                  Under the Terms of Use, the Project Owner is not licensed to
                  use the content contributed by any Collaborator outside of the
                  MatchHat Platform. It is the responsibility of the Project
                  Owner to agree the terms of any such use with each and every
                  Collaborator on the Project.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">2. MATCHHAT PROPRIETARY RIGHTS</h4>
              </div>

              <div className="block">
                <p>
                  <strong>2.1. Exclusive property of MatchHat:</strong>
                </p>
                <p>
                  Except for your User Content, the Services and all materials
                  therein or transferred thereby, including, without limitation,
                  software, images, text, graphics, illustrations, logo such as
                  the “d” logo, patents, trademarks (including, without
                  limitation, the MatchHat marks), service marks, copyrights,
                  photographs, audio, videos, music, soundtracks and User
                  Content belonging to other Users (the "
                  <strong>MatchHat Content</strong>"), and all Intellectual
                  Property Rights related thereto, are the respective exclusive
                  property of MatchHat and its licensors (including other Users
                  who post User Content to the Service).
                </p>
              </div>

              <div className="block">
                <p>
                  The Service contains data, information, Intellectual Rights
                  and other content not owned by you ("
                  <strong>MatchHat Property</strong>"). You understand and agree
                  that regardless of terminology used, MatchHat Property
                  represents a limited license right governed solely by the
                  Terms of this Agreement and available for distribution, at
                  MatchHat’s sole discretion.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>2.2. Use of MatchHat property:</strong>
                </p>
                <p>
                  Except as explicitly provided herein, nothing in this
                  Agreement shall be deemed to create a license in or under any
                  such Intellectual Property Rights, and you agree not to sell,
                  license, rent, modify, distribute, copy, reproduce, transmit,
                  publicly display, publicly perform, publish, adapt, edit or
                  create derivative works from any MatchHat Content. Any use of
                  the MatchHat Content for any purpose not expressly permitted
                  by this Agreement is strictly prohibited.
                </p>
              </div>

              <div className="block">
                <p>
                  MatchHat reserves all rights to the MatchHat Content and no
                  right or license in the MatchHat is granted to you, except as
                  expressly set forth in this Agreement and the{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>2.3. Ideas on how to improve MatchHat:</strong>
                </p>
                <p>
                  You may choose to or we may invite you to submit feedback,
                  comments or ideas about the Services, including without
                  limitation about how to improve the Services or our products
                  ("<strong>Ideas</strong>"). By submitting any Idea, you agree
                  that your disclosure is gratuitous, unsolicited and without
                  restriction and will not place MatchHat under any fiduciary or
                  other obligation, and that we are free to use the Idea without
                  any additional financial compensation or credit to you, and/or
                  to disclose the Idea on a non-confidential basis or otherwise
                  to anyone. You further acknowledge that, by acceptance of your
                  submission, MatchHat does not waive any rights to use similar
                  or related ideas previously known to MatchHat, or developed by
                  its employees, or obtained from sources other than you.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>2.4. Database:</strong>
                </p>
                <p>
                  You acknowledge that you do not own the account you use to
                  access the Services. You agree that MatchHat has the right to
                  manage, regulate, control, modify and/or eliminate MatchHat
                  Property as MatchHat deems adequate, in any general or
                  specific case, and that MatchHat will have no liability to you
                  based on its exercise of such right. All data on MatchHat’s
                  servers are subject to deletion, alteration or transfer.
                  NOTWITHSTANDING ANY VALUE ATTRIBUTED TO SUCH DATA BY YOU OR
                  ANY THIRD PARTY, YOU UNDERSTAND AND AGREE THAT ANY DATA,
                  ACCOUNT HISTORY AND ACCOUNT CONTENT RESIDING ON MATCHHAT’S
                  SERVERS, MAY BE DELETED, ALTERED, MOVED OR TRANSFERRED AT ANY
                  TIME FOR ANY REASON, WITH OR WITHOUT NOTICE AND WITH NO
                  LIABILITY OF ANY KIND. MATCHHAT DOES NOT PROVIDE OR GUARANTEE,
                  AND EXPRESSLY DISCLAIMS, ANY VALUE, CASH OR OTHERWISE,
                  ATTRIBUTED TO ANY DATA RESIDING ON MATCHHAT’S SERVERS.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">3. BETA SERVICES</h4>
              </div>

              <div className="block">
                <p>
                  From time to time, We may offer new services (limited preview
                  services or new features to existing Services) in a
                  pre-release version. New services, new features to existing
                  services or limited preview services shall be known,
                  individually and collectively, as “Beta services”. If you
                  elect to use any Beta services, then your use of the Beta
                  services is subject to the following terms and conditions:
                </p>
              </div>

              <div className="block">
                <div className="content">
                  <ul>
                    <li>
                      You acknowledge and agree that the Beta services are
                      pre-release versions and may not work properly;
                    </li>
                    <li>
                      You acknowledge and agree that your use of the Beta
                      services may expose you to unusual risks of operational
                      failures;
                    </li>
                    <li>
                      The Beta services are provided as-is, so we do not
                      recommend using them in production or mission critical
                      environments;
                    </li>
                    <li>
                      MatchHat reserves the right to modify, change, or
                      discontinue any aspect of the Beta services at any time;
                    </li>
                    <li>
                      Commercially released versions of the Beta services may
                      change substantially, and programs that use or run with
                      the Beta services may not work with the commercially
                      released versions or subsequent releases;
                    </li>
                    <li>
                      MatchHat may limit availability of customer service
                      support time dedicated to support of the Beta services;
                    </li>
                    <li>
                      You acknowledge and agree to provide prompt feedback
                      regarding your experience with the Beta services in a form
                      reasonably requested by us, including information
                      necessary to enable us to duplicate errors or problems you
                      experience. You acknowledge and agree that we may use your
                      feedback for any purpose, including product development
                      purposes. At our request you will provide us with comments
                      that we may use publicly for press materials and marketing
                      collateral. Any intellectual property inherent in your
                      feedback or arising from your use of the Beta services
                      shall be owned exclusively by MatchHat;
                    </li>
                    <li>
                      You acknowledge and agree that all information regarding
                      your use of the Beta services, including your experience
                      with and opinions regarding the Beta services, is
                      confidential, and may not be disclosed to a third party or
                      used for any purpose other than providing feedback to
                      MatchHat;
                    </li>
                    <li>
                      The Beta services are provided “as is”, “as available”,
                      and “with all faults”.
                    </li>
                    <li>
                      To the fullest extent permitted by law, MatchHat disclaims
                      any and all warranties, statutory, express or implied,
                      with respect to the Beta services including, but not
                      limited to, any implied warranties of title,
                      merchantability, fitness for a particular purpose and
                      non-infringement.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="block">
                <h4 className="is-size-5">4. NO PROFESSIONAL ADVICE</h4>
                <p>
                  If the Service provides professional information (for example,
                  business, legal, financial, medical or else), it is for
                  informational purposes only and should not be construed as
                  professional advice. No action should be taken based upon any
                  information contained in the Service. You should seek
                  independent professional advice from a person who is licensed
                  and/or qualified in the applicable area.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">5. PRIVACY</h4>
                <p>
                  We care about the privacy of our Users. You understand that by
                  using the Services you consent to the collection, use and
                  disclosure of your aggregate data as set forth in our Privacy
                  Policy and to have your personally identifiable information
                  collected, used, shared and stored as set forth in our Privacy
                  Policy.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">6. SECURITY</h4>
                <p>
                  MatchHat cares about the integrity and security of your
                  personal information. MatchHat uses commercially reasonable
                  physical, managerial, and technical safeguards to preserve the
                  integrity and security of your personal information and
                  implement your privacy settings. However, we cannot guarantee
                  that unauthorised third parties will never be able to defeat
                  our security measures or use your personal information for
                  improper purposes. You acknowledge that you provide your
                  personal information at your own risk.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">7. USER’S CONTENT COPYRIGHTS</h4>
                <p>
                  Note that posting or displaying your Content on MatchHat is
                  not a substitute for registering the Intellectual Rights
                  attached to it to the appropriate authorities. Depending on
                  the laws and Regulations of your country or territory, you may
                  wish to register the Intellectual Rights in relation with your
                  creation, such as but not limited to copyrights, trademarks,
                  moral rights, goodwill and other intellectual property rights.
                  If you have legal proof that your copyrighted work has been
                  copied in a way that constitutes copyright infringement and is
                  accessible via the Services, please notify MatchHat’s at
                  contact@matchhat.com. Please note that this procedure is
                  exclusively for notifying MatchHat and its affiliates that
                  your copyrighted material has been infringed. The preceding
                  requirements do not constitute legal advice. It may be
                  advisable to contact an attorney regarding your rights and
                  obligations under the applicable laws. MatchHat may, at its
                  own discretion, limit access to the Service and/or terminate
                  the accounts of any Users who infringe any Intellectual
                  Property rights of others, whether or not there is any
                  repeated infringement and in accordance with the law.
                </p>
              </div>

              <div className="block">
                <h3 className="is-size-4">III. RESPONSIBILITIES</h3>
              </div>

              <div className="block">
                <h4 className="is-size-5">
                  1. THIRD-PARTY LINKS AND INFORMATION
                </h4>
              </div>

              <div className="block">
                <p>
                  MatchHat has no responsibility for any third party’s activity.
                  The Service may contain links to third-party materials that
                  are not owned or controlled by MatchHat. MatchHat does not
                  endorse or assume any responsibility for any such third-party
                  sites, information, materials, products, or services. If you
                  access a third-party website or service from the Service or
                  share your User Content on or through any third-party website
                  or service, you do so at your own risk, and you understand
                  that this Agreement and the MatchHat{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link> do not apply
                  to your use of such sites. Therefore you EXPRESSLY RELIEVE
                  MATCHHAT FROM ANY AND{' '}
                  <strong>
                    ALL LIABILITY ARISING FROM YOUR USE OF ANY THIRD-PARTY
                    WEBSITE, SERVICE, OR CONTENT, INCLUDING WITHOUT LIMITATION
                    USER CONTENT SUBMITTED BY OTHER USERS
                  </strong>
                  .
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">2. CREDITS</h4>
              </div>

              <div className="block">
                <p>
                  The MatchHat Services (website and app) integrate the
                  respective application programming interfaces, data,
                  databases, sub-processors, media libraries, images.;
                </p>
              </div>

              <div className="block">
                <p>
                  The sub-processors we work with do not endorse MatchHat
                  Service, but they do have legal protections in the materials
                  they have let us use. You must not violate their legal
                  protections by copying or disclosing the material or removing
                  any copyright mark of those third parties without our
                  permission.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">3. INDEMNITY</h4>
              </div>

              <div className="block">
                <p>
                  You agree to{' '}
                  <strong>
                    DEFEND, INDEMNIFY AND HOLD HARMLESS MATCHHAT AND ITS
                    SUBSIDIARIES, AGENTS, LICENSEES, MANAGERS, AND OTHER
                    AFFILIATED COMPANIES, AND THEIR EMPLOYEES, CONTRACTORS,
                    AGENTS, OFFICERS AND DIRECTORS, FROM AND AGAINST ANY AND ALL
                    CLAIMS, DAMAGES, OBLIGATIONS, LOSSES, LIABILITIES, COSTS OR
                    DEBT, AND EXPENSES
                  </strong>{' '}
                  (including but not limited to attorney’s fees) arising from:
                </p>
              </div>

              <div className="block">
                <div className="content">
                  <ol>
                    <li>
                      your use of and access to the Service and any data or
                      content transmitted or received by you;
                    </li>
                    <li>
                      your violation of any term of this Agreement, including
                      without limitation your breach of any of the
                      representations and warranties above;
                    </li>
                    <li>
                      your violation of any third-party right, including without
                      limitation any privacy right or Intellectual Property
                      Right;
                    </li>
                    <li>
                      your violation of any applicable law, rule or regulation;
                    </li>
                    <li>
                      User Content or any content that is submitted via your
                      account including without limitation misleading, false, or
                      inaccurate information;
                    </li>
                    <li>your willful misconduct; or</li>
                    <li>
                      any other party’s access and use of the Service with your
                      unique username, password or other appropriate security
                      code.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="block">
                <h4 className="is-size-5">4. NO WARRANTY</h4>
              </div>

              <div className="block">
                <p>
                  THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                  BASIS. YOU ARE USING OF THE SERVICE IS AT YOUR OWN RISK. To
                  the maximum extent permitted by applicable law, the service is
                  provided WITHOUT WARRANTIES OF ANY KIND, whether express or
                  implied, including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose. No advice
                  or information, whether oral or written, obtained by you from
                  MatchHat or through the service will create any warranty not
                  expressly stated herein.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>4.1. Reliability of the service:</strong>
                </p>
                <p>
                  WITHOUT LIMITING THE FOREGOING, MATCHHAT, ITS EMPLOYEES, ITS
                  CONTRACTORS, ITS HOSTS, ITS AFFILIATES, AND ITS LICENSORS{' '}
                  <strong>DO NOT WARRANT THAT:</strong>
                </p>
              </div>

              <div className="block">
                <div className="content">
                  <ol>
                    <li>
                      THE CONTENT IS{' '}
                      <strong>ACCURATE, RELIABLE OR CORRECT;</strong>
                    </li>
                    <li>
                      THE SERVICE <strong>WILL MEET YOUR REQUIREMENTS;</strong>
                    </li>
                    <li>
                      THE SERVICE WILL BE <strong>AVAILABLE AT</strong> ANY
                      PARTICULAR TIME OR LOCATION,{' '}
                      <strong>UNINTERRUPTED</strong> OR <strong>SECURE</strong>;
                    </li>
                    <li>
                      ANY <strong>DEFECTS OR ERRORS WILL BE CORRECTED</strong>;
                      OR
                    </li>
                    <li>
                      THE SERVICE IS <strong>FREE OF VIRUSES OR</strong> OTHER{' '}
                      <strong>HARMFUL COMPONENTS</strong>.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="block">
                <p>
                  <strong>4.2. Content downloaded:</strong>
                </p>
                <p>
                  ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
                  OF THE SERVICE IS{' '}
                  <strong>
                    DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
                    RESPONSIBLE FOR ANY DAMAGE
                  </strong>{' '}
                  TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT
                  RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>4.3. 3rd parties:</strong>
                </p>
                <p>
                  MatchHat DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                  RESPONSIBILITY FOR ANY PRODUCT OR SERVICE PROMOTED OR OFFERED
                  BY A THIRD PARTY THROUGH THE MATCHHAT SERVICES OR{' '}
                  <strong>ANY HYPERLINKED WEBSITE OR SERVICE</strong>, AND{' '}
                  <strong>
                    MATCHHAT WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
                    TRANSACTION
                  </strong>{' '}
                  BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>To the permitted extent of the law:</strong>
                </p>
                <p>
                  THE DISCLAIMERS AND EXCLUSIONS UNDER THIS AGREEMENT WILL NOT
                  APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">5. LIMITATION OF LIABILITY</h4>
              </div>

              <div className="block">
                <p>
                  THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE WHOLE
                  AGREEMENT INCLUDING ANY USER PARTICIPATION IN ANY QUESTION
                  TIME:
                </p>
              </div>

              <div className="block">
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,{' '}
                  <strong>IN NO EVENT SHALL MATCHHAT</strong>, ITS AFFILIATES,
                  AGENTS, HOSTS, DIRECTORS, EMPLOYEES, CONTRACTORS, SUPPLIERS OR
                  LICENSORS{' '}
                  <strong>
                    BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL OR EXEMPLARY DAMAGES, WHETHER INCURRED
                    DIRECTLY OR INDIRECTLY AND INCLUDING WITHOUT LIMITATION
                  </strong>{' '}
                  DAMAGES FOR LOSS OF PROFITS, REVENUES, GOODWILL, USE, DATA OR
                  OTHER INTANGIBLE LOSSES,{' '}
                  <strong>ARISING OUT OF OR RELATING TO ANY:</strong>
                </p>
              </div>

              <div className="block">
                <div className="content">
                  <ol>
                    <li>
                      USE OF, OR INABILITY TO USE, MATCHHAT SERVICE AND ANY
                      WEBSITES THAT ARE LINKED TO OR FROM IT;
                    </li>
                    <li>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;</li>
                    <li>
                      PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                      WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR
                      SERVICE;
                    </li>
                    <li>
                      UNAUTHORIZED ACCESS (RESULTING FROM HACKING, TAMPERING OR
                      OTHER) TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                      PERSONAL INFORMATION STORED THEREIN;
                    </li>
                    <li>
                      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                      SERVICE;
                    </li>
                    <li>
                      BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
                      TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY;
                    </li>
                    <li>
                      ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR
                      DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                      POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
                      THROUGH THE SERVICE; AND/OR
                    </li>
                    <li>
                      USER CONTENT OR THE DEFAMATORY, HARASSING, OFFENSIVE, OR
                      ILLEGAL CONDUCT OF ANY THIRD PARTY.
                    </li>
                  </ol>
                </div>
              </div>

              <div className="block">
                <p>
                  THE LIMITATION OF LIABILITY IN THIS SECTION APPLIES WHETHER
                  THE ALLEGED LIABILITY IS BASED ON{' '}
                  <strong>
                    CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER
                    BASIS
                  </strong>
                  , EVEN IF MATCHHAT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGE.
                </p>
              </div>

              <div className="block">
                <p>
                  THIS INCLUDES, BUT IS NOT LIMITED TO, THE LOSS OR DAMAGE OF
                  YOUR{' '}
                  <strong>
                    INCOME OR REVENUE; SALARY, BENEFITS OR OTHER PAYMENTS;
                    BUSINESS; PROFITS OR CONTRACTS; OPPORTUNITY; BUSINESS
                    INTERRUPTION; ANTICIPATED SAVINGS; DATA; GOODWILL OR
                    REPUTATION; TANGIBLE PROPERTY; WASTED MANAGEMENT OR OFFICE
                    TIME; INTANGIBLE PROPERTY, INCLUDING LOSS, CORRUPTION OR
                    DAMAGE TO DATA OR ANY COMPUTER SYSTEM, HARDWARE, SOFTWARE OR
                    ANY PERSONAL PROPERTY LOSS OR ANY OTHER LOSSES
                  </strong>
                  , RESULTING FROM YOUR ACCESS, USE OR NON-USE OF THE SERVICE.
                  We strongly recommend that you (i) take care to verify the
                  suitability and compatibility of your devices before use, (ii)
                  take reasonable precautions to protect yourself against
                  harmful programs or devices including through installation of
                  antivirus software.
                </p>
              </div>

              <div className="block">
                <p>
                  THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
                  FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
                  JURISDICTION. We may still be liable for death or personal
                  injury arising from our negligence; fraudulent
                  misrepresentation; any other liability which cannot be
                  excluded or limited under applicable law; and only if we have
                  failed to carry out our obligations under these Terms to a
                  reasonable standard, or breached any duties imposed on us by
                  law, including if we have caused death or personal injury by
                  our negligence, and that failure is not attributed to:
                </p>
              </div>

              <div className="block">
                <div className="content">
                  <ul>
                    <li>
                      your own fault, for example your failure to implement
                      recommendations previously advised by us,
                    </li>
                    <li>
                      a third party unconnected with our performance of this
                      Agreement, for instance problems due to communication
                      network performance of this agreement, congestion, and
                      connectivity, or the performance of your computer
                      equipment; or
                    </li>
                    <li>
                      any other events which neither we or our suppliers could
                      have foreseen or forestalled even if they had taken
                      reasonable care.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="block">
                <p>
                  The Service is controlled and operated from facilities in the
                  United Kingdom. MatchHat makes no representations that the
                  Service is appropriate or available for use in other
                  locations. Those who access or use the Service from other
                  jurisdictions do so at their own volition and are entirely
                  responsible for compliance with all applicable United Kingdom
                  and local laws and regulations. You may not use the Service if
                  you are a foreign person or entity blocked or denied by the
                  United Kingdom government.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>6.2. Conciliation</strong>
                </p>
                <p>
                  In case of a disagreement between you and MatchHat, you agree
                  to first contact us at{' '}
                  <Link to="mailto:contact@matchhat.com">
                    contact@matchhat.com
                  </Link>{' '}
                  in order to inform us of a dispute you might have and try to
                  resolve it with us. As a result, in case of a claim, dispute,
                  or controversy between the Parties on the subject matter,
                  formation, application, interpretation or performance of this
                  Agreement and PRIOR TO ANY LITIGATION PROCEDURE, each Party
                  undertakes to designate a person duly authorized to search for{' '}
                  <strong>
                    AN AMICABLE SOLUTION IN GOOD FAITH TO ENTER INTO A
                    CONCILIATION PROCEDURE
                  </strong>
                  .
                </p>
              </div>

              <div className="block">
                <p>
                  These persons shall meet at the initiative of the most
                  diligent Party, within thirty (30) days, from receipt of the
                  letter requesting a conciliation meeting. The Party taking the
                  initiative of conciliation should set the agenda. The
                  decisions, if they are{' '}
                  <strong>MADE BY COMMON AGREEMENT</strong>, will have
                  contractual value and will constitute a SETTLEMENT BETWEEN THE
                  PARTIES ON THE SUBJECT MATTER OF THE DISPUTE.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>6.3. Class Action and Jury Trial Waiver</strong>
                </p>
                <p>
                  YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND
                  MATCHHAT ARE EACH{' '}
                  <strong>
                    WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                    CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL
                    ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND
                  </strong>
                  .
                </p>
              </div>

              <div className="block">
                <p>
                  With respect to all persons and entities, regardless of
                  whether they have obtained or used the service for personal,
                  commercial or other purposes, ALL CLAIMS MUST BE BROUGHT IN
                  THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                  CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION,
                  PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE
                  PROCEEDING.
                </p>
              </div>

              <div className="block">
                <p>
                  THIS WAIVER APPLIES TO CLASS ACTION IN ANY JURISDICTION, AND,
                  UNLESS WE AGREE OTHERWISE, THE JUDGE MAY NOT CONSOLIDATE MORE
                  THAN ONE PERSON’S CLAIMS.
                </p>
              </div>

              <div className="block">
                <h4 className="is-size-5">7. MISCELLANEOUS</h4>
              </div>

              <div className="block">
                <p>
                  <strong>7.1. Assignment</strong>
                </p>
                <p>
                  This Agreement does not give rights to any third parties. You
                  cannot transfer your rights and licenses granted hereunder or
                  obligations under this Agreement without our consent. Our
                  rights and obligations can be assigned by MatchHat to others
                  without restriction. For example, this could occur if our
                  ownership changes (as in a merger, acquisition or sale of
                  assets or else) or by law. Any attempted transfer or
                  assignment in violation hereof shall be null and void.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>
                    7.2. Notification regarding Procedures and Changes to the
                    Agreement
                  </strong>
                </p>
                <p>
                  MatchHat may provide notifications, whether such notifications
                  are required by law or are for marketing or other business
                  related purposes, to you via email notice, written or hard
                  copy notice, or through posting of such notice on our website,
                  as determined by MatchHat in our sole discretion. MatchHat
                  reserves the right to determine the form and means of
                  providing notifications to our Users, provided that you may
                  opt out of certain means of notification as described in this
                  Agreement. MatchHat is not responsible for any automatic
                  filtering you or your network provider may apply to email
                  notifications we send to the email address you provide us.
                </p>
              </div>

              <div className="block">
                <p>
                  MatchHat may, in its sole discretion, modify or update this
                  Agreement from time to time, and so you should review this
                  page periodically. When we change the Agreement in a material
                  manner, we will update the ‘last modified’ date at the bottom
                  of this page. Your continued use of the Service after any such
                  change constitutes your acceptance of the new Terms of Use. IF
                  YOU DO NOT AGREE TO ANY OF THESE TERMS OR ANY FUTURE TERMS OF
                  USE, DO NOT USE OR ACCESS (OR CONTINUE TO ACCESS) THE SERVICE.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>7.3. Entire Agreement and Severability</strong>
                </p>
              </div>

              <div className="block">
                <p>
                  This Agreement, together with any amendments and any
                  additional Agreements you may enter into with MatchHat in
                  connection with the Service, shall constitute the entire
                  Agreement between you and MatchHat concerning the Service.
                </p>
              </div>

              <div className="block">
                <p>
                  If any provision of this Agreement is deemed invalid by a
                  court of competent jurisdiction, the invalidity of such
                  provision shall not affect the validity of the remaining
                  provisions of this Agreement, which shall remain in full force
                  and effect.
                </p>
              </div>

              <div className="block">
                <p>
                  <strong>7.4. No Waiver</strong>
                </p>
              </div>

              <div className="block">
                <p>
                  No waiver of any term of this Agreement shall be deemed a
                  further or continuing waiver of such term or any other term,
                  and MatchHat’s failure to assert any right or provision under
                  this Agreement shall not constitute a waiver of such right or
                  provision. Any amendment or waiver to our Agreement must be in
                  writing and signed by us. If we fail to enforce any aspect of
                  this Agreement, it will not be a waiver. We reserve all rights
                  not expressly granted to you.
                </p>
              </div>

              <div className="block">
                <p>
                  If you have any questions, please send us an email at{' '}
                  <Link to="mailto:contact@matchhat.com">
                    contact@matchhat.com
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ApolloProvider>
);

export default TermsOfUsePage;
