import React, { Fragment, useState } from 'react';
import Comment, { IComment } from './Comment';
import { CommentCategory } from './constants';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';

export interface ICommentListProps {
  comments: IComment[],
  commentCategory?: CommentCategory,
  user: GET_CURRENT_USER_me;
}

const DEFAULT_MAX_COMMENTS_TO_SHOW = 4;

const CommentList: React.FunctionComponent<ICommentListProps> = (
  props: ICommentListProps
) => {
  const { comments, commentCategory, user } = props;
  const [hasShowMoreComments, setHasShowMoreComments] = useState<boolean>(
    comments.length > DEFAULT_MAX_COMMENTS_TO_SHOW
  );
  const toggleHasShowMoreComments = () => {
    setHasShowMoreComments(!hasShowMoreComments);
  };

  const showMoreCommentsText: JSX.Element = (
    <span
      className={
        'media comments-toggle-text is-marginless content is-small has-text-link'
      }
      onClick={toggleHasShowMoreComments}
    >
      {hasShowMoreComments ? 'Show more comments' : 'Show less'}
    </span>
  );

  const renderComments = (comments: IComment[], commentCategory?: CommentCategory) => {
    return (
      <Fragment>
        {hasShowMoreComments &&
          // Render the last DEFAULT_MAX_COMMENTS_TO_SHOW comments if there are more
          comments
            .slice(-DEFAULT_MAX_COMMENTS_TO_SHOW)
            .map((comment: IComment, idx: number) => (
              <Comment
                key={`${idx}-${comment.id}`}
                {...comment}
                commentCategory={commentCategory}
                user={user}
              />
            ))}
        {!hasShowMoreComments &&
          comments.map((comment: IComment, idx: number) => (
            <Comment
              key={`${idx}-${comment.id}`}
              {...comment}
              commentCategory={commentCategory}
              user={user}
            />
          ))}
      </Fragment>
    );
  };

  return (
    <div className="comments m-b-1">
      <Fragment>
        {comments &&
          comments.length > DEFAULT_MAX_COMMENTS_TO_SHOW &&
          showMoreCommentsText}
        {renderComments(comments, commentCategory)}
      </Fragment>
    </div>
  );
};

CommentList.defaultProps = {
  commentCategory: CommentCategory.project
};

export default CommentList;
