import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import interestedIcon from '../../../assets/images/project/icon-interested.svg';
import { GET_CURRENT_USER_me } from '../../../graphql/types/GET_CURRENT_USER';
import { GET_PROJECT_getProject_interested } from '../../../graphql/types/GET_PROJECT';
import { TOGGLE_INTERESTED } from '../../../graphql/types/TOGGLE_INTERESTED';
import { useRedirect } from '../../../hooks/useRedirect';

enum EPageTypes {
  list = 'list',
  project = 'project'
}

const MAX_USERS_TO_DISPLAY = 9;
interface IInterestedProject {
  projectId: string;
  interested: GET_PROJECT_getProject_interested[];
  wasInterestedByUser: boolean;
  pageType?: string;
  projectAuthorId?: string;
  currentUserId?: string;
  user?: GET_CURRENT_USER_me;
}

const InterestedProject: React.FunctionComponent<IInterestedProject> = ({
  projectId,
  interested,
  wasInterestedByUser,
  projectAuthorId,
  currentUserId,
  user
}: IInterestedProject) => {
  const [fakeInterested, setFakeInterested] = useState({
    interestedLength: interested.length,
    wasInterested: wasInterestedByUser
  });

  const [isShowInterestedUsers, setShowInterestedUsers] = useState(false);
  const { redirectToHomePage } = useRedirect();

  // Faking the interested method to improve the UI experience when interested on a project.
  const handlefakeInterested = () => {
    setFakeInterested(prevInterested => {
      const interestedLength = prevInterested.wasInterested
        ? prevInterested.interestedLength - 1
        : prevInterested.interestedLength + 1;

      return {
        ...prevInterested,
        interestedLength,
        wasInterested: !prevInterested.wasInterested
      };
    });
  };

  const [toggleInterested] = useMutation(TOGGLE_INTERESTED, {
    variables: {
      projectId
    }
  });

  return (
    <a
      className="post-card-stats-item tooltip has-text-weight-semibold is-inline-flex is-small"
      onClick={async () => {
        if (!user) {
          redirectToHomePage();
          return;
        }
        await toggleInterested();
        handlefakeInterested();
      }}
      onMouseEnter={() => {
        if (currentUserId === projectAuthorId) {
          setShowInterestedUsers(true);
        }
      }}
    >
      <img src={interestedIcon} alt="interested icon" />
      <span
        className={`${(fakeInterested.wasInterested && 'has-text-info') ||
          'has-text-grey-dark'}`}
      >
        Interested
      </span>
      {fakeInterested.interestedLength !== 0 && (
        <span className="is-size-7 is-light icon--is-inline-flex is-hover-underline">
          {fakeInterested.interestedLength}
        </span>
      )}
      <InterestedUserListTooltip
        isShowInterestedUsers={isShowInterestedUsers}
        interested={interested}
      />
    </a>
  );
};

InterestedProject.defaultProps = {
  pageType: EPageTypes.list
};

interface IInteresteredUserListToolTip {
  isShowInterestedUsers: boolean;
  interested: GET_PROJECT_getProject_interested[];
}

const InterestedUserListTooltip = ({
  isShowInterestedUsers,
  interested
}: IInteresteredUserListToolTip) => {
  if (isShowInterestedUsers && interested.length > 0) {
    return (
      <div className="tooltiptext is-size-8">
        {interested.map(
          (interest: GET_PROJECT_getProject_interested, idx: number) => {
            if (idx < MAX_USERS_TO_DISPLAY) {
              return (
                <span className="is-block" key={interest.id}>
                  {interest.author.profile.name}
                </span>
              );
            }
            return undefined;
          }
        )}
      </div>
    );
  }

  return (
    <div className="tooltiptext is-size-8">
      <span className="is-block">
        Click interested if you want to know more about this project.
      </span>
    </div>
  );
};

export default InterestedProject;
