import { gql } from '@apollo/client';

export const GET_PROFESSIONS_BY_FILTER_QUERY = gql`
  query GET_PROFESSIONS_BY_FILTER_QUERY($filter: String!) {
    getProfessionsByFilter(filter: $filter) {
      value: id
      label: name
    }
  }
`;
