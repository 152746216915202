import React, { Fragment, useEffect } from 'react';
// import { Route } from 'react-router';
import { Route, useLocation } from 'react-router-dom';

import { pageView } from '../services/googleAnalytics';

const PublicRoute = props => {
  const location = useLocation();

  useEffect(() => {
    pageView(location.pathname);
  }, [location]);
  return (
    <Fragment>
      <Route {...props} />
    </Fragment>
  );
};

export default PublicRoute;
