import type { IClientUser } from '../../interfaces/user';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Navigation from '../../components/Navigation';
import { SpinnerFull } from '../../components/Spinner';
import { useConfirmUser } from '../../hooks/useConfirmUser';

const ConfirmUser = (props: IClientUser) => {
  const location = useLocation();
  const [loading, error, success, confirmUser] = useConfirmUser();

  React.useEffect(() => {
    if (!confirmUser || typeof confirmUser === 'boolean') {
      return
    }

    if (location.search) {
      const urlParams = location.search.split('?token=');
      confirmUser(urlParams[1]);
    } else {
      confirmUser('');
    }
  }, []); // eslint-disable-line

  return (
    <main className="page-user-profile">
      <Navigation {...props} />
      <section className="container">
        <div className="p-t-2">
          {loading && <SpinnerFull />}
          {!loading && success && (
            <div className="notification is-success">
              User successfully confirmed!
            </div>
          )}
          {!loading && error && (
            <div className="notification is-danger">
              The confirmation token provided is invalid. Please try again
              later.
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default ConfirmUser;
