import type { ReactNode } from 'react';
import React, { createContext, Fragment } from 'react';

import { useMutation } from '@apollo/client';
import { FieldArray, useFormik } from 'formik';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import autoCompleteStyles from '../components/User/Forms/autocompleteStyles';
import { ADD_USER_PROFESSION } from '../graphql/ADD_USER_PROFESSION';
import { DELETE_USER_PROFESSION } from '../graphql/DELETE_USER_PROFESSION';
import { GET_PROFESSIONS_BY_FILTER_QUERY } from '../graphql/GET_PROFESSIONS_BY_FILTER_QUERY';
import { GET_CURRENT_USER_me_professions } from '../graphql/types/GET_CURRENT_USER';
import { IHandleOptionSelect, useAutocomplete } from '../hooks/useAutocomplete';

const initData = {
  professionNames: [] as string[]
};

export const UserProfessionContext = createContext(initData);

interface IUserProfessionContextProviderProps {
  children: ReactNode;
  error?: string;
  professions: GET_CURRENT_USER_me_professions[];
}

const userProfessionSchema = Yup.object().shape({
  newProfession: Yup.string().required('This field is required.')
});

export const UserProfessionContextProvider = (
  props: IUserProfessionContextProviderProps
) => {
  const [addUserProfession] = useMutation(ADD_USER_PROFESSION, {
    onCompleted: () => toast.success('User profession successfully added.')
  });

  const [deleteUserProfession] = useMutation(DELETE_USER_PROFESSION, {
    onCompleted: () => toast.success('User profession successfully deleted.')
  });

  const professionsOptsSelectCb = async (params: IHandleOptionSelect) => {
    if (!params) {
      return;
    }

    const { __isNew__, label, value } = params;

    await addUserProfession({
      variables: { professionName: __isNew__ ? value : label }
    });
  };

  const { loadOptions, loading } = useAutocomplete({
    query: GET_PROFESSIONS_BY_FILTER_QUERY
  });

  const { handleSubmit, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      newProfession: '',
      professionNames: props.professions.map((prof) => prof.name) || []
    },
    onSubmit: async (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    validationSchema: userProfessionSchema
  });

  return (
    <UserProfessionContext.Provider value={values}>
      <form onSubmit={handleSubmit} className="m-b-1">
        <FieldArray name="professionNames">
          {(arrayHelper) => {
            return (
              <Fragment>
                <div className="field">
                  <div className="field-body">
                    <div className="field">
                      <div className="label is-uppercase is-size-8">
                        Profession <span className="has-text-danger">*</span>
                      </div>
                      <div className="control">
                        <AsyncCreatableSelect
                          cacheOptions={true}
                          isClearable={true}
                          className="is-small"
                          escapeClearsValue={true}
                          backspaceRemovesValue={true}
                          isLoading={loading}
                          onChange={professionsOptsSelectCb}
                          loadOptions={loadOptions}
                          placeholder={'Eg. Editor'}
                          styles={autoCompleteStyles}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          value={null}
                        />
                      </div>
                      {props.error && (
                        <span className="error field-error has-text-danger is-uppercase">
                          {props.error}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column p-t-0">
                    {values.professionNames &&
                      values.professionNames.map((name, idx) => (
                        <Fragment key={`${idx}-${name}`}>
                          <span className="tag page-add-project-card-content-tag is-primary m-b-1">
                            {name}
                            <button
                              className="delete is-small"
                              onClick={async () => {
                                arrayHelper.remove(idx);
                                await deleteUserProfession({
                                  variables: {
                                    professionName: name
                                  }
                                });
                              }}
                            />
                          </span>
                        </Fragment>
                      ))}
                  </div>
                </div>
              </Fragment>
            );
          }}
        </FieldArray>
        {props.children}
      </form>
    </UserProfessionContext.Provider>
  );
};
