import { useEffect, useState } from 'react';

/**
 * This hook returned current window size
 */

export const useBreakpoint = () => {
  const getDeviceSize = width => {
    if (width < 320) {
      return 'xs';
    } else if (width >= 320 && width < 720) {
      return 'sm';
    } else if (width >= 720 && width < 1024) {
      return 'md';
    } else if (width >= 1024) {
      return 'lg';
    }
  };

  const [breakPoint, setBreakPoint] = useState(() =>
    getDeviceSize(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setBreakPoint(getDeviceSize(window.innerWidth));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakPoint]);

  return breakPoint;
};
