import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import { IRawDraftContentState } from '../MentionRenderer';
import { CommentCategory } from './constants'
import LikeComment from './LikeComment'
import ReadOnly from '../Editor/ReadOnly';
export interface ICommentLike {
  author: {
    id: string,
    profile: {
      id: string,
      name: string,
      __typename: string
    }
  }
}

export interface IComment {
  id: string;
  version: number,
  contentJson: IRawDraftContentState;
  author: any; // TODO: Fix author once avatar is done
  createdAt: string;
  likes: ICommentLike[]
}

interface IFormetedComment {
  id: string;
  version: number,
  contentJson: IRawDraftContentState;
  author: any; // TODO: Fix author once avatar is done
  createdAt: string;
  commentCategory?: CommentCategory,
  likes: ICommentLike[],
  user: GET_CURRENT_USER_me;
}

const Comment: React.FunctionComponent<IFormetedComment> = (props: IFormetedComment) => {
  const authorAvatarImgUrl = props.author && props.author.avatar && props.author.avatar.fileUrl && props.author.avatar.fileUrl;

  return (
    <article className="media is-small comments-media is-borderless is-marginless p-t-1">
      <Link to={`/user/${props.author && props.author.id}`}>
        <figure
          className="media-left image avatar is-32x32"
          style={{ backgroundImage: `url(${getCompressedImagePath(authorAvatarImgUrl, 'thumbnail') || profilePlaceholder})` }}
        />
      </Link>
      <div className="comments-media-content media-content has-background-light is-relative">
        <div className="content m-b-0">
          {props.author && props.author.profile && (
            <p className='has-width-fit-content'>
              <Link to={`/user/${props.author.id}`} className="has-text-black">
                <strong>
                  {props.author.profile.name && props.author.profile.name}
                </strong>
              </Link>
            </p>
          )}
          {/* <MentionRenderer input={props.contentJson} /> */}
          <ReadOnly content={props.contentJson} commentIndex={props.id} version={props.version}/>
        </div>
        <div className="media-right">
          <p className="comments-media-content-timestamp is-marginless is-light is-size-7">
            <Moment
              calendar={{
                lastDay: '[Yesterday at] LT',
                lastWeek: '[last] dddd [at] LT',
                sameDay: 'LT'
              }}
            >
              {props.createdAt}
            </Moment>
          </p>
          <div className="comments-media-content-control is-marginless is-light is-size-7">
            <LikeComment
              commentId={props.id}
              commentCategory={props.commentCategory}
              likes={props.likes}
              user={props.user}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default Comment;
