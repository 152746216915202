import React, { useRef, useState } from 'react';

import chevronDown from '../../assets/svg/chevron-down-grey-dark.svg';
import { PrettyContent } from '../Content';

const Accordion = (props: { title: string; content: string }) => {
  const [isActive, setActive] = useState<boolean>(false);

  const accordionContentRef: React.MutableRefObject<HTMLDivElement | null> = useRef(
    null
  );

  const toggleAccordion = () => setActive(!isActive);

  return (
    <div
      className='card accordion__section is-bordered-bottom m-b-0 p-t-1 p-b-1'
    >
      <header className="card-header accordion" onClick={toggleAccordion}>
        <p className="card-header-title is-paddingless">{props.title}</p>
        <div className="card-header-icon">
          <img
            className={`accordion__icon ${isActive && 'accordion--rotate'}`}
            src={chevronDown}
            alt="chevron down"
            width="20px"
          />
        </div>
      </header>
      <div
        ref={accordionContentRef}
        style={{
          maxHeight: isActive ? accordionContentRef.current!.scrollHeight : 0
        }}
        className="card-content accordion__content p-t-0 p-b-0"
      >
        <div className="content is-light">
          <PrettyContent content={props.content} isLineBreakEnabled={false} />
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="faq has-background-white">
      <div className="container">
        <div className="columns m-b-0 p-t-4 p-b-4">
          <div className="column is-three-fifths is-offset-one-fifth">
            <h2 className="title has-text-centered">Common Questions</h2>

            <Accordion
              title="How does MatchHat work?"
              content="We support creatives in finding collaborators for their
                projects. We invite creatives from different fields and cities
                to use MatchHat as a creative playground to connect,
                collaborate, and create."
            />
            <Accordion
              title="Who is a creative?"
              content="MatchHat defines creatives as anyone who wants to produce a
                passion project."
            />
            <Accordion
              title="Is this a global platform?"
              content="Yes. Web 2.0 has enabled us to collaborate with each other
                remotely. We hope to see creatives from different fields (eg.
                Science and Art) and cities come together."
            />
            <Accordion
              title="Is there a membership?"
              content="During the beta stage, we are expanding our community via
                referrals made by MatchHat friends."
            />
            <Accordion
              title="Is there a fee?"
              content="There is no fee to join or use MatchHat."
            />
            <Accordion
              title="Are the projects paid or non-paid?"
              content="We have commissioned and non-commissioned projects on the
                platform. Project owners will communicate the funding status as
                well as how they plan on giving back to their collaborator."
            />
            <Accordion
              title="How do I contact the MatchHat team?"
              content="Please reach out to: <a href='mailto:contact@matchhat.com'>contact@matchhat.com</a>"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
