import { gql } from '@apollo/client';

export const GET_ALL_PROJECTS = gql`
  query GET_ALL_PROJECTS($limit: Float!, $page: Float!) {
    getAllProjects(limit: $limit, page: $page) {
      projects {
        __typename
        author {
          id
          email
          avatar {
            fileUrl
          }
          professions {
            id
            name
          }
          profile {
            id
            name
            bio
            website
          }
        }
        id
        type
        title
        locations {
          id
          name
        }
        visibility
        description
        fundingStatus
        videoUrl
        medias {
          id
          fileUrl
        }
        projectStatus
        isPublic
        genres {
          id
          name
        }
        progress
        likes {
          id
          author {
            id
            email
            avatar {
              fileUrl
            }
            profile {
              id
              name
            }
          }
        }

        interested {
          id
          author {
            id
            email
            avatar {
              fileUrl
            }
            profile {
              id
              name
            }
          }
        }

        wasLikedByUser

        collaborations {
          id
          isFound
          numberOfPeople

          applicants {
            id
          }

          requiredProfessions {
            id
            name
          }

          requiredSkills {
            id
            name
          }

          selectedApplicants {
            id
          }
        }

        comments {
          id
          contentJson
          createdAt
          # likeCount
          wasCommentLikedByUser
          version
          author {
            id
            email
            avatar {
              fileUrl
            }
            profile {
              id
              name
            }
          }
          likes {
            id
            author {
              id
              profile {
                id
                name
              }
            }
          }
        }
        createdAt
        updatedAt
      }
      page
      totalCount
    }
  }
`;
