import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { apolloClient } from '../services/apollo';

const PrivacyPolicyPage = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <div className="page-privacy-policy">
        <Navigation client={apolloClient} user={undefined} />
        <div className="page-privacy-policy-container page-container">
          <div className="container p-t-3 p-b-3">
            <div className="columns m-t-0 m-r-0 m-b-0 m-l-0">
              <div className="column">
                <h1 className="is-size-3 is-bordered-bottom">Privacy Policy</h1>
                <div className="block">
                  <p>Last updated: Feb 14, 2021</p>
                </div>

                <div className="block">
                  <p>
                    The MatchHat community is a community of creative talents.
                    The platform is for sharing, learning, discovering and
                    collaborating. To ensure that this process takes place in a
                    safe and lawful environment, we wish to be transparent with
                    the data we may collect, how we may use it and for what
                    reason. We also wish to empower you to make the best
                    decisions about the information you may share with us, so
                    that MatchHat stays a trustworthy and fun place for
                    creators. We make sure to only use necessary information
                    from you in order to run and improve our service. Please
                    note that in accordance with the General Data Protection
                    Regulation, MatchHat is a data controller (MatchHat is
                    responsible for and controls the processing of your personal
                    data).
                  </p>
                </div>

                <div className="block">
                  <p>
                    Our Privacy Policy is designed to be accessible, focusing
                    closely on our privacy practices and principles. If you have
                    any questions about our Privacy Policy or Service, please
                    contact us at{' '}
                    <a href="mailto:contact@matchhat.com">
                      contact@matchhat.com
                    </a>
                    .
                  </p>
                </div>

                <div className="block">
                  <p>Our Privacy Policy is organised as follow:</p>
                </div>

                <div className="block">
                  <div className="content">
                    <ul>
                      <li>
                        WHAT KIND OF INFORMATION MATCHHAT COLLECTS FROM YOU
                      </li>
                      <li>WHY WE COLLECT SUCH INFORMATION</li>
                      <li>HOW WE SHARE SUCH INFORMATION</li>
                      <li>HOW WE STORE AND PROCESS YOUR INFORMATION</li>
                      <li>HOW WE PROTECT YOUR INFORMATION</li>
                      <li>HOW LONG DOES MATCHHAT KEEP INFORMATION ABOUT YOU</li>
                      <li>CHILDREN PRIVACY</li>
                      <li>LINKS TO OTHER WEBSITES AND SERVICES</li>
                      <li>CONTROL OF YOUR COMMUNICATIONS</li>
                      <li>MANAGING YOUR PERSONAL INFORMATION WITH MATCHHAT</li>
                      <li>CHANGES TO OUR PRIVACY POLICY</li>
                    </ul>
                  </div>
                </div>

                <div className="block">
                  <h3 className="is-size-4">PUBLIC INFORMATION ON MATCHHAT</h3>
                </div>

                <div className="block">
                  <p>
                    You must be at least thirteen year of age (13) in order to
                    create an account. Please make sure you are old enough to
                    consent to the processing of your personal data in your own
                    country. This minimum age may vary from country to country
                    between 13 and 16 years old. In the UK it is 13 years old,
                    in Italy it is 14 years old, in France it is 15 years old
                    and in Netherlands it is 16 years old. Your email address
                    and password remain private and are only required for
                    administrative purposes.
                  </p>
                </div>

                <div className="block">
                  <p>
                    All accounts on MatchHat are public to other MatchHat users.
                    You must create an account to use MatchHat, after which the
                    Services functionalities will become available, such as
                    creating and collaborating on projects, discovering and
                    interacting with other MatchHat Users.
                  </p>
                </div>

                <div className="block">
                  <p>
                    When you register for a MatchHat account, we will ask for
                    certain information from you. We request your full name, and
                    an email address. This enables us to verify that you are a
                    human, and therefore a welcome addition to MatchHat. Your
                    displayed name is always public on MatchHat. You can either
                    use your real name or a pseudonym. If you wish to include a
                    profile picture, a biography, a URL, some roles or your
                    location, these will be public.
                  </p>
                </div>

                <div className="block">
                  <p>
                    All your activity on MatchHat is public and viewable by any
                    MatchHat Users, such as projects or comments.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Information about you that is posted by other people who use
                    MatchHat is also public (for instance when you are named as
                    a collaborator on another public project). Private messages
                    and internal project chats are private to all kinds of
                    projects (private or public) and only collaborators on the
                    projects can see it.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Please remember that you are responsible for any Content or
                    information you display on MatchHat, including for instance
                    any project you create or that are a part of and any
                    comment. Thus, please think carefully about what you make
                    public, especially if it is sensitive information.
                  </p>
                </div>

                <div className="block">
                  <p>
                    If you update your public information on MatchHat, delete
                    Content or deactivate your account, we will reflect this
                    change on MatchHat. We currently do not have a deactivate
                    account function but if you email us at{' '}
                    <a href="mailto:contact@matchhat.com">
                      contact@matchhat.com
                    </a>{' '}
                    we will be able to deactivate your account. Please remember
                    that any User Content that you make public is searchable by
                    other Users. If you remove information that you posted to
                    the platform, copies may remain viewable in cached and
                    archived pages and other Users might have copied or saved
                    that information, which is beyond our control. Please think
                    carefully about what you share and with who. You are
                    responsible for what you upload and for determining if the
                    level of access to your User Content is appropriate.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    WHAT KIND OF INFORMATION MATCHHAT COLLECTS FROM YOU
                  </h3>
                </div>

                <div className="block">
                  <p>
                    The information MatchHat collects, and how that information
                    is used, depends on how you use our services.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    1. INFORMATION YOU CREATE OR PROVIDE US DIRECTLY
                  </h4>
                </div>

                <div className="block">
                  <p>
                    <strong>Account Creation:</strong> When you register for a
                    MatchHat account, we ask for certain information from you.
                    We request your full name, an email address and a password
                    so that we can provide our services to you. In addition, if
                    you want to you may also choose to add role(s), biography
                    URL and location.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>Search Users:</strong> The Search feature allows you
                    to find other MatchHat Users through a search of either
                    names or location on MatchHat. As a result, we ask you for
                    information about your location. We also ask you for your
                    information about your role. Indeed, other Users on MatchHat
                    may be able to search you based on your role.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>Content:</strong> We store all the Content that you
                    create, upload, display and/or receive from others when you
                    use the MatchHat Services. This includes things like
                    projects that you create or are a part of, any Content you
                    post, including comments you make and Content appreciation
                    you give.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Some of the information you choose to provide us might be
                    considered "special" or "sensitive" in certain
                    jurisdictions, for example your racial or ethnic origins. By
                    choosing to provide this information, you consent to our
                    processing of that information.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>Location:</strong> As mentioned above, you may be
                    able to display your location when you create an account,
                    depending on the functionalities and your account settings.
                    In addition, while you use the Service, you may have the
                    choice to indicate your location as you upload some Content.
                    By choosing to provide such information, you consent to
                    MatchHat processing it. This information will be available
                    publicly and other users may be able to search you via the
                    location you indicate on profile and/or Content, depending
                    on the functionalities.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">2. COMMUNICATIONS</h4>
                  <p>
                    If you correspond with us, we will keep the{' '}
                    <strong>Content of your message</strong>, your{' '}
                    <strong>email address</strong> and your{' '}
                    <strong>contact information</strong> to respond to your
                    request. We will keep a record of these interactions for
                    training purposes and to ensure a high quality of service.
                  </p>
                </div>

                <div className="block">
                  <p>
                    We also collect data about Users with whom you communicate
                    or share Content, whether via direct messages or not.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    3. ACCESS AND USE OF THE SERVICES
                  </h4>
                  <p>
                    We keep in our database how you access and use the Services,
                    which may regard terms or Users you search for, how
                    frequently you access the Services, the time you access the
                    Services or how long you use the Services for. In addition,
                    we will also be able to view whether you access the Service
                    from multiple devices, the website from which you came and
                    other actions you may take on the Services.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">4. DEVICE INFORMATION</h4>
                  <p>
                    We will be able to view information about{' '}
                    <strong>your device</strong>. This information can include
                    details about the type of electronic device you use to
                    connect to our app or our Website (for instance computer,
                    tablet, smartphone) as well as unique device identifying
                    numbers, operating systems, browsers your IP address, crash
                    reports, system activity and the date, time, and referrer
                    URL of your request.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">WHY WE COLLECT SUCH INFORMATION</h3>
                  <p>
                    We mainly use your data to monitor, operate and protect
                    MatchHat services and business. We also use this data to
                    help us improve and develop our functionalities and product,
                    in order to provide you with the best possible experience.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">PROVIDE OUR SERVICES:</h4>
                  <p>
                    We use information provided to us by you to make our
                    services available, such as sharing your creative Content or
                    making connections with other creators using MatchHat.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">COMMUNICATE WITH YOU:</h4>
                  <p>
                    To communicate directly with you we may use information such
                    as your email address or device ID by sending you emails or
                    push notifications, to allow you to find Users and have
                    others find you on the Service. We may also use data to
                    enable you to share and interact around Content displayed on
                    MatchHat with other Users. As part of our marketing
                    communications, we may let you know about our last
                    newsletter or improvements to our services.
                  </p>
                </div>

                <div className="block">
                  <p>
                    If you contact us, we will keep a record of the information
                    that you submit to us, and your request in order to help and
                    solve any issues or questions you might have.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    MAINTAIN & IMPROVE OUR SERVICES:
                  </h4>
                  <p>
                    We also use your information to ensure that MatchHat
                    services are working properly. For example, if we detect
                    that the app isn’t working correctly on your device. Plus,
                    we may use the information we collect to develop and improve
                    the quality as well as the functionalities of our service,
                    for instance implementing new features.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">MEASURE PERFORMANCE:</h4>
                  <p>
                    We use data for analytics and measurement to understand how
                    MatchHat services are used. For example, we analyse data
                    about how many Users are on MatchHat, what are they mostly
                    using the services for, what are they responsive to in order
                    to optimize User experience. To do so, we use a variety of
                    tools, such as Google Analytics.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    PROTECT MATCHHAT, OUR USERS, AND THE PUBLIC:
                  </h4>
                  <p>
                    We use your information to help improve the security, safety
                    and reliability of our services. This includes detecting,
                    preventing, and responding to fraud, abuse, security risks,
                    and technical issues that could harm MatchHat, our Users, or
                    the public. In addition, enforcing our{' '}
                    <Link to="/community-guidelines">Community Guidelines</Link>{' '}
                    as well as our <Link to="/terms-of-use">Terms of Use</Link>{' '}
                    are supplemental reasons why we might collect your
                    information.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    Legal key purposes for processing your data:
                  </h4>
                  <div className="content">
                    <ol>
                      <li>
                        To perform the contract we are about to enter into or
                        have entered into with you for the Service;
                      </li>
                      <li>
                        Where it is necessary for our legitimate interests or
                        those of a third party and where your interests and
                        fundamental rights do not override those interests. Here
                        the processing is necessary for our legitimate
                        interests, namely to administer and improve the Service;
                        and
                      </li>
                      <li>
                        To comply with a legal or regulatory obligation in the
                        EU.
                      </li>
                    </ol>
                  </div>
                </div>

                <div className="block">
                  <h3 className="is-size-4">HOW WE SHARE SUCH INFORMATION</h3>
                </div>

                <div className="block">
                  <h4 className="is-size-5">YOUR ACCOUNT INFORMATION:</h4>
                  <p>
                    Your profile information, including your username, full
                    name, profile picture, role, biography or location that you
                    include in your profile, will be available publicly. You can
                    be searched by other MatchHat Users by default. Therefore,
                    by using the Service, you consent to have your profile
                    information displayed to other Users.
                  </p>
                </div>

                <div className="block">
                  <p>
                    By having an account on MatchHat, you can be “found” on the
                    Service and other MatchHat Users may follow you, contact
                    you, invite you to collaborate and see all of your public
                    Content. When other Users of the Service attempt to search
                    you by your name or username, we will identify you as an
                    existing User of the Service.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Any information or Content that you voluntarily post to the
                    Service, becomes available to the public. As such, Content
                    that you make public is searchable by other Users, such as
                    public projects. Also remember that if you remove
                    information or Content that you’ve posted on the Service,
                    copies or traces of it may remain on the Internet, beyond
                    our control.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Consider your own privacy and personal safety when logging
                    in to MatchHat Services and sharing your information with
                    anyone. MatchHat never shares your email address with other
                    Users on the Service without your consent.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">SERVICES PROVIDERS:</h4>
                  <p>
                    We may also share your personal information with certain
                    service providers, who we require to help us operate our
                    service as well as help us understand how our Service is
                    used.
                  </p>
                </div>

                <div className="block">
                  <p>
                    When we grant such necessary third parties access to your
                    personal information, we require that they use your personal
                    information in respect of the applicable laws. Most of our
                    service providers are subject to obligations consistent with
                    this Privacy Policy, any other appropriate confidentiality
                    and security measures, and are performing on the condition
                    that they use your personal data only on our behalf and
                    pursuant to our instructions. Please visit their own terms
                    of use to learn how they process data.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    LEGAL PURPOSES AND PUBLIC INTEREST:
                  </h4>
                  <p>
                    We may preserve, use, or disclose your personal data with
                    third parties as required to:
                  </p>
                  <div className="content">
                    <ul>
                      <li>
                        satisfy any applicable law, regulation, subpoena/court
                        order, legal process or other government request;
                      </li>
                      <li>
                        enforce our{' '}
                        <Link to="/terms-of-use">Terms of use Agreement</Link>{' '}
                        or{' '}
                        <Link to="/community-guidelines">
                          Community Guidelines
                        </Link>
                        , including the investigation of potential violations
                        thereof;
                      </li>
                      <li>
                        explain why we have removed Content or account from our
                        service;
                      </li>
                      <li>
                        investigate and defend ourselves against any third party
                        claims or allegations;
                      </li>
                      <li>
                        protect against harm to the rights, property of
                        MatchHat, its Users or the public as required or
                        permitted by law;
                      </li>
                      <li>
                        preserve the safety and integrity of MatchHat including
                        to help prevent spam, abuse, or malicious actors on our
                        services; and
                      </li>
                      <li>
                        prevent or otherwise address criminals (including fraud
                        or stalking), security or technical issues.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="block">
                  <h4 className="is-size-5">CORPORATE DECISIONS:</h4>
                  <p>
                    In the event of a transaction, such as a merger and
                    acquisition, reorganisation, sale of company assets or
                    shares, financing or change of control of our company by a
                    third party as well as in the event of bankruptcy or related
                    or similar proceedings, your personal data might be
                    transferred.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">NON-PERSONAL INFORMATION:</h4>
                  <p>
                    Finally we share or disclose non-personal data, such as
                    aggregated information like the total number of times people
                    engaged with a post on MatchHat, the number of people who
                    clicked on a particular post, the subjects that Users are
                    interacting on in a particular location.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    HOW WE STORE AND PROCESS YOUR INFORMATION
                  </h3>
                </div>

                <div className="block">
                  <p>
                    Your information collected through the Service may be stored
                    and processed in the United Kingdom, United States or any
                    other country in which MatchHat or its affiliates or service
                    providers maintain facilities. If you are located outside
                    the European Economic Area (EEA) or other regions with laws
                    governing data collection and use that may differ from
                    English and European laws, please note that we may transfer
                    information, including personal information, to a country
                    and jurisdiction that does not have the same data protection
                    laws as your jurisdiction.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Other companies acting such as IT sub-processors are
                    required to respect the security of your personal data and
                    to treat them in accordance with the law. We try to minimise
                    transfer of your personal data outside the EEA but some of
                    our external third parties are based outside the EEA, so
                    their processing of your personal data may involve a
                    transfer of data outside the EEA. In these circumstances, we
                    would only permit your personal data to be transferred
                    outside the EEA, to countries that have been deemed to
                    provide an adequate level of protection for personal data by
                    the European Commission or in the case of US providers based
                    in the US, we may transfer data to them if they are part of
                    the Privacy Shield, which requires them to provide similar
                    protection to personal data shared between the Europe and
                    the US.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Alternatively, we may seek to acquire other businesses or
                    merge with them. If a change happens to our business, then
                    the new owners may use your personal data in the same way as
                    set out in this privacy notice.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">HOW WE PROTECT YOUR INFORMATION</h3>
                </div>

                <div className="block">
                  <p>
                    MatchHat cares about the safety and integrity of the
                    information you give us. That’s why we put in place
                    reasonable safeguards to preserve all information collected
                    through the Service.
                  </p>
                </div>

                <div className="block">
                  <p>
                    To protect your privacy and security, we take reasonable
                    steps (such as requesting a unique and strong password) to
                    verify your identity before granting you access to your
                    account. You are responsible for maintaining the secrecy of
                    your password and account information, and for controlling
                    access to your email communications from MatchHat, at all
                    times.
                  </p>
                </div>

                <div className="block">
                  <p>
                    However, MatchHat cannot warrant the security of any
                    information you transmit to MatchHat or guarantee that
                    information on the Service may not be accessed, disclosed,
                    altered, or destroyed.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Data is stored on secure servers, in secure data centers.
                    All data sent to MatchHat servers is secured by SSL
                    technology.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Your privacy settings may also be affected by changes of
                    functionality and features as the Service grows and
                    develops. We would then inform you and amend our Privacy
                    Policy in accordance.
                  </p>
                </div>

                <div className="block">
                  <p>
                    In the event that any information under our control is
                    compromised as a result of a breach of security, MatchHat
                    will take reasonable steps to investigate the situation and
                    where appropriate, notify those individuals whose
                    information may have been compromised and take other steps,
                    in accordance with any applicable laws and regulations.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    HOW LONG DOES MATCHHAT KEEP INFORMATION ABOUT YOU
                  </h3>
                </div>

                <div className="block">
                  <p>
                    Following termination or deactivation of your User account,
                    MatchHat will do its best to delete your data from the
                    platform. However MatchHat may retain your profile
                    information and User Content information for a reasonable
                    time for backup, analytical archival, or audit purposes.
                    MatchHat may store personal data for as long as necessary to
                    fulfil the purposes for which we collect the data.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Please note that the Content you delete may persist for a
                    limited period of time in backup copies and may still be
                    visible where others have shared it. Ultimately if you
                    delete your account or Content, it will be permanently
                    unrecoverable.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">CHILDREN PRIVACY</h3>
                </div>

                <div className="block">
                  <p>
                    MatchHat does not knowingly collect or solicit any
                    information from anyone under the age of 13 or knowingly
                    allow such persons to visit MatchHat services or register as
                    Users. The Service and its Content are not directed at
                    children under the age of 13.
                  </p>
                </div>

                <div className="block">
                  <p>
                    You must also be old enough to consent to the processing of
                    your personal data in your own country. This age may vary
                    between 13 and 16 years old in the European Union. For
                    example, in Italy and Spain the minimum age to consent for
                    data processing is 14 years old, in France it is 15 years
                    old, whereas in Germany, Poland, Ireland and Netherlands, it
                    is 16 years old. Please make sure your use of the MatchHat
                    is in line with your national minimum age of consent for
                    data processing. In the UK, this minimum age is 13 years
                    old.
                  </p>
                </div>

                <div className="block">
                  <p>
                    In the event that we learn that we have collected personal
                    information from a child under age 13, without verification
                    of parental consent, we will delete that information as
                    quickly as possible. If you believe that we might have any
                    information from or about a child under 13, please contact
                    us at contact@MatchHat.com.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    LINKS TO OTHER WEBSITES AND SERVICES
                  </h3>
                </div>

                <div className="block">
                  <p>
                    We are not responsible for the practices employed by
                    websites or services linked to or from the Service,
                    including the information or Content contained therein.
                    Please remember that when you use a link to go from the
                    Service to another website, our Privacy Policy does not
                    apply to third-party websites or services. Your browsing and
                    interaction on any third-party website or service, including
                    those that have a link on our website, are subject to that
                    third party’s own rules and policies. In addition, you agree
                    that we are not responsible for and we do not have any
                    control over any third-parties that you authorize to access
                    your Content through an hyperlink of your MatchHat account
                    and/or Content. If you are using a third-party website or
                    service and you allow such a third-party access to your
                    Content you do so at your own risk.
                  </p>
                </div>

                <div className="block">
                  <p>
                    This Privacy Policy does not apply to information we collect
                    by other means (including offline) or from other sources
                    other than through the Service.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">CONTROL OF YOUR COMMUNICATIONS</h3>
                </div>

                <div className="block">
                  <p>
                    We use the information we collect or receive to communicate
                    directly with you by different means.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">COMMUNICATIONS WITH MATCHHAT</h4>
                </div>

                <div className="block">
                  <p>
                    <strong>SERVICE COMMUNICATIONS:</strong> We may need to
                    provide you with certain communications, such as service
                    announcements and administrative messages. These are made to
                    inform you on the activity of your account and the service
                    such as scheduled downtime, data breach, forgot password,
                    changes to features of the service. These communications are
                    considered part of the MatchHat services and your account,
                    and you may not be able to opt-out from receiving them.
                    Thus, by providing MatchHat your email address, you consent
                    to MatchHat using your email address to send you
                    service-related notices including any notices required by
                    law, in lieu of communication by postal mail. If you don’t
                    agree to such communications sent to you, you can disable
                    your account, as you may not opt out of Service-related
                    emails.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>NOTIFICATION COMMUNICATIONS:</strong> We will send
                    notification emails based on the activity of your account,
                    for example when you receive a private message. You may opt
                    out of these communications from the Settings section.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>MARKETING COMMUNICATIONS:</strong> We may also send
                    you communications to offer you the best service and keep
                    you updated. Therefore we may use your email address to send
                    you other messages, such as newsletters, or special offers.
                    If you do not want to receive such email messages, you may
                    opt out by following the “Unsubscribe” link in the email
                    messages and from your account setting. Opting out may
                    prevent you from receiving email messages regarding updates,
                    improvements or offers. Please note that we are not selling
                    or sharing your email address with a third party for
                    marketing purposes.
                  </p>
                </div>

                <div className="block">
                  <h4 className="is-size-5">
                    COMMUNICATIONS WITH MATCHHAT USERS
                  </h4>
                </div>

                <div className="block">
                  <p>
                    <strong>DIRECT MESSAGES:</strong> You can use Direct
                    Messages to have non-public conversations between Users on
                    MatchHat. Any User can send you a direct message. We share
                    the Content of your Direct Messages with the people you have
                    sent them to; we do not use them to serve you ads.
                  </p>
                </div>

                <div className="block">
                  <p>
                    In your direct messages, if you interact with some Content
                    ordinarily displayed on MatchHat, for instance by
                    appreciating some Content, such an interaction is public.
                    When you use features like Direct Messages to communicate,
                    remember that the recipients of your messages will have
                    their own copy of your communications on MatchHat, which
                    they may duplicate, store, or re-share.
                  </p>
                </div>

                <div className="block">
                  <p>
                    When you communicate with others by sending or receiving
                    Direct Messages, we will keep in the database information
                    about whom you have communicated with and when. The Content
                    of those communications will be scanned only to protect the
                    safety and integrity of our platform. We will also keep and
                    process your communications and information related to
                    review of reported issues.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>PROJECT CHATS:</strong> As a collaborator on a
                    project, you can discuss with other collaborators within the
                    project. Project chats remain private to any collaborators
                    on the project, either it is public or private. However,
                    keep in mind that any User recipients of your messages on a
                    project chat will have their own copy of your communications
                    on the chat, which they may duplicate, store, or re-share.
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">
                    MANAGING YOUR PERSONAL INFORMATION WITH MATCHHAT
                  </h3>
                </div>

                <div className="block">
                  <p>
                    We provide you with the following rights in respect of the
                    personal information we hold about you and according to the
                    General Data Protection Regulation (GDPR).
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>
                      UPDATE OR RECTIFY YOUR PERSONAL INFORMATION:
                    </strong>{' '}
                    You may at any time update or correct any information that
                    you think is inaccurate or incomplete about you on MatchHat.
                    However, we may need to verify the accuracy of the new data
                    you provide to us.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>Opting out from cookies:</strong> The only way to
                    completely opt out of the collection of any information
                    through cookies or other tracking technology is to actively
                    manage the settings on your browser or device. Please refer
                    to your device or browser’s technical information for
                    instructions on how to delete and disable cookies, and other
                    tracking/recording tools. Depending on your type of device,
                    it may not be possible to delete or disable tracking
                    mechanisms on it.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Note that disabling cookies and/or other tracking tools
                    prevents MatchHat or its partners from tracking your
                    browser’s activities in relation to the Service. However,
                    doing so may disable many of the features available through
                    the Service.
                  </p>
                </div>

                <div className="block">
                  <p>
                    <strong>ERASE YOUR INFORMATION:</strong> You have the right
                    to obtain the erasure of your personal data, without undue
                    delay and in certain circumstances, such as where the
                    personal data is no longer necessary in relation to the
                    purposes for which it was collected or processed. You also
                    have the right to ask us to delete or remove your personal
                    data, where you have successfully exercised your right to
                    object to processing where we may have processed your
                    information unlawfully or where we are required to erase
                    your personal data to comply with applicable law. However,
                    we may not always be able to comply with your request of
                    erasure for specific legal reasons which will be notified to
                    you, if applicable, at the time of your request.
                  </p>
                </div>

                <div className="block">
                  <p>
                    Please remember that requesting a deletion is total and
                    irreversible, unlike a request to unsubscribe from specific
                    emails, such as marketing. This means that MatchHat will
                    lose record of you having ever been on our systems,
                    including any previous instruction to opt out of a specific
                    email type you may have given us. As a result, if you happen
                    to join our systems by voluntarily providing us your
                    personal information (like your email), you will appear to
                    us as a new data subject.
                  </p>
                </div>

                <div className="block">
                  <p>
                    If you wish to exercise one of these rights, please contact
                    us at{' '}
                    <Link to="mailto:contact@matchHat.com">
                      contact@matchHat.com
                    </Link>{' '}
                    by using your email address you subscribed with or by
                    providing your identity. You can find further information
                    about how to contact your local data protection authority if
                    you are located in Europe{' '}
                    <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                      here
                    </a>
                    .
                  </p>
                </div>

                <div className="block">
                  <h3 className="is-size-4">CHANGES TO OUR PRIVACY POLICY</h3>
                </div>

                <div className="block">
                  <p>
                    MatchHat may modify or update this Privacy Policy from time
                    to time to reflect the changes in our services or business
                    as well as in the law. Therefore you should review this page
                    periodically. When we change the policy in a substantial
                    manner we will let you know and update the ‘last updated’
                    date at the top of this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default PrivacyPolicyPage;
