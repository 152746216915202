export const parseUrl = (text: string) => {
  const validUrlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(validUrlRegex, '<a href="$1" target="_blank">$1</a>');
};

export const prependUrl = (url: string) => {
  // Prepend `//` to url if it has no http:// or https:// prefix
  return url.match(/\/\//) ? url : `//${url}`
};
