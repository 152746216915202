import type { FunctionComponent } from 'react';
import type { IClientUser } from '../interfaces/user';
import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import MessageContent from '../components/Messages/MessageContent';
import MessageUserList from '../components/Messages/MessageUserList';
import MessageUserSearch from '../components/Messages/MessageUserSearch';
import Navigation from '../components/Navigation';
import { MessageContextProvider } from '../contexts/messageContext';

import { useBreakpoint } from '../hooks/useBreakpoint';

const MessagesPage: FunctionComponent<IClientUser> = (props) => {
  const [openRoom, setOpenRoom] = useState(false);
  const { path } = useRouteMatch();
  const currBreakPoint = useBreakpoint();

  const mobileBreakpoint = currBreakPoint === 'sm' && openRoom;

  // Include desktop & tablet screen size
  const desktopBreakpoint = currBreakPoint === 'md' || currBreakPoint === 'lg';

  return (
    <main className="page-messages">
      <Navigation {...props} />
      <MessageContextProvider currentUser={props.user!}>
        <section className="container">
          <div
            className={`columns is-gapless p-r-0 p-l-0 ${
              !mobileBreakpoint && 'section'
            }`}
          >
            {mobileBreakpoint && (
              <div className="column m-b-1 is-hidden-desktop is-hidden-tablet">
                <h6
                  className="subtitle is-6 has-text-weight-semibold"
                  onClick={() => setOpenRoom(!openRoom)}
                >
                  {'< '} Back to Message
                </h6>
              </div>
            )}
            <div className="column page-messages-column page-messages-column-list is-one-quarter">
              <div id="scrollableDiv"
                className={`card page-messages-column-list-card is-dropshadowed is-paddingless ${
                  mobileBreakpoint && 'is-hidden'
                }`}
              >
                <div className="card-content page-messages-column-list-card-content is-paddingless">
                  <div className='p-l-1 p-t-1 has-text-weight-medium'>Messages</div>
                  <div className="p-t-1 p-r-1 p-b-1 p-l-1">
                    <MessageUserSearch />
                  </div>
                  <ul>
                    <MessageUserList setOpenRoom={setOpenRoom} />
                  </ul>
                </div>
              </div>
            </div>
            {(mobileBreakpoint || desktopBreakpoint) && (
              <div className="column page-messages-column page-messages-column-content">
                <div className="card page-messages-column-content-card is-rounded">
                  <Switch>
                    <Route
                      path={`${path}/:roomId`}
                      component={MessageContent}
                    />
                  </Switch>
                </div>
              </div>
            )}
          </div>
        </section>
      </MessageContextProvider>
    </main>
  );
};

export default MessagesPage;
