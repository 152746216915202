import { gql } from '@apollo/client';

export const UPDATE_READ_AT_MESSAGES = gql`
  mutation UPDATE_READ_AT_MESSAGES($messageIds: [Float!]!) {
    updateReadAtMessages(messageIds: $messageIds) {
      id
      content
      __typename
    }
  }
`;
