import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';

// import useReactRouter from 'use-react-router';
import profilePlaceholder from '../assets/images/profile/profile-placeholder.png'
import { GET_ALL_NOTIFICATIONS_getAllNotifications } from '../graphql/types/GET_ALL_NOTIFICATIONS';
import { getCompressedImagePath } from '../utils/getCompressedImagePath';
import { DropDownTypes } from './Navigation';
interface INotificationProps {
  notifications: GET_ALL_NOTIFICATIONS_getAllNotifications[];
  dropDownType: DropDownTypes;
}

export enum NotificationType {
  like = 'like',
  comment = 'comment',
  chat = 'chat',
  interested = 'interested',
  mention = 'mention'
}

export enum NotificationModelTypes {
  project = 'project',
  room = 'room'
}

const Notification = ({ notifications, dropDownType }: INotificationProps) => {
  const history = useHistory();

  const handleNotificationClick = async (
    model: NotificationModelTypes | string,
    modelId: string
  ) => {
    if (model === NotificationModelTypes.project) {
      history.push(`/${model}/${modelId}`);
    } else if (model === NotificationModelTypes.room) {
      history.push(`/messages/${modelId}`);
    }
  };

  return (
    <div className="notification navbar-dropdown is-right p-r-0 p-l-0">
      {notifications.map(
        (n: GET_ALL_NOTIFICATIONS_getAllNotifications, idx: number) => (
          <div
            className="navbar-item is-small has-background-light is-cursor-pointer"
            key={idx}
            onClick={async () => await handleNotificationClick(n.model, n.modelId)}
          >
            <article className="media">
              <figure
                className="meda-left image avatar is-32x32 m-r-1 m-l-1"
                style={{
                  backgroundImage: `url(${getCompressedImagePath(getAvatarFileUrl(n), 'thumbnail') || profilePlaceholder})`
                }}
              />
              <div className="media-content">
                <div className="content">
                  <p>{renderNotificationMessage(n)}</p>
                </div>
              </div>
            </article>
          </div>
        )
      )}
      {dropDownType === DropDownTypes.roomNotification &&
        notifications.length > 0 && (
          <Link to="/messages" className="navbar-item-footer">
            Go to Messages
          </Link>
        )}
    </div>
  );
};

const renderNotificationMessage = (
  n: GET_ALL_NOTIFICATIONS_getAllNotifications
) => {
  return (
    <Fragment>
      {generateNotificationMessage(n)}{' '}
      <span className="is-light has-text-weight-normal">
        {generateNotificationTimeElapsed(n)}
      </span>
    </Fragment>
  );
};

const generateNotificationMessage = ({
  actor,
  type
}: GET_ALL_NOTIFICATIONS_getAllNotifications) => {
  if (type === NotificationType.comment) {
    return `${actor.profile.name} ${type}ed on your project.`;
  } else if (type === NotificationType.like) {
    return `${actor.profile.name} ${type}d your project.`;
  } else if (type === NotificationType.chat) {
    return `${actor.profile.name} sent you a message.`;
  } else if (type === NotificationType.interested) {
    return `${actor.profile.name} is ${type} in your project.`;
  } else if (type === NotificationType.mention) {
    return `${actor.profile.name} ${type}ed you.`;
  }
};

const generateNotificationTimeElapsed = ({
  createdAt
}: GET_ALL_NOTIFICATIONS_getAllNotifications) => {
  return <Moment fromNow={true}>{createdAt}</Moment>;
};

const getAvatarFileUrl = ({
  actor
}: GET_ALL_NOTIFICATIONS_getAllNotifications) => {
  return actor.avatar.fileUrl;
};

export default Notification;
