import type { IClientUser } from '../interfaces/user';
import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
// import useReactRouter from 'use-react-router';
import { useLocation } from 'react-router-dom'

import ChangePasswordForm from '../components/User/Forms/ChangePasswordForm';
import ForgotPasswordEmailForm from '../components/User/Forms/ForgotPasswordEmailForm';
import { SignContextProvider, SignInContext } from '../contexts/signInContext';
import { VALIDATE_PASSWORD_REQUEST_TOKEN } from '../graphql/VALIDATE_PASSWORD_REQUEST_TOKEN';

const SignInPage: React.FC<IClientUser> | any = (props: IClientUser) => {
  useContext(SignInContext);

  const location = useLocation();
  const token = location.search.split('?token=');
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [validateToken] = useMutation(VALIDATE_PASSWORD_REQUEST_TOKEN, {
    onCompleted: () => setResetToken(token[1])
  });
  useEffect(() => {
    async function runValidateResetPassToken() {
      await validateToken({
        variables: {
          token: token[1]
        }
      });
    }

    if (token.length > 1) {
      runValidateResetPassToken();
    }
  }, [token.length]); // eslint-disable-line

  return (
    <SignContextProvider>
      {resetToken ? (
        <ChangePasswordForm resetToken={resetToken} />
      ) : (
        <ForgotPasswordEmailForm />
      )}
    </SignContextProvider>
  );
};

export default SignInPage;
