import { gql } from '@apollo/client';

export const ADD_PROJECT_UPDATE_COMMENT = gql`
  mutation ADD_PROJECT_UPDATE_COMMENT(
    $updateId: String!, 
    $commentJson: JSONObject!, 
    $commentRaw: String!
) {
    addProjectUpdateComment(
        updateId: $updateId, 
        commentJson: $commentJson, 
        commentRaw: $commentRaw
    ) {
      __typename
      id
      contentJson
      createdAt
      author {
        id
        email
        avatar {
          fileUrl
        }
        profile {
          id
          name
        }
      }
    }
  }
`;
