import { gql } from '@apollo/client';

export const ADD_PROJECT_UPDATE = gql`
    mutation ADD_PROJECT_UPDATE(
        $projectId: ID!
        $title: String!
        $description: String!
        $medias: [Float!]
    ) {
        addProjectUpdate(
            projectId: $projectId
            title: $title
            description: $description
            medias: $medias
        ) {
            __typename
            id
            title
            description
        }
    }
`;
