import React from 'react';
import uploadCloudIcon from '../../../assets/svg/upload-cloud.svg';

const UploadPhotoContainer = () => (
    <div className="upload upload-dropzone is-small has-text-weight-semibold has-background-grey p-t-1 p-b-1">
      <div className="upload-dropzone-column">
        <img
          src={uploadCloudIcon}
          className="upload-icon"
          alt="upload cloud icon"
        />
        <span className="has-text-secondary">Add file&nbsp;</span>
        <span className="is-light">or drop it right here. </span>
      </div>
      <div className="upload-dropzone-column">
        <span className="has-text-danger">
          (You can only upload files up to 5MB.)
        </span>
      </div>
      <div className="upload-dropzone-column">
        <p className="content is-small is-light is-block is-full">
          Allowed file types: .jpg, .jpeg, and .png
        </p>
      </div>
    </div>
);

export default UploadPhotoContainer