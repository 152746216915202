import { gql } from '@apollo/client';

export const GET_ROOM = gql`
  query GET_ROOM($roomId: Float!) {
    getRoom(roomId: $roomId) {
      __typename
      id
      createdAt
      hasUnreadMessage
      recentMessage

      messages {
        id
        content
        createdAt

        author {
          id
          email

          avatar {
            fileUrl
          }
        }
      }
      members {
        email
        id

        professions {
          name
          id
        }

        avatar {
          fileUrl
        }

        profile {
          id
          name
        }
      }
    }
  }
`;
