import ReactGA from 'react-ga';

export const initGoogleAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID!, {
    debug: false, // debug will be controlled with chrome extension
    gaOptions: {
      siteSpeedSampleRate: 100 // collect site speed from 100% of the users
    }
  });
};

// if we end up doing more dimensions, keeping a map of
// the dimension indexes in the code will be easier
export const trackUser = (userId: string) => {
  ReactGA.set({ dimension1: userId });
};

// map for the pageView method below
const pathRemap = [
  [/^\/user(\/\d+)?$/, '/user'], // eg. /user/123
  [/^\/project(\/\d+)?$/, '/project'], // eg. /project/123
  [/^\/project\/\d+\/new$/, '/project/new'] // eg. /project/123/new
];

export const pageView = (location: string) => {
  // group some urls where we don't want individual locations tracked
  const pathTest = (acc, [pattern, delta]: [RegExp, string]) =>
    (pattern.test(location)) ? delta : acc
  const page = pathRemap.reduce(pathTest, location);
  // record the pageview
  ReactGA.set({ page })
  ReactGA.send({ hitType: 'pageview' });
};
