import React, { Fragment, useContext } from 'react';
import commentIcon from '../../assets/svg/comment.svg';
import { ProjectContext } from '../../contexts/projectContext';
import { GET_CURRENT_USER_me } from '../../graphql/types/GET_CURRENT_USER';
import CommentForm from '../Comment/CommentForm';
import CommentList from '../Comment/CommentList';

interface IProjectContentProps {
  user: GET_CURRENT_USER_me;
}

const Comment = ({ user }: IProjectContentProps) => {
  const project = useContext(ProjectContext);

  return (
    <div className="content page-project-content">
      <h3 className="is-size-5 is-inline-flex post-card-stats-item has-text-grey-dark has-text-weight-semibold m-b-0">
        <img src={commentIcon} alt="comment icon" />
        <span>Comment</span>
        <span className="is-size-7 is-light icon--is-inline-flex">
          {project.comments.length}
        </span>
      </h3>
      <Fragment>
        <div className="is-block">
          <CommentList comments={project.comments} user={user}/>
        </div>
        <div className="is-block m-t-1">
          <CommentForm user={user} projectId={project.id} />
        </div>
      </Fragment>
    </div>
  )
}

export default Comment