import type { IClientUser } from '../interfaces/user';
import React from 'react';
import OnboardingForm from '../components/User/Forms/OnboardingForm';
import { CurrentUserContextProvider } from '../contexts/currentUserContext';

const OnboardingPage: React.FC<IClientUser> = () => {
  return (
    <main className="page-onboarding">
      <section className="container m-t-0">
        <div className="columns section">
          <div className="column is-half">
            <CurrentUserContextProvider>
              <OnboardingForm />
            </CurrentUserContextProvider>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OnboardingPage;
