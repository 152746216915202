import * as React from 'react';
import Slider from 'react-slick';
import noImageFound from '../assets/images/placeholder/no-image-found.png';
import { GET_ALL_PROJECTS_getAllProjects_projects_medias } from '../graphql/types/GET_ALL_PROJECTS';
// import { getCompressedImagePath } from '../utils/getCompressedImagePath';

export interface IImageSliderProps {
  medias: any[];
}

const ImageSlider: React.FunctionComponent<IImageSliderProps> = (
  props: IImageSliderProps
) => {
  const settings = {
    arrows: false,
    customPaging: (idx: number) => {
      return (
        <div
          className="image-slider-thumb"
          style={{
            backgroundImage: `url(${props.medias[idx].fileUrl}), url(${noImageFound})`
          }}
        />
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    className: `image-slider ${
      props.medias.length > 0 && 'is-bordered-bottom p-b-1'
    }`
  };

  return (
    <>
      <Slider {...settings}>
        {props.medias.map(
          (
            image: GET_ALL_PROJECTS_getAllProjects_projects_medias,
            idx: number
          ) => (
            <div key={idx} className="image-slider-item">
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: 700,
                  height: 'auto',
                  margin: 'auto'
                }}
                src={image.fileUrl}
                alt={image.fileUrl}
              />
            </div>
          )
        )}
      </Slider>
    </>
  );
};

export default ImageSlider;
