import { gql } from '@apollo/client';

export const TOGGLE_LIKE = gql`
  mutation TOGGLE_LIKE($projectId: String!) {
    toggleLike(projectId: $projectId) {
      __typename
      author {
        id
        email
        profile {
          id
          name
          bio
          website
        }
      }

      id
      type
      title
      locations {
        id
        name
      }
      visibility
      description
      fundingStatus

      medias {
        id
        fileUrl
      }

      projectStatus
      genres {
        id
        name
      }
      progress

      likes {
        id
      }
      wasLikedByUser

      comments {
        id
        version
        contentJson
        wasCommentLikedByUser
        # likeCount
      }
    }
  }
`;
