// replace {} to Record<string, unknown>
export const storage = <TData>(key: string) => ({
  clear() {
    localStorage.removeItem(key);
  },
  exists(): boolean {
    const data = localStorage.getItem(key);

    return typeof data === 'string' && data.length > 0;
  },

  get(): TData | null {
    try {
      return JSON.parse(localStorage.getItem(key)!) as TData;
    } catch (e) {
      throw new Error(e);
    }
  },
  set(data: TData) {
    localStorage.setItem(key, JSON.stringify(data));
  }
});
