import React from 'react';
import { Link } from 'react-router-dom';

const UserBio = ({ userIdx }: { userIdx: number }) => {
  return (
    <Link
      to={`/user/${userIdx}?sm=true`}
      className="has-cursor-pointer content is-small has-text-link is-inline-block"
    >
      Show more
    </Link>
  );
};

export default UserBio;
