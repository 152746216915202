import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import shareModalImage from '../../assets/images/project/share-modal.png';

interface IUserProfileModalProps {
  modalIsOpen: boolean;
  handleModalToggle?: () => void;
  project: any;
  compressedAvatarImage?: any;
}

const ProjectShareModal = ({
  modalIsOpen,
  handleModalToggle,
  project,
  compressedAvatarImage
}: IUserProfileModalProps) => {
  const getProjectPath = () => {
    const regex = /project/g;
    const currentPath = window.location.toString();
    return regex.test(window.location.toString())
      ? currentPath
      : currentPath + `project/${project.id}`;
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    copy(getProjectPath());
    setCopied(true);
  };

  const projectMedia = project.medias[0] && project.medias[0].fileUrl;
  const modalImage = project.isPublic
    ? `url(${projectMedia})`
    : `url(${shareModalImage})`;
  return (
    <div>
      <div className="share-modal-card page-user-profile-modal-card">
        <header className="modal-card-head-share-modal has-background-white">
          <div
            className='image has-justify-content-flex-start'
            style={{
              backgroundImage: modalImage
            }}
          />
        </header>

        <section className="share-modal-card-body">
          <div className="share-modal-card-body-content-wrapper">
            <h4 className="has-text-centered">
              {project.isPublic
                ? 'You can share on or off Matchhat'
                : 'You can share on Matchhat community'}
            </h4>
          </div>
        </section>
        <footer className="modal-card-foot-share-modal has-background-white p-t-1 p-b-1 is-fullwidth">
          <div className="share-modal-card-input-area">
            <input
              className="control input has-icon-right"
              type="text"
              name="name"
              placeholder={'Enter your name...'}
              value={getProjectPath()}
              readOnly={true}
            />
            <span
              className="has-cursor-pointer"
              style={{
                padding: '3px',
                float: 'right',
                zIndex: 1,
                width: '6.2em',
                marginLeft: '-100px'
              }}
            >
              <button
                onClick={handleCopy}
                style={{
                  width: '100%',
                  padding: '2px',
                  height: '40px',
                  border: 0,
                  background: '#F5F6FA'
                }}
              >
                {copied ? 'Copied!' : 'Copy'}
              </button>
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ProjectShareModal;
