import React, { createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import howItWorks1 from '../assets/images/landing/how-it-works1.png';
import howItWorks2 from '../assets/images/landing/how-it-works2.png';
import banner from '../assets/svg/landing/banner.svg';
import collaborate from '../assets/svg/landing/collaborate.svg';
import connect from '../assets/svg/landing/connect.svg';
import findCreatives from '../assets/svg/landing/find-creatives.svg';
import howItWorks3 from '../assets/svg/landing/how-it-works3.svg';
import Navigation from '../components/Navigation';
import FAQ from '../components/Templates/FAQ';
import Parallax from '../components/Templates/Parallax';
import { IClientUser } from '../interfaces/user';

interface IParallaxItemProps {
  class: string;
  title: string;
}

const parallaxItems: IParallaxItemProps[] = [
  {
    class: 'fashion-vr',
    title: 'Fashion + VR'
  },
  {
    class: 'film-ai',
    title: 'Film + AI'
  },
  {
    class: 'science-art',
    title: 'Science + Art'
  }
];

const LandingPage = (
  props: JSX.IntrinsicAttributes & IClientUser & { children?: React.ReactNode }
) => {
  const parralaxRef: any[] = [];

  const [offSet, setOffSet] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffSet(window.pageYOffset);
    };
  }, [offSet]);
  return (
    <main className="page-landing">
      <Navigation {...props} />
      <section className="page-landing-container">
        <div className="hero">
          <div className="container">
            <div className="columns section is-vcentered">
              <div className="column is-one-third">
                <h1 className="title has-text-primary">
                  Find collaborators for your passion project
                </h1>
                <p className="subtitle m-t-0">
                  Team up with someone who believes in your{' '}
                  <span className="has-text-primary">vision</span>
                </p>
                <Link
                  to="/sign-up"
                  className="button is-primary has-width-auto"
                >
                  Sign Up for Free
                </Link>
              </div>
              <div className="column is-two-thirds">
                <img src={banner} alt="banner img" />
              </div>
            </div>
          </div>
        </div>
        <div className="has-background-white">
          <div className="container">
            <div className="card columns process has-text-centered is-dropshadowed is-marginless is-rounded p-t-2 p-b-2">
              <div className="column">
                <img src={findCreatives} alt="find creatives" />
                <h3 className="is-size-4 has-text-weight-semibold">
                  Find creatives
                </h3>
              </div>
              <div className="column">
                <img src={connect} alt="connect" />
                <h3 className="is-size-4 has-text-weight-semibold">Connect</h3>
              </div>
              <div className="column">
                <img src={collaborate} alt="collaborate" />
                <h3 className="is-size-4 has-text-weight-semibold">
                  Collaborate
                </h3>
              </div>
            </div>

            <div className="columns how-it-works has-text-centered  p-t-2 p-b-2">
              <div className="column">
                <h2 className="is-size-3 has-text-weight-bold m-b-3">
                  How it works?
                </h2>
                <section className="m-b-3">
                  <img src={howItWorks1} alt="post passion project" />
                </section>

                <section className="m-b-3">
                  <div className="is-size-4 has-text-weight-bold has-text-primary">
                    02
                  </div>
                  <div className="is-size-4 has-text-weight-bold">
                    Find collaborators
                  </div>
                  <p className="is-light m-b-3">
                    from diverse fields and cities.
                  </p>
                  <img src={howItWorks2} alt="find collaborators" />
                </section>

                <section className="m-b-3">
                  <div className="is-size-4 has-text-weight-bold has-text-primary">
                    03
                  </div>
                  <div className="is-size-4 has-text-weight-bold">
                    See your passion come to life.
                  </div>
                  <p className="is-light m-b-3">
                    Take your creativity further with your collaborator.
                  </p>
                  <img src={howItWorks3} alt="post passion project" />
                </section>
              </div>
            </div>
          </div>
        </div>

        <div className="columns cta has-text-centered has-background-primary m-b-0 p-t-4 p-b-4">
          <div className="column is-three-fifths is-offset-one-fifth is-10-mobile is-offset-1-mobile">
            <h2 className="title has-text-white m-b-3">
              Become the Creative You Aspire to Be
            </h2>
            <p className="subtitle has-text-white">
              Hard to build great things alone. Find collaborators who can bring
              your project to life.
            </p>
            <Link
              to="/sign-up"
              className="button is-white has-text-primary has-text-weight-bold has-width-auto"
            >
              Sign Up for Free
            </Link>
          </div>
        </div>

        <div className="vision has-background-white">
          <div className="container is-relative" style={{ zIndex: 2 }}>
            <div className="columns has-text-centered p-t-4 p-b-4">
              <div className="column is-three-fifths is-offset-one-fifth">
                <h2 className="title m-b-3">
                  Vision of MatchHat Collaborations
                </h2>
                <p className="subtitle m-b-0">
                  Collaborate with creatives within or outside your field.
                </p>
                <p className="subtitle">
                  Find someone who can take your project further.
                </p>
              </div>
            </div>
          </div>
          {parallaxItems.map((item, i) => {
            const newRef = createRef();
            parralaxRef.push(newRef);
            return (
              <Parallax
                item={item}
                key={i}
                forwardRef={newRef}
                offset={offSet}
              />
            );
          })}
        </div>

        <FAQ />

        <div className="columns cta has-text-centered has-background-primary m-b-0 p-t-4 p-b-4">
          <div className="column is-three-fifths is-offset-one-fifth is-8-mobile is-offset-2-mobile">
            <h2 className="title has-text-white m-b-3">MatchHat is free.</h2>
            <p className="subtitle has-text-white">
              We do not take a commission for matches that are made.
            </p>
            <Link
              to="/sign-up"
              className="button is-white has-text-primary has-text-weight-bold has-width-auto"
            >
              Sign Up for Free
            </Link>
          </div>
        </div>

        <div className="columns cta has-background-white has-text-centered m-b-0 p-t-4 p-b-4">
          <div className="column is-three-fifths is-offset-one-fifth">
            <p className="subtitle">Any questions for the team?</p>
            <a
              href="mailto:contact@matchhat.com"
              className="button is-white has-background-white-light has-text-primary has-text-weight-bold has-width-auto is-dropshadowed"
            >
              Contact us
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LandingPage;
