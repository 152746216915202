import React from 'react';

const Spinner: React.FunctionComponent = () => (
  <div className="spinner">
    <div />
    <div />
  </div>
);

export const SpinnerFull: React.FunctionComponent = () => {
  return (
    <div className="spinner-full">
      <Spinner />
    </div>
  );
};

export default Spinner;
