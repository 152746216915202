import { withNProgress } from '@tanem/react-nprogress';
import React from 'react';

interface IProgressProps {
  isFinished?: boolean;
  progress?: number;
  animationDuration?: number;
}

const Progress = ({
  isFinished,
  progress,
  animationDuration
}: IProgressProps) => (
  <div
    className="progress-bar"
    style={{
      opacity: isFinished ? 0 : 1,
      transition: `opacity ${animationDuration}ms linear`
    }}
  >
    <div
      className="progress-bar-wrap"
      style={{
        marginLeft: `${(-1 + (progress ?? 0)) * 100}%`,
        transition: `margin-left ${animationDuration}ms linear`
      }}
    >
      <div className="progress-bar-line" />
    </div>
    <div className="progress-bar-loading-spinner-wrap">
      <div
        className="progress-bar-loading-spinner"
        style={{ opacity: isFinished ? 0 : 1 }}
      />
    </div>
  </div>
);

export default withNProgress(Progress);
