import type { ReactNode } from 'react';
import React, { createContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';

import Progress from '../components/Progress';
import { IClientUser } from '../interfaces/user';
import { apolloClient } from '../services/apollo';
import { isLoggedIn } from '../services/auth';

import logo from '../assets/svg/logo.svg';

export const SignInContext = createContext(null);

interface ISignContextProviderProps extends IClientUser {
  children: ReactNode;
}

export const SignContextProvider: React.FC<IClientUser> | any = (
  props: ISignContextProviderProps
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsAuthenticated] = useState(false);
  const [, setLoggedInUser] = useState({});

  useMount(async () => {
    setIsLoading(true);
    const auth: any = await isLoggedIn(apolloClient);

    if (auth && auth.user && auth.user.me) {
      setLoggedInUser(auth.user.me);
      setIsAuthenticated(true);
      return setIsLoading(false);
    }

    setIsAuthenticated(false);
    return setIsLoading(false);
  });

  return (
    <main className="page-home">
      <Progress isAnimating={isLoading} />
      {!isLoading && (
        <div className="columns is-gapless">
          <div className="column column-left is-hidden-mobile" />
          <div className="column column-right">
            <div className="column-right-logo is-absolute">
              <Link to="/">
                <img className="logo-md" src={logo} alt="MatchHat logo" />
              </Link>
            </div>
            {props.children}
          </div>
        </div>
      )}
    </main>
  );
};
