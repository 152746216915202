import type { IClientUser } from '../../interfaces/user';
import { useQuery } from '@apollo/client';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
// import useReactRouter from 'use-react-router';

import backButtonImage from '../../assets/svg/back.svg';
import Navigation from '../../components/Navigation';
import ProjectForm, {
  EFormTypes
} from '../../components/Project/Forms/ProjectForm';
import { GET_PROJECT } from '../../graphql/GET_PROJECT';

export interface IEditProject extends IClientUser {
  projectId: string;
  match: any;
}

const EditProjectPage: React.FC<IEditProject> = (props: IEditProject) => {
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId: props.match.params.projectId }
  });

  const project = (data && data.getProject) || undefined;

  if (project && project.author.id !== props.user?.id) {
    history.goBack();
  }

  return (
    <main className="page-project">
      <Navigation {...props} />
      <section className="container">
        <div className="section p-b-0 project-control">
          <Link
            to={`/project/${props.match.params.projectId}`}
            className="is-light is-small has-text-weight-medium is-flex"
          >
            <span className="m-r-1">
              <img src={backButtonImage} alt="Back button" />
            </span>
            <strong>Back</strong>
          </Link>
        </div>
        {(() => {
          if (error) {
            return <p>something went wrong...</p>;
          }

          if (loading) {
            return <p>Loading...</p>;
          }

          return (
            <ProjectForm
              {...props}
              project={data.getProject}
              formType={EFormTypes.update}
            />
          );
        })()}
      </section>
    </main>
  );
};

export default EditProjectPage;
