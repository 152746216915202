import { gql } from '@apollo/client';

export const UPDATE_PROJECT = gql`
  mutation UPDATE_PROJECT(
    $id: ID!
    $title: String!
    $description: String!
    $passionProjectOpts: String
    $commissionedProjectOpts: String
    $type: String!
    $progress: String!
    $medias: [Float!]
    $isPublic: Boolean!
    $fundingStatus: String!
    $genres: [EGenreOpts!]
    $visibility: String
    $locations: [String!]
    $collaborations: [AddProjectCollaborationInput!]
    $videoUrl: String
  ) {
    editProject(
      id: $id
      type: $type
      passionProjectOpts: $passionProjectOpts
      commissionedProjectOpts: $commissionedProjectOpts
      title: $title
      locations: $locations
      description: $description
      isPublic: $isPublic
      progress: $progress
      fundingStatus: $fundingStatus
      medias: $medias
      visibility: $visibility
      genres: $genres
      collaborations: $collaborations
      videoUrl: $videoUrl
    ) {
      __typename
      author {
        id
        email
        profile {
          id
          name
          bio
          website
        }

        skills {
          id
          name
        }
      }

      id
      type
      title
      locations {
        id
        name
      }
      visibility
      description
      fundingStatus
      isPublic

      medias {
        id
        fileUrl
      }

      genres {
        id
        name
      }
      progress

      likes {
        id
      }

      wasLikedByUser

      collaborations {
        id
        requiredSkills {
          id
          name
        }

        requiredProfessions {
          id
          name
        }

        numberOfPeople

        applicants {
          id
        }

        selectedApplicants {
          id
        }
      }

      comments {
        id
        version
        contentJson
        createdAt
      }
    }
  }
`;
