import React, { useEffect, useState } from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';

interface IParallaxProps {
  item: { class: string; title: string };
  forwardRef: React.RefObject<any>;
  offset: number;
}

export enum BreakPointSize {
  SM = 'sm',
}

const Parallax: React.FunctionComponent<
IParallaxProps
> = props => {
  const { item, forwardRef, offset } = props;

  const currBreakPoint = useBreakpoint();

  const mobileBreakpoint = currBreakPoint === BreakPointSize.SM;

  const [textHidden, setTextHidden] = useState(true);

  useEffect(() => {
    // Calculate top & bottom component offset position to display/hide text
    const refOffsetTop = forwardRef.current.offsetTop;
    const refOffsetBottom = refOffsetTop + forwardRef.current.offsetHeight;

    if (offset > refOffsetTop - 370 && offset < refOffsetBottom - 420) {
      setTextHidden(false);
    } else {
      setTextHidden(true);
    }
  }, [offset, forwardRef]);

  return (
    <div
      ref={forwardRef}
      className={`collaboration is-relative ${item.class}`}
      style={{
        backgroundAttachment: mobileBreakpoint ? 'scroll' : 'fixed',
        maxHeight: '600px',
        transition: '0s linear',
        transitionProperty: 'background-position'
      }}
    >
      <h2
        className={`title is-overlay ${
          textHidden && !mobileBreakpoint ? 'is-hidden' : 'is-flex'
        }`}
        style={{
          overflow: 'hidden',
          position: mobileBreakpoint ? 'absolute' : 'fixed'
        }}
      >
        {item.title}
      </h2>
    </div>
  );
};

export default Parallax;
