import React, { createContext, ReactNode } from 'react';
import { useQuery } from '@apollo/client';

import { GET_ALL_PROJECTS_BY_USER } from '../graphql/GET_ALL_PROJECTS_BY_USER';
import { renderContents } from './common';

export const UserProjectsContext = createContext([]);

interface IUserProfileContextProviderProps {
  children: ReactNode;
  userId?: string;
}

export const UserProjectsContextProvider = (
  props: IUserProfileContextProviderProps
) => {
  // Set variables when user ID is provided
  // Otherwise, load the current logged-in user's data
  const setVariables = props.userId
    ? {
        variables: { userId: parseInt(props.userId, 10) }
        // variables: { userId: props.userId }
      }
    : {};

  const { loading, error, data } = useQuery(
    GET_ALL_PROJECTS_BY_USER,
    setVariables
  );

  return (
    <UserProjectsContext.Provider value={data && data.getAllProjectsByUser}>
      {renderContents({
        children: props.children,
        data,
        error,
        loading
      })}
    </UserProjectsContext.Provider>
  );
};
