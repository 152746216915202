import { gql } from '@apollo/client';

export const GET_ALL_USERS_MENTION = gql`
  query GET_ALL_USERS_MENTION {
    getAllUsersMention {
      id
      avatar {
        fileUrl
      }
      profile {
        id
        name
      }
    }
  }
`;
