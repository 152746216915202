import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { RESET_PASSWORD } from '../../../graphql/RESET_PASSWORD';
import FormField from '../../FormField';

const confirmEmailValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password should have at least 8 characters.')
    .max(24, 'Password should not exceed 24 characters.')
    .required('This field is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords does not match.')
    .required('This field is required')
});

const renderForm = ({ handleSubmit, isSubmitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <header className="content">
        <h1 className="is-size-4">Change your password</h1>
        <span>Make sure it is at least 8 characters not exceeding 24.</span>
      </header>
      <div className="field">
        <div className="field-body">
          <FormField
            name="password"
            type="password"
            placeholder="Enter your password"
          />
        </div>
      </div>
      <div className="field">
        <div className="field-body">
          <FormField
            name="passwordConfirm"
            type="password"
            placeholder="Confirm your password"
          />
        </div>
      </div>
      <div className="field">
        <button
          type="submit"
          className={`button has-text-weight-medium is-primary ${isSubmitting &&
            'is-loading'}`}
          disabled={isSubmitting}
        >
          Change password
        </button>
      </div>
    </form>
  );
};

interface IChangePasswordFormProps {
  resetToken: string;
}

const ChangePasswordForm = ({ resetToken }: IChangePasswordFormProps) => {
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => setResetSuccess(true),
    onError: () => toast.error('Something went wrong. Please try again later.', {
      autoClose: false
    })
  });

  return (
    <Formik
      initialValues={{ password: '', passwordConfirm: '' }}
      validationSchema={confirmEmailValidationSchema}
      onSubmit={async (
        { password, passwordConfirm },
        { setSubmitting, resetForm }
      ) => {
        await resetPassword({
          variables: {
            password,
            passwordConfirm,
            resetToken
          }
        });
        toast.success(
          'Password successfully updated. You can now login with your new password.',
          { autoClose: false }
        );
        setSubmitting(false);
        resetForm();
      }}
    >
      {resetSuccess ? (
        <Fragment>
          <header className="content">
            <h1 className="is-size-4">Password successfully updated.</h1>
            <span className="has-text-weight-bold">
              Login with your new password at the link below.
            </span>
            <Link to="/sign-in" className="button m-t-1">
              Go back to sign-in page
            </Link>
          </header>
        </Fragment>
      ) : formikdata => renderForm(formikdata)
      }
    </Formik>
  );
};

export default ChangePasswordForm;
