/**
 * Checks if date is valid
 *
 * @returns true if date is valid, else false
 */
export const isValidDate = (date: Date) => {
  return date instanceof Date;
};

export const formatDate = (date: string) => {
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${newDate.getMonth() +
    1}-${newDate.getDate()} ${newDate.getHours()}:${newDate.getMinutes()}`;
};

// Calendar strings for react-moment
export const calendarStrings = {
  lastDay: '[Yesterday at] LT',
  lastWeek: '[last] dddd [at] LT',
  nextDay: '[Tomorrow at] LT',
  nextWeek: 'dddd [at] LT',
  sameDay: '[Today at] LT',
  sameElse: 'L'
};
