import { gql } from '@apollo/client';

export const TOGGLE_UPDATE_LIKE = gql`
  mutation TOGGLE_UPDATE_LIKE($ updateId: String!) {
    toggleProjectUpdateLike( updateId: $  updateId) {
      __typename
      author {
        id
        email
        profile {
          id
          name
          bio
          website
        }
      }
      id
      title
      description
      medias {
        id
        fileUrl
      }
      likes {
        id
      }
      wasLikedByUser
      comments {
        id
        version
        contentJson
        wasCommentLikedByUser
        # likeCount
      }
    }
  }
`;
