import React, { Fragment, useEffect, useState } from 'react';
// import { Route } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

import { apolloClient } from '../services/apollo';
import { isLoggedIn } from '../services/auth';
import { pageView, trackUser } from '../services/googleAnalytics';
import Progress from './Progress';

interface ILoggedInUser {
    id?: string;
}

const ShareableRoute = ({ component: Component, ...rest }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState<ILoggedInUser>({});
    const location = useLocation();

    useMount(async () => {
        setIsLoading(true);
        const auth: any = await isLoggedIn(apolloClient);
        if (auth && auth.user && auth.user.me) {
            setLoggedInUser(auth.user.me);
            setIsAuthenticated(true);
            return setIsLoading(false);
        }
        setIsAuthenticated(false);
        return setIsLoading(false);
    });

    useEffect(() => {
        if (loggedInUser && loggedInUser.id) {
            trackUser(loggedInUser.id);
            pageView(location.pathname);
        }
    }, [location, loggedInUser]);

    return (
        <Fragment>
            <Progress isAnimating={isLoading} />
            {!isLoading && (
                <Route {...rest} render={props => <Component {...props} user={isAuthenticated ? loggedInUser : null} />} />
            )}
        </Fragment>
    );
};

export default ShareableRoute;
