import { gql } from '@apollo/client';

export const FETCH_STORYBOARD_IMAGE_GENERATION_PROGRESS = gql`
  query FETCH_STORYBOARD_IMAGE_GENERATION_PROGRESS($messageId: String!) {
    fetchStoryboardImageGenerationProgress(messageId: $messageId) {
      messageId
      progress
      uri
    }
  }
`;
