import { gql } from '@apollo/client';

export const UPDATE_USER_DESIRED_COLLABORATION_PROFESSION = gql`
  mutation UPDATE_USER_DESIRED_COLLABORATION_PROFESSION(
    $professionName: String!
  ) {
    updateUserDesiredCollaborationProfession(professionName: $professionName) {
      id
      desiredCollaborationProfession {
        id
        name
      }
    }
  }
`;
