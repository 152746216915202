import React, { Fragment } from 'react';

import binocularsIcon from '../../assets/svg/binoculars.svg';
import checkCircleGreenIcon from '../../assets/svg/check-circle-green.svg';
import xCircleIcon from '../../assets/svg/x-circle.svg';

export enum ECollaborationStatus {
  available = 'available',
  looking = 'looking',
  unavailable = 'unavailable'
}

interface ICollaborationStatusProps {
  collaborationStatus: ECollaborationStatus;
}

const CollaborationStatus: React.FunctionComponent<
  ICollaborationStatusProps
> = ({ collaborationStatus }: ICollaborationStatusProps) => {
  switch (collaborationStatus) {
    case ECollaborationStatus.available:
      return (
        <Fragment>
          <img
            src={checkCircleGreenIcon}
            alt="icon check"
            className="image is-16x16 is-inline"
          />
          <span className="content is-small">Available</span>
        </Fragment>
      );
    case ECollaborationStatus.looking:
      return (
        <Fragment>
          <img
            src={binocularsIcon}
            alt="icon binoculars"
            className="image is-16x16 is-inline"
          />
          <span className="content is-small">Looking for collaborations</span>
        </Fragment>
      );
    case ECollaborationStatus.unavailable:
      return (
        <Fragment>
          <img
            src={xCircleIcon}
            alt="icon times-circle"
            className="image is-16x16 is-inline"
          />
          <span className="content is-small">Unavailable</span>
        </Fragment>
      );

    default:
      return null;
  }
};

export default CollaborationStatus;
